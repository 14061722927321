import {Container, Link, Typography} from "@mui/material";
import {Link as RouterLink} from 'react-router-dom';
import getAppName from "../../utils/EnvUtils";
import {StyledContent} from "./LoginStyleUtils";
import {LoginForm} from "../../sections/auth/login";
import {Head} from "../../components/utils";

export default function LoginPage() {

    const appName = getAppName()

    return (
        <>
            <Head title={`Login | ${appName}`}/>

            <Container maxWidth="sm">
                <StyledContent>

                    <Typography variant="h4" gutterBottom>
                        Sign in to {appName}
                    </Typography>

                    <Typography variant="body2" sx={{mb: 2}}>
                        Don’t have an account? {''}
                        <Link component={RouterLink} variant="subtitle2"
                              to={'/auth/signup'}
                        >
                            Create an account.
                        </Link>

                    </Typography>

                    <LoginForm/>

                </StyledContent>
            </Container>

        </>
    )
}