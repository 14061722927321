import PropTypes from 'prop-types';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack} from '@mui/material';
import * as Yup from "yup";
import {useEffect, useMemo, useState} from "react";
import {useSnackbar} from "notistack";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import UseCase from "../../usecases/UseCase";
import UpdateCompanyUseCase, {RequestUpdateCompany} from "../../usecases/company/UpdateCompanyUseCase";
import {useAuthContext} from "../../auth/AuthProvider";
import {DEFAULT_BILLING_RATE} from "../../utils/constants";
import {getTodayFormattedMMDDYYY} from "../../utils/DateUtils";
import UserProfile from "../../usecases/models/UserProfile";
import CompanyUC from "../../usecases/models/Company";
import FormProvider, {RHFTextField} from "../hook-form";
import UpdateUserUseCase, {RequestUpdateUser} from "../../usecases/user/UpdateUserUseCase";

// ----------------------------------------------------------------------

AccountUpgradeDialog.propTypes = {
    open: PropTypes.bool,
    enforceJobQuota: PropTypes.bool,
    enforceAcreQuota: PropTypes.bool,
    requestedAcres: PropTypes.number,
    onAccept: PropTypes.func,
    onCancel: PropTypes.func,
    onClose: PropTypes.func,
};

export default function AccountUpgradeDialog({
                                                 open,
                                                 enforceJobQuota,
                                                 enforceAcreQuota,
                                                 requestedAcres,
                                                 onAccept,
                                                 onCancel,
                                                 onClose,
                                                 ...other
                                             }) {

    const {user, company} = useAuthContext()
    const {enqueueSnackbar} = useSnackbar();

    const updateCompany = new UpdateCompanyUseCase()
    const updateUser = new UpdateUserUseCase()

    const [isOverQuota, setIsOverQuota] = useState(false)

    const formSchema = Yup.object().shape({
        billingEmail: Yup.string().required().email('Billing email must be a valid email address'),
        companyName: Yup.string().required('Company name is required'),
        companyPhone: Yup.string().required('Company name is required'),
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('First name is required'),
    })

    const defaultValues = useMemo(() => ({
        billingEmail: company.billingEmail,
        companyName: company.name,
        companyPhone: company.phone,
        firstName: user.firstName,
        lastName: user.lastName,

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [user, company])

    const methods = useForm({
        resolver: yupResolver(formSchema),
        defaultValues,

    })
    const {handleSubmit} = methods

    function getUserInfoFromForm(formInfo) {
        const userProfile = new UserProfile()

        userProfile.firstName = formInfo.firstName
        userProfile.lastName = formInfo.lastName
        userProfile.email = user.email
        userProfile.phone = user.phone
        userProfile.companyId = user.companyId
        userProfile.role = user.role
        userProfile.status = user.status

        return userProfile
    }

    function getCompanyInfoFromForm(formInfo) {
        const companyFromForm = new CompanyUC()

        console.log("originalCompany", company)

        companyFromForm.name = formInfo.companyName
        companyFromForm.phone = formInfo.companyPhone
        companyFromForm.address = company.address
        companyFromForm.country = company.country
        companyFromForm.state = company.state
        companyFromForm.city = company.city
        companyFromForm.zipCode = company.zipCode
        companyFromForm.billingEmail = formInfo.billingEmail
        companyFromForm.billingRate = DEFAULT_BILLING_RATE
        companyFromForm.referrerEmail = company.referrerEmail
        companyFromForm.status = company.status
        companyFromForm.trial = false
        companyFromForm.accountUpgradedAt = getTodayFormattedMMDDYYY()

        return companyFromForm
    }

    const billingRate = (company?.billingRate && !Number.isNaN(company.billingRate))
        ? Number(company.billingRate).toFixed(2)
        : DEFAULT_BILLING_RATE

    // For trial accounts, if quota has been exceeded, display an upgrade dialog
    useEffect(() => {
        const newIsOverQuota = (company.trial && enforceJobQuota && company.quotaJobs < 1)
            || (company.trial && enforceAcreQuota && requestedAcres && company.quotaAcres < requestedAcres)
        setIsOverQuota(newIsOverQuota)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, enforceJobQuota, enforceAcreQuota, requestedAcres])


    function handleUpgradeCompleted() {
        enqueueSnackbar('Account upgraded!', {variant: 'success'});
        setIsOverQuota(false)
        if (typeof onAccept === 'function') {
            onAccept()
        }
    }

    const onUpgradeAccount = (formInfo) => {

        const userToUpdate = getUserInfoFromForm(formInfo)
        const companyToUpdate = getCompanyInfoFromForm(formInfo)

        const requestUser = new RequestUpdateUser(user.id, userToUpdate)
        const request = new RequestUpdateCompany(company.id, companyToUpdate)

        console.log("userToUpdate", userToUpdate)
        console.log("companyToUpdate", companyToUpdate)

        UseCase.execute(updateUser, requestUser, () => {}, () => {})
        UseCase.execute(updateCompany, request, handleUpgradeCompleted, () => {})
    }

    return (
        <Dialog fullWidth maxWidth="xs" open={open || isOverQuota} onClose={onClose} {...other}>
            <DialogTitle sx={{pb: 2}}>UPGRADE YOUR ACCOUNT</DialogTitle>

            <FormProvider methods={methods} onSubmit={handleSubmit(onUpgradeAccount)}>


                <DialogContent sx={{typography: 'body2'}}>

                    <Stack spacing={2} direction="column" alignItems="left">
                        <div>
                            You
                            have {company?.quotaJobs} {company?.quotaJobs === 1 ? 'job' : 'jobs'} and {company?.quotaAcres} acres
                            remaining on your trial account.
                            {enforceAcreQuota && requestedAcres && ` This job is ${requestedAcres.toFixed(1)} acres.`}
                            &nbsp;Upgrading enables unlimited jobs for ${billingRate} per acre.
                        </div>
                        <div>

                            <Stack spacing={2}>
                                Who should receive invoices?
                                <RHFTextField name="companyName" label="Company Name"  disabled required/>
                                <RHFTextField name="billingEmail" label="Billing Email Address"/>
                                <RHFTextField name="companyPhone" label="Company Phone" required/>
                                <Divider/>
                                Update your user information
                                <RHFTextField name="firstName" label="First Name" required/>
                                <RHFTextField name="lastName" label="Last Name" required/>

                            </Stack>
                        </div>
                        <div>
                            By clicking "Upgrade" you agree to the <a href='https://datasightusa.com/terms-of-service/'
                                                                      target='_blank' rel='noreferrer'>Terms &
                            Conditions</a>.
                        </div>
                    </Stack>
                </DialogContent>


                <DialogActions>
                    <Button
                        variant="contained"
                        type="submit"
                    >
                        Upgrade
                    </Button>
                    <Button
                        variant="outlined"
                        color="inherit"
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                </DialogActions>

            </FormProvider>
        </Dialog>
    );
}
