export function getTodayFormattedMMDDYYY() {
    const today = new Date()
    const dd = String(today.getDate()).padStart(2, '0')
    const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
    const yyyy = today.getFullYear()
    return `${mm}/${dd}/${yyyy}`
}

export function getDateFormattedMMDDYYYY(date) {
    const dd = String(date.getDate()).padStart(2, '0')
    const mm = String(date.getMonth() + 1).padStart(2, '0') // January is 0!
    const yyyy = date.getFullYear()
    return `${mm}/${dd}/${yyyy}`
}