import {defer, from, Subject} from "rxjs";
import {SortDirection} from "aws-amplify";
import {subDays} from "date-fns";
import * as BreaklinesApi from "../BreaklinesApi";
import {getBasePathForJobFile, JobStatus} from "../../utils/JobUtils";
import UseCase from "../../usecases/UseCase";
import UpdateJobStatusUseCase, {RequestUpdateJobStatus} from "../../usecases/job/UpdateJobStatusUseCase";
import {Jobs} from "../../models";
import {rolesEnum} from "../../assets/data/roles";
import FileStorage from "../FileStorage";

export function createJob(ds, job) {
    const obs = new Subject()

    ds.save(job)
        .then(jobCreated => {
            console.log('[SUCCESS] createJob', jobCreated)
            obs.next(jobCreated)
            obs.complete()
        })
        .catch(e => {
            console.log('[FAIL] createJob', e)
            obs.error(e)
            obs.complete()
        })

    return obs
}

export function updateJob(ds, jobId, jobUpdated) {
    const obs = new Subject()

    from(ds.query(Jobs, jobId)).subscribe(originalJob => {
        const cUpdated = Jobs.copyOf(originalJob, updated => {
            updated.userProfileId = jobUpdated.userProfileId
            updated.companyId = jobUpdated.companyId
            updated.name = jobUpdated.name
            updated.status = jobUpdated.status
            updated.type = jobUpdated.type
            updated.acreage = jobUpdated.acreage
            updated.billingNumber = jobUpdated.billingNumber
            updated.prefix = jobUpdated.prefix
            updated.las = jobUpdated.las
            updated.aoi = jobUpdated.aoi
            updated.s3Bucket = jobUpdated.s3Bucket
            updated.resolution = jobUpdated.resolution
            updated.customBreaklines = jobUpdated.customBreaklines
            updated.errorMessage = jobUpdated.errorMessage
            updated.downloadUrl = jobUpdated.downloadUrl
            updated.jobCreatedAt = jobUpdated.jobCreatedAt
            updated.jobUpdatedAt = jobUpdated.jobUpdatedAt
            updated.jobStartedAt = jobUpdated.jobStartedAt
            updated.jobFinishedAt = jobUpdated.jobFinishedAt
            updated.billingRate = jobUpdated.billingRate
        })

        ds.save(cUpdated)
            .then(jobUpdated => {
                console.log('[SUCCESS] updateJob', jobUpdated)
                obs.next(jobUpdated)
                obs.complete()
            })
            .catch(e => {
                console.log('[FAIL] updateJob', e)
                obs.error(e)
                obs.complete()
            })
    })

    return obs
}

export function fetchAllJobs(ds, userProfile) {
    const obs = new Subject()

    const { id, role, companyId } = userProfile
    ds.query(Jobs, job => {
        const daysAgo = subDays(new Date(), 7);
        let filterRule = job.createdAt.gt(daysAgo.toISOString())

        if (role === rolesEnum.ADMIN.code) {
            filterRule = filterRule && job.companyId.eq(companyId)
        }

        if (role === rolesEnum.USER.code) {
            filterRule = filterRule && job.userProfileId.eq(id)
        }

        return filterRule
    }, {
        sort: s => s.createdAt(SortDirection.DESCENDING)
    })
        .then(jobs => {
            console.log('[SUCCESS] fetchAllJobs', jobs)
            obs.next(jobs)
            obs.complete()
        })
        .catch(e => {
            console.log('[FAIL] fetchAllJobs', e)
            obs.error(e)
            obs.complete()
        })

    return obs
}

export function deleteJob(ds, jobId) {
    const obs = new Subject()
    const fileStorage = new FileStorage()

    const getOriginalJob = () => ds.query(Jobs, jobId)
    defer(getOriginalJob).subscribe(jobToDelete => {
        const basePath = getBasePathForJobFile(jobToDelete.userProfileId, jobToDelete.id)

        // deleting boundary files
        jobToDelete.aoi.forEach(fileName => {
            from(fileStorage.removeFile(basePath + fileName)).subscribe({
                next: fileDeleted => console.log('file removed', fileName, fileDeleted)
            })
        })

        // deleting datasource files
        jobToDelete.las.forEach(fileName => {
            from(fileStorage.removeFile(basePath + fileName)).subscribe({
                next: fileDeleted => console.log('file removed', fileName, fileDeleted)
            })
        })

        // deleting custom breaklines files
        jobToDelete.customBreaklines.forEach(fileName => {
            from(fileStorage.removeFile(basePath + fileName)).subscribe({
                next: fileDeleted => console.log('file removed', fileName, fileDeleted)
            })
        })

        // removing field record
        ds.delete(jobToDelete)
            .then(jobDeleted => {
                console.log('[SUCCESS] removeJob', jobDeleted)
                obs.next(jobDeleted)
                obs.complete()
            })
            .catch(e => {
                console.log('[FAIL] removeJob', e)
                obs.error(e)
                obs.complete()
            })
    })

    return obs
}

export const runJob = async (job) => {
    // If need call async functions could wrap with defer or from operator, it transforms into a observable
    // then need subscribe to it, next will be equivalent to .then() and error to .catch()
    // maybe usefull: https://stackoverflow.com/questions/39319279/convert-promise-to-observable
    console.log(`JobNewForm: Starting job run for job #${job.id}`)
    // defer(() => BreaklinesApi.runJob(job)).subscribe({
    //    next: response => {
    try {
        const response = await BreaklinesApi.runJob(job)
        console.log('JobUtils: Got job run response; updating job status to RUNNING', response)
        const request = new RequestUpdateJobStatus(job.id, JobStatus.RUNNING)
        const useCase = new UpdateJobStatusUseCase()
        return UseCase.executeAsync(useCase, request)
    } catch (error) {
        console.error('JobUtils: Failed to update job status to RUNNING', error)
        throw(error)
    }
}

/*
export async function runJob(job) {
    try {
        await BreaklinesApi.runJob(job)
    }
    catch (error) {
        console.warn("JobUtils: Failed to post job to Breaklines API")
        throw(error)
    }

    try {
        return await updateJobStatus(job, JobStatus.RUNNING)
    }
    catch (error) {
        console.warn("JobUtils: Job was successfully posted to Breaklines API but failed to update status to 'running'")
        throw (error)
    }
}
*/

/*
export async function setJobStatus(job, status) {
    job.status = status;
    const useCase = new UpdateJobStatusUseCase()
    const request = new RequestUpdateJobStatus(job.id, JobStatus.READY)
    // UseCase.execute(useCase, request, onJobStatusUpdated, onFailure)
    return await UseCase.executeAsync(useCase, request)
}
*/

export function updateJobStatus(ds, jobId, newStatus) {
    // Note: obs works asynchronously
    const obs = new Subject()

    const getOriginalJob = () => ds.query(Jobs, jobId)

    defer(getOriginalJob).subscribe(originalJob => {
        const cUpdated = Jobs.copyOf(originalJob, updated => {
            updated.status = newStatus
        })

        ds.save(cUpdated)
            .then(jobUpdated => {
                console.log('[SUCCESS] updateJobStatus', jobUpdated)
                obs.next(jobUpdated)
                obs.complete()
            })
            .catch(e => {
                console.log('[FAIL] updateJobStatus', e)
                obs.error(e)
                obs.complete()
            })
    })

    return obs
}
