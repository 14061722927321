import {getExtension} from "./FileUtils";

export const JobStatus = {
    PENDING: 'pending',
    READY: 'ready',
    COMPLETED: 'completed',
    RUNNING: 'running',
    FAILED: 'failed',
    UPLOADING: 'uploading'
}

export const JobFileType = {
    BOUNDARY: 'boundary',
    DATASOURCE: 'datasource',
    BREAKLINES: 'breaklines'
}

export const JobResolutions = {
    NORMAL: {id: 'NORMAL', label: 'Normal (12 inches)', description: 'For open space areas, providing high accuracy and greater compression.'},
    HIGH: {id: 'HIGH', label: 'High (6 inches)', description: 'Higher detail, with more breaklines and less compression. Sites over 400 acres may be hard to work with.'}
}

export function getFilesFromJobInfo(jobInfo, fileFieldName) {
    return !jobInfo[fileFieldName] ? [] : jobInfo[fileFieldName].map(file => file.name)
}

export function getBasePathForJobFile(userId, jobId) {
    return `inputs/${userId}/${jobId}/`
}

export function getJobOutputUrl(baseS3Url, bucketName, region, userId, jobId) {
    return `${baseS3Url}/${bucketName}/` +
        `?region=${region}` +
        `&prefix=output/` +
        `${userId}/${jobId}/`
}

export function isFilesCompleted(filesToEvaluate, fileTypeInfo) {

    const {required, optional, minRequired} = fileTypeInfo

    const extRequired = required.map(extName => getExtension(extName))
    const extOptional = optional.map(extName => getExtension(extName))
    const allExt = [...extOptional, ...extRequired]

    const extToEvaluate = filesToEvaluate.map(file => getExtension(file.name))
    const requiredExtDetected = extRequired.filter(ext => extToEvaluate.includes(ext))

    const existDuplicates = (new Set(extToEvaluate)).size !== extToEvaluate.length
    const requiredExtCompleted = requiredExtDetected.length === minRequired
    const existExtNotRecognized = extToEvaluate.filter(ext => !allExt.includes(ext)).length > 0

    return !existDuplicates && requiredExtCompleted && !existExtNotRecognized
}