import {Container} from '@mui/material';
import CompanyNewEditForm from "../../sections/@dashboard/company/CompanyNewEditForm";


export default function CreateCompanyPage() {
    return (
        <>
            <Container maxWidth={'lg'}>
                <CompanyNewEditForm/>
            </Container>
        </>
    );
}