import {Checkbox, IconButton, InputAdornment, Link, Stack, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {useSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import {AcceptTermsUseCase, RequestAcceptTerms} from "../../usecases/user/AcceptTermsUseCase";
import FormProvider, {RHFTextField} from "../../components/hook-form";
import {AlertError} from "../../components/utils";
import Iconify from "../../components/iconify";
import {useAuthContext} from "../../auth/AuthProvider";
import {ChangePasswordUseCase, RequestChangePassword} from "../../usecases/user/ChangePasswordUseCase";
import UseCase from "../../usecases/UseCase";
import {PATH_DASHBOARD} from "../../routes/paths";
import {TEMP_PASS} from "../../usecases/login/SignUpUseCase";
import {getTodayFormattedMMDDYYY} from "../../utils/DateUtils";

export function CompleteVerificationForm() {

    const {user, setRequireResetPassword} = useAuthContext()

    const acceptTerms = new AcceptTermsUseCase()
    const changePassword = new ChangePasswordUseCase()

    const [showPassword, setShowPassword] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [verifyInProgress, setVerifyInProgress] = useState(false)
    const [termsAccepted, setTermsAccepted] = useState(false)

    const {enqueueSnackbar} = useSnackbar()
    const navigate = useNavigate()

    useEffect(() => {
        console.log('user profile', user)
    }, [user])

    const formSchema = Yup.object().shape({
        password: Yup.string().required('Password required'),
    })

    const methods = useForm({resolver: yupResolver(formSchema)})
    const {handleSubmit} = methods

    const handleChange = (newValue) => {
        console.log('new value', newValue)
        setTermsAccepted(newValue)
    }

    const onChangePassword = (formInfo) => {
        if (!validateTermsAndConditions()) return

        const newPassword = formInfo.password
        const request = new RequestChangePassword(TEMP_PASS, newPassword)
        UseCase.execute(changePassword, request, onPasswordChanged, onFailure)
    }

    const onPasswordChanged = () => {
        console.log('password changed successfully')

        const today = getTodayFormattedMMDDYYY()
        const request = new RequestAcceptTerms(user.id, today)
        acceptTerms.execute(request).subscribe({
            next: () => {
                setRequireResetPassword(false)
            },
            error: (err) => console.log('something went wrong', err)
        })

        navigate(PATH_DASHBOARD.root, {replace: true})
        enqueueSnackbar(`Welcome!`, {variant: 'info'});
    }

    const onFailure = (error) => {
        showAlertError(error.message)
        setVerifyInProgress(false)
    }

    function showAlertError(message) {
        console.log('ops', message)
        setErrorMessage(message)
    }

    function validateTermsAndConditions() {
        if (!termsAccepted) {
            showAlertError('Please indicate that you have read and agree to the Terms and Conditions')
            return false
        }

        return true
    }

    return (
        <>
            <FormProvider methods={methods} onSubmit={handleSubmit(onChangePassword)}>

                <Stack spacing={3} paddingBottom={4}>

                    <AlertError message={errorMessage} setMessage={setErrorMessage}/>

                    <RHFTextField name="password" label="Password" required
                                  type={showPassword ? 'text' : 'password'}
                                  InputProps={{
                                      endAdornment: (
                                          <InputAdornment position="end">
                                              <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                  <Iconify
                                                      icon={showPassword ? 'mdi:eye-outline' : 'mdi:eye-off-outline'}/>
                                              </IconButton>
                                          </InputAdornment>
                                      ),
                                  }}
                    />
                </Stack>


                <Stack direction={"row"} mb={2} alignItems={"center"}>
                    <Checkbox
                        checked={termsAccepted}
                        onChange={(e) => handleChange(e.target.checked)}
                    />

                    <Typography>
                        By continue, you agree to our
                        <Link href="https://datasightusa.com/terms-of-service/" sx={{ml: 0.5}}>
                            Terms & Conditions
                        </Link>
                    </Typography>

                </Stack>

                <LoadingButton
                    fullWidth size="large" type="submit" variant="contained"
                    loading={verifyInProgress}
                >
                    Update Password
                </LoadingButton>

            </FormProvider>
        </>
    );
}