import {defer} from "rxjs";
import {Auth} from "aws-amplify";

export class RequestVerifyNewPassword {
    constructor(email, code, newPassword) {
        this.email = email
        this.code = code
        this.newPassword = newPassword
    }
}

export default class VerifyNewPasswordUseCase {
    // eslint-disable-next-line class-methods-use-this
    execute(request) {
        const {email, code, newPassword} = request
        return defer(
            () => Auth.forgotPasswordSubmit(email, code, newPassword)
        )
    }
}