import {Link as RouterLink} from "react-router-dom";
import {Container, Link, Typography} from '@mui/material';
import getAppName from "../../utils/EnvUtils";
import SignUpForm from "../../sections/auth/login/SignUpForm";
import {StyledContent} from "./LoginStyleUtils";
import {Head} from "../../components/utils";

export default function SignUpPage() {

    const appName = getAppName()

    return (
        <>
            <Head title={`Sign Up | ${appName}`}/>

            <Container maxWidth="sm">
                <StyledContent>
                    <Typography variant="h4" gutterBottom>
                        Sign up to {appName}
                    </Typography>

                    <Typography variant="body2" gutterBottom>
                        Sign up for an account and run your first job up to 100 acres completely free, no strings
                        attached
                    </Typography>

                    <Typography variant="body2" gutterBottom>
                        We will never share your data. See our
                        <Link href="https://datasightusa.com/privacy-policy/"> Privacy Policy</Link>
                    </Typography>

                    <SignUpForm/>

                    <Typography variant="body2" sx={{mt: 2}}>
                        Already have an account? {''}
                        <Link component={RouterLink} variant="subtitle2"
                              to={'/auth/login'}
                        >
                            Sign In.
                        </Link>
                    </Typography>

                </StyledContent>
            </Container>
        </>
    );
}