import CompanyRepository from "../../repositories/CompanyRepository";


export class RequestCreateCompany {
    /** @param {CompanyUC} company */
    constructor(company) {
        this.company = company
    }
}

export default class CreateCompanyUseCase {
    constructor(repository = new CompanyRepository()) {
        this.repository = repository
    }

    /** @return {Observable<CompanyUC>} */
    execute(request) {
        const {company} = request
        return this.repository.createCompany(company)
    }
}