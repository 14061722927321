import JobRepository from "../../repositories/JobRepository";

export class RequestGetAllJob {
    constructor(userProfile) {
        this.userProfile = userProfile
    }
}

export default class GetAllJobsUseCase {
    constructor(repository = new JobRepository()) {
        this.repository = repository
    }

    /** @return {Observable<Array<Job>>} */
    execute(request) {
        const { userProfile } = request
        return this.repository.fetchAllJobs(userProfile)
    }
}