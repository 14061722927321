import JobRepository from "../../repositories/JobRepository";

export class RequestUpdateJobStatus {
    constructor(jobId, newStatus) {
        this.jobId = jobId
        this.newStatus = newStatus
    }
}

export default class UpdateJobStatusUseCase {
    constructor(repository = new JobRepository()) {
        this.repository = repository
    }

    execute(request) {
        const {jobId, newStatus} = request
        return this.repository.updateJobStatus(jobId, newStatus)
    }
}