import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {Stack, TextField} from "@mui/material"
import {isNonEmpty} from "../../../../utils/StringUtils"
import {JobStep} from "./StepManager"
import {StepStatus} from "./StepStatus"
import {isAlphaNumeric} from "../../../../utils/validatorUtils";
import AccountUpgradeDialog from "../../../../components/account-upgrade-dialog";
import {useAuthContext} from "../../../../auth/AuthProvider";

StepJobInfo.propTypes = {
    onStepInfo: PropTypes.func,
    jobInfo: PropTypes.object,
    onReject: PropTypes.func
}

export default function StepJobInfo({onStepInfo, jobInfo, onReject}) {

    const stepId = JobStep.STEP_JOB_INFO.id

    const {company} = useAuthContext()

    const [formInfo, setFormInfo] = useState({})
    const [isTrialAccount,setIsTrialAccount] = useState(false)

    useEffect(() => {
        function restoreFormState() {
            if (isNewStep()) {
                setFormInfo({...formInfo})
                return
            }
            setFormInfo(jobInfo)
        }
        restoreFormState()

        setIsTrialAccount(company.trial)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        updateStepStatus()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formInfo])

    const handleChangeName = (jobName) => {
        setFormInfo({...formInfo, name: jobName})
    }

    const handleChangeBillingNumber = (jobBillingNumber) => {
        setFormInfo({...formInfo, billingNumber: jobBillingNumber})
    }

    function updateStepStatus() {
        const stepStatus = getStepStatus()
        onStepInfo(stepId, stepStatus, formInfo)
    }

    function getStepStatus() {
        const stepCompleted = isNonEmpty(formInfo.name) && (isTrialAccount || isNonEmpty(formInfo.billingNumber))
        return stepCompleted ? StepStatus.COMPLETED : StepStatus.UNCOMPLETED
    }

    function isNewStep() {
        return jobInfo.name === undefined
    }

    return <>
        <Stack spacing={3} pt={2}>
            <TextField
                name="jobName" label="Job Name"
                required
                defaultValue={jobInfo.name}
                onChange={e => handleChangeName(e.target.value)}
            />

            <TextField
                name="jobBillingNumber" label={isTrialAccount ? "Billing Reference (optional)" : "Billing Reference"}
                required={!isTrialAccount}
                defaultValue={jobInfo.billingNumber}
                onChange={e => handleChangeBillingNumber(e.target.value)}
            />

        </Stack>

        <AccountUpgradeDialog
            enforceJobQuota
            onCancel={onReject}
            onClose={() => {}}
        />
    </>
}