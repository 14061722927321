import React, {useEffect, useState} from 'react';
import {DataStore} from "aws-amplify";
import {IconButton, Stack, Typography} from '@mui/material';
import {useAuthContext} from "../../../auth/AuthProvider";
import GetCompanyUseCase, {RequestGetCompany} from "../../../usecases/company/GetCompanyUseCase";
import {Company as Companies} from "../../../models";
import Company from "../../../usecases/models/Company";
import Iconify from "../../../components/iconify";
import AccountUpgradeDialog from "../../../components/account-upgrade-dialog";

export default function TrialAccountInfoBox() {
    const [isTrialAccount, setIsTrialAccount] = useState(false);
    const [quotaAcres, setQuotaAcres] = useState(100)
    const [quotaJobs, setQuotaJobs] = useState(2)
    const [accountUpgradeDialogOpen, setAccountUpgradeDialogOpen] = useState(false)

    const getCompany = new GetCompanyUseCase()

    const {user} = useAuthContext()

    useEffect(() => {

        function loadCompanyForQuotas() {
            const request = new RequestGetCompany(user.companyId)
            getCompany.execute(request).subscribe({
                next: (company) => {
                    handleCompanyUpdated(company);
                    DataStore.observe(Companies, company.id)
                        .subscribe(msg => handleCompanyUpdated(Company.fromCompanyAmplifyModel(msg.element)))
                }
            })
        }

        loadCompanyForQuotas()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function handleCompanyUpdated(company) {
        setIsTrialAccount(company.trial)
        setQuotaAcres(company.quotaAcres)
        setQuotaJobs(company.quotaJobs)
    }

    if (!isTrialAccount) {
        return null
    }

    return (
        <>
            <Stack direction="row" spacing={1}>
                <Typography variant="body2" sx={{color: 'text.secondary'}}>
                    FREE TRIAL<br />
                    { quotaJobs } { (quotaJobs === 1) ? 'job' : 'jobs' }, { quotaAcres } acres
                </Typography>

                <IconButton
                    aria-label="Upgrade Account"
                    title="Upgrade Account"
                    size="medium"
                    onClick={() => { setAccountUpgradeDialogOpen(true) }}
                >
                    <Iconify icon="mdi:account-arrow-up"/>
                </IconButton>
            </Stack>

            <AccountUpgradeDialog
                open={accountUpgradeDialogOpen}
                onAccept={() => { setAccountUpgradeDialogOpen(false) }}
                onCancel={() => { setAccountUpgradeDialogOpen(false) }}
                onClose={() => { setAccountUpgradeDialogOpen(false) }}
            />
        </>
    )
}
