import React from "react";
import PropTypes from 'prop-types';
import {useDropzone} from 'react-dropzone';
import {alpha, styled} from '@mui/material/styles';
import Iconify from '../iconify';

const StyledDropZone = styled('div')(({theme}) => ({
    width: 32,
    height: 32,
    fontSize: 24,
    display: 'flex',
    flexShrink: 0,
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(0.5),
    color: theme.palette.text.disabled,
    borderRadius: theme.shape.borderRadius,
    border: `dashed 1px ${theme.palette.divider}`,
    backgroundColor: alpha(theme.palette.grey[500], 0.08),
    '&:hover': {
        opacity: 0.72,
    },
}));

UploadBox.propTypes = {
    sx: PropTypes.object,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default function UploadBox({error, disabled, sx, ...other}) {
    const {getRootProps, getInputProps, isDragActive, isDragReject} = useDropzone({
        disabled,
        ...other,
    });

    const isError = isDragReject || error;

    return (
        <StyledDropZone
            {...getRootProps()}
            sx={{
                ...(isDragActive && {
                    opacity: 0.72,
                }),
                ...(isError && {
                    color: 'error.main',
                    bgcolor: 'error.lighter',
                    borderColor: 'error.light',
                }),
                ...(disabled && {
                    opacity: 0.48,
                    pointerEvents: 'none',
                }),
                ...sx,
            }}
        >
            <input {...getInputProps()} />

            <Iconify icon="material-symbols:upload-rounded" width={26} color={"black"}/>

        </StyledDropZone>
    );
}