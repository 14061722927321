import JobRepository from "../../repositories/JobRepository";

export class RequestUpdateJob {
    constructor(jobId, jobUpdated) {
        this.jobId = jobId
        this.jobUpdated = jobUpdated
    }
}

export default class UpdateJobUseCase {
    constructor(repository = new JobRepository()) {
        this.repository = repository
    }

    /**
     * @param {RequestUpdateJob} request
     * @return {Observable<Job>}
     */
    execute(request) {
        const {jobId, jobUpdated} = request
        return this.repository.updateJob(jobId, jobUpdated)
    }
}