import {useEffect, useLayoutEffect, useState} from 'react'
import {paramCase} from 'change-case'
import {useNavigate} from 'react-router-dom'
import {Button, Card, Container, Tab, Table, TableBody, TableContainer, Tabs,} from '@mui/material'
import {DataStore} from "aws-amplify"
import {interval} from "rxjs";
import {useSnackbar} from 'notistack';
import {Helmet} from "react-helmet-async";
import Scrollbar from '../../components/scrollbar'
import ConfirmDialog from '../../components/confirm-dialog'
import {
    emptyRows,
    getComparator,
    TableEmptyRows,
    TableNoData,
    TablePaginationCustom,
    useTable,
} from '../../components/table'
import {PATH_DASHBOARD} from "../../routes/paths"
import getAppName from "../../utils/EnvUtils"
import UseCase from "../../usecases/UseCase"
import JobTableToolbar from "../../sections/@dashboard/job/list/JobTableToolBar"
import JobTableRow from "../../sections/@dashboard/job/list/JobTableRow"
import JobTableHead from "../../sections/@dashboard/job/list/JobTableHead"
import GetAllJobsUseCase, {RequestGetAllJob} from "../../usecases/job/GetAllJobsUseCase"
import UpdateJobUseCase, {RequestUpdateJob} from "../../usecases/job/UpdateJobUseCase";
import {Jobs} from "../../models"
import Job from "../../usecases/models/Job"
import * as JobUtils from "../../services/amplify/JobUtils";
import {useAuthContext} from "../../auth/AuthProvider";
import {DeleteJobUseCase, RequestDeleteJob} from "../../usecases/job/DeleteJobUseCase";
import {containsInFields} from "../../utils/SearchUtils";

const STATUS_OPTIONS = ['all', 'pending', 'running', 'completed', 'failed']
const ROLE_OPTIONS = ['Code', 'Date']
const TABLE_HEAD = [
    {id: 'name', label: 'Job Name', align: 'left'},
    {id: 'acreage', label: 'Acreage', align: 'left'},
    {id: 'jobCreatedAt', label: 'Date Created', align: 'left'},
    {id: 'status', label: 'Status', align: 'center'},
    {id: 'button'},
]

export default function JobListPage() {

    const getAllJobs = new GetAllJobsUseCase()
    const updateJob = new UpdateJobUseCase()
    const deleteJob = new DeleteJobUseCase()

    const appName = getAppName()
    const navigate = useNavigate()
    const {user} = useAuthContext()
    const {enqueueSnackbar} = useSnackbar();

    const refreshFrequency = interval(60_000 * 1)
    let dis

    const [tableData, setTableData] = useState([])
    const [openConfirm, setOpenConfirm] = useState(false)
    const [filterInput, setFilterInput] = useState('')
    const [filterRole, setFilterRole] = useState('Name')
    const [filterStatus, setFilterStatus] = useState('all')

    const [jobUpdated, setJobUpdated] = useState()

    let poolJobObs = []

    const {
        dense, page, order, orderBy, rowsPerPage, setPage,
        selected, setSelected, onSelectRow, onSelectAllRows,
        onSort, onChangeDense, onChangePage, onChangeRowsPerPage,
    } = useTable({defaultOrderBy: 'createdAt', defaultOrder: 'desc'})

    const dataFiltered = applyFilter({
        inputData: tableData,
        comparator: getComparator(order, orderBy),
        filterInput,
        filterRole,
        filterStatus,
    })

    const dataInPage = dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    const denseHeight = dense ? 52 : 72
    const isFiltered = filterInput !== '' || filterRole !== 'Name' || filterStatus !== 'all'

    const isNotFound = (!dataFiltered.length && !!filterInput) ||
        (!dataFiltered.length && !!filterRole) ||
        (!dataFiltered.length && !!filterStatus)

    useLayoutEffect(() =>
         () => {
            try {
                dis.unsubscribe()
            } catch (e) {
                console.log('unable to dispose')
            }

            unsubscribeToRealTimeUpdates()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    , [])

    useEffect(() => {
        if (!jobUpdated) return

        const jobsUpdated = tableData.map(row => (row.id === jobUpdated.id) ? jobUpdated : row)
        setTableData([...jobsUpdated])

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobUpdated])

    function fetchAllJobs() {
        if (!user) return

        const request = new RequestGetAllJob(user)
        UseCase.execute(getAllJobs, request, onJobsFetched, onFailure)
    }

    useEffect(() => {
        fetchAllJobs()
        try {
            dis.unsubscribe();
        } catch (e) {
            console.log('unable to dispose')
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
        dis = refreshFrequency.subscribe(x => {
            console.log('refresh time', x)
            onRefreshTable()
        });

        // TODO when fetch all jobs sync again manually
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // async function changeSync() {
    //     console.log('changeSync')
    //     await DataStore.clear();
    //     await DataStore.start().then(() => {
    //         fetchAllJobs()
    //     });
    // }

    const handleCloseConfirm = () => setOpenConfirm(false)

    const handleFilterStatus = (event, newValue) => {
        setPage(0)
        setFilterStatus(newValue)
    }

    const handleFilterInput = (event) => {
        setPage(0)
        setFilterInput(event.target.value)
    }

    const handleFilterRole = (event) => {
        setPage(0)
        setFilterRole(event.target.value)
    }

    const handleRun = async (job) => {
        try {
            const didSucceed = await JobUtils.runJob(job)
            if (didSucceed) {
                enqueueSnackbar('Job was started', {variant: 'success'});
                // Clear any old error message
                job.errorMessage = null
                const request = new RequestUpdateJob(job.id, job)
                UseCase.execute(updateJob, request, ()=>{}, ()=>{})
            }
            else {
                enqueueSnackbar('Failed to start job', {variant: 'error'});
            }
        } catch (error) {
            enqueueSnackbar(`Failed to start job: ${error.message}`, {variant:'error'})
        }
    }

    const handleResetFilter = () => {
        setFilterInput('')
        setFilterRole('Name')
        setFilterStatus('all')
    }

    const onJobsFetched = (jobs) => {
        subscribeToRealTimeUpdates(jobs)
        setTableData([...jobs])
    }

    function subscribeToRealTimeUpdates(jobs) {
        jobs.forEach(job => {
            const subscription = DataStore.observe(Jobs, job.id)
                .subscribe(msg => onJobUpdated(Job.fromJobAmplifyModel(msg.element)))
            poolJobObs.push(subscription)
        })
    }

    function unsubscribeToRealTimeUpdates() {
        poolJobObs.forEach(sub => sub.unsubscribe())
        poolJobObs = []
    }

    function onJobUpdated(jobUpdated) {
        setJobUpdated(jobUpdated)
    }

    const onFailure = (error) => {
        console.log(error)
    }

    const handleDeleteRows = (selectedRows) => {
        const deleteRows = tableData.filter((row) => !selectedRows.includes(row.id))
        setSelected([])
        setTableData(deleteRows)

        if (page > 0) {
            if (selectedRows.length === dataInPage.length) {
                setPage(page - 1)
            } else if (selectedRows.length === dataFiltered.length) {
                setPage(0)
            } else if (selectedRows.length > dataInPage.length) {
                const newPage = Math.ceil((tableData.length - selectedRows.length) / rowsPerPage) - 1
                setPage(newPage)
            }
        }
    }

    function navigateToEditForm(job) {
        navigate(
            PATH_DASHBOARD.jobs.edit(paramCase(job.name)), {
                state: {jobToEdit: job}
            }
        )
    }

    function handleDeleteJob(job) {
        const request = new RequestDeleteJob(job.id)
        deleteJob.execute(request).subscribe({
            next: jobDeleted => {
                enqueueSnackbar(`Job ${jobDeleted.name} was deleted`, {variant: 'success'});

                const jobsUpdated = tableData.filter(row => (row.id !== job.id))
                setTableData([...jobsUpdated])
            },
            error: err => {
                console.log('error', err)
                enqueueSnackbar('Unable to delete job', {variant: 'error'});
            }
        })
    }

    const onRefreshTable = async () => {
        // manual sync, need improve it, should sync just jobs, not entire datastore
        await DataStore.clear()
        await DataStore.stop();
        await DataStore.start();

        setTimeout(()=> {
            fetchAllJobs()
        }, 3_000)
    }

    return (
        <>
            <Helmet>
                <title> Jobs: List | {appName}</title>
            </Helmet>

            <Container maxWidth={'lg'}>

                <Card>

                    <Tabs
                        value={filterStatus}
                        onChange={handleFilterStatus}
                        sx={{
                            px: 2,
                            bgcolor: 'background.neutral',
                        }}
                    >
                        {
                            STATUS_OPTIONS.map(tab =>
                                <Tab key={tab} label={`${tab}`}
                                     value={tab}
                                />
                            )
                        }

                    </Tabs>

                    { /* <Divider/> */}

                    <JobTableToolbar
                        isFiltered={isFiltered}
                        filterInput={filterInput}
                        filterRole={filterRole}
                        optionsRole={ROLE_OPTIONS}
                        onFilterInput={handleFilterInput}
                        onFilterRole={handleFilterRole}
                        onResetFilter={handleResetFilter}
                    />


                    <TableContainer sx={{position: 'relative', overflow: 'unset'}}>
                        <Scrollbar>
                            <Table size={dense ? 'small' : 'medium'} sx={{minWidth: 800}}>
                                <JobTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={tableData.length}
                                    numSelected={selected.length}
                                    onSort={onSort}
                                    onSelectAllRows={(checked) =>
                                        onSelectAllRows(
                                            checked,
                                            tableData.map((row) => row.id)
                                        )
                                    }
                                    onRefresh={onRefreshTable}
                                />

                                <TableBody>
                                    {dataFiltered
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((job) => (
                                            <JobTableRow
                                                key={job.id}
                                                job={job}
                                                selected={selected.includes(job.id)}
                                                onSelectRow={() => onSelectRow(job.id)}
                                                onEditRow={() => navigateToEditForm(job)}
                                                onDeleteJob={() => handleDeleteJob(job)}
                                                onRun={() => handleRun(job)}
                                            />
                                        ))}


                                    <TableEmptyRows
                                        height={denseHeight}
                                        emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                                    />

                                    <TableNoData isNotFound={isNotFound}/>
                                </TableBody>
                            </Table>
                        </Scrollbar>
                    </TableContainer>

                    <TablePaginationCustom
                        count={dataFiltered.length}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onPageChange={onChangePage}
                        onRowsPerPageChange={onChangeRowsPerPage}
                        //
                        dense={dense}
                        onChangeDense={onChangeDense}
                    />
                </Card>
            </Container>

            <ConfirmDialog
                open={openConfirm}
                onClose={handleCloseConfirm}
                title="Delete"
                content={
                    <>
                        Are you sure want to delete <strong> {selected.length} </strong> items?
                    </>
                }
                action={
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            handleDeleteRows(selected)
                            handleCloseConfirm()
                        }}
                    >
                        Delete
                    </Button>
                }
            />
        </>
    )
}

function applyFilter({inputData, comparator, filterInput, filterStatus}) {
    const stabilizedThis = inputData.map((el, index) => [el, index])

    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })

    inputData = stabilizedThis.map((el) => el[0])

    if (filterStatus !== 'all') {
        inputData = containsInFields(inputData, ['status'], filterStatus)
    }

    const fields = ['name', 'id', 'jobCreatedAt']
    inputData = containsInFields(inputData, fields, filterInput)

    return inputData
}