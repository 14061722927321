import {Storage} from "aws-amplify";
import {from, Subject} from "rxjs";

export default class FileStorage {
    CONST_MAX_REGULAR_SIZE = 20_000000 // 20mb

    constructor(storage = Storage) {
        this.storage = storage
    }

    saveFile(pathDestiny, file, fileType, fileName, onProgressCallback) {
        if (file.size < this.CONST_MAX_REGULAR_SIZE) {
            return this.saveRegularFile(pathDestiny, file, fileType, fileName, onProgressCallback)
        }

        return this.saveLargeFile(pathDestiny, file, fileType, fileName, onProgressCallback)
    }

    saveRegularFile(pathDestiny, file, fileType, fileName, onProgressCallback) {
        const obs = new Subject()

        this.storage.put(pathDestiny, file, {
            progressCallback(progress) {
                onProgressCallback(fileType, fileName, progress.loaded, progress.total)
            }
        })
            .then(result => {
                console.log('[SUCCESS] saveFile', result)
                obs.next(result)
                obs.complete()
            })
            .catch(e => {
                console.log('[FAIL] saveFile', e)
                obs.error(e)
                obs.complete()
            })


        return obs
    }

    saveLargeFile(pathDestiny, file, fileType, fileName, onProgressCallback) {
        console.log("saving large file", fileName)
        const obs = new Subject()

        try {
            const newUploadRef = this.storage.put(pathDestiny, file, {
                resumable: true, // it enables multipart upload
                completeCallback: (event) => {
                    console.log(`Successfully uploaded ${event.key}`, file);
                    obs.next(file)
                    obs.complete()
                },
                progressCallback: (progress) => {
                    console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                    onProgressCallback(fileType, fileName, progress.loaded, progress.total)
                },
                errorCallback: (err) => {
                    console.log('[FAIL] saveFile', err)
                    obs.error(err)
                    obs.complete()
                }
            })
        } catch (err) {
            console.log('[FAIL] saveFile', err)
            obs.error(err)
            obs.complete()
        }

        return obs
    }

    removeFile(pathFile) {
        return from(this.storage.remove(pathFile))
    }
}