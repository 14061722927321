import {Container, Link, Stack, Typography} from "@mui/material";
import {Link as RouterLink} from 'react-router-dom';
import getAppName from "../../utils/EnvUtils";
import {StyledContent} from "./LoginStyleUtils";
import {Head} from "../../components/utils";
import ResetPasswordForm from "../../sections/auth/login/ResetPasswordForm";

export default function ResetPasswordPage() {

    const appName = getAppName()

    return (
        <>
            <Head title={`Forgot Pass | ${appName}`}/>

            <Container maxWidth="sm">
                <StyledContent>

                    <Typography variant="h4" gutterBottom>
                        Forgot your password?
                    </Typography>

                    <Typography variant="body2" sx={{mb: 5}}>
                        Please enter the email address associated with your account
                        and we will send you a code to reset your password.

                    </Typography>

                    <ResetPasswordForm/>

                    <Stack direction="row" justifyContent="center" marginTop={2}>
                        <Typography variant="body2">
                            <Link component={RouterLink} variant="subtitle2"
                                  to={'/auth/login'}
                            >
                                Return to sign in
                            </Link>
                        </Typography>
                    </Stack>

                </StyledContent>
            </Container>

        </>
    )
}