import {Helmet} from "react-helmet-async";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Alert, Collapse, IconButton} from "@mui/material";
import Iconify from "./iconify";

export function Head({title}) {
    return <Helmet><title> {title} </title></Helmet>
}

Head.propTypes = {
    title: PropTypes.string
}

export function AlertError({message, setMessage}) {

    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (message !== '') {
            setOpen(true)
        }
    }, [message])

    return (
        <Collapse in={open}>
            <Alert
                variant='outlined' severity='error'
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setMessage('')
                            setOpen(false)
                        }}
                    >
                        <Iconify icon="carbon:close"/>
                    </IconButton>
                }
            >
                {message}
            </Alert>
        </Collapse>
    )
}

AlertError.propTypes = {
    message: PropTypes.string,
    setMessage: PropTypes.func
}