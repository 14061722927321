import PropTypes from 'prop-types';
import {Box, Stack, Typography} from '@mui/material';

EmptyContent.propTypes = {
    sx: PropTypes.object,
    img: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
}

export default function EmptyContent({title, description, img, sx, ...other}) {
    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
                height: 1,
                textAlign: 'center',
                p: (theme) => theme.spacing(8, 2),
                ...sx,
            }}
            {...other}
        >

            <Box
                component="img"
                src={img}
                sx={{height: 140, mx: 'auto', my: 5}}
            />

            <Typography variant="h5" gutterBottom color={'#6F6F6E'}>
                {title}
            </Typography>

            {description && (
                <Typography variant="body2" sx={{color: 'text.secondary'}}>
                    {description}
                </Typography>
            )}
        </Stack>
    )
}
