import {SnackbarProvider} from "notistack";
import {useEffect} from "react";
import Router from './routes';
import ThemeProvider from './theme';
import ScrollToTop from './components/scroll-to-top';
import {AuthProvider} from "./auth/AuthProvider";
import GlobalDebug from "./GlobalDebug";

export default function App() {

    useEffect(() => {
        GlobalDebug(process.env.NODE_ENV)
    }, [])

    return (
        <AuthProvider>
            <ThemeProvider>
                <SnackbarProvider maxSnack={3}>
                    <ScrollToTop/>
                    <Router/>
                </SnackbarProvider>
            </ThemeProvider>
        </AuthProvider>
    )
}