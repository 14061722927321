import CompanyRepository from "../../repositories/CompanyRepository";

export default class GetCompaniesUseCase {
    constructor(repository = new CompanyRepository()) {
        this.repository = repository
    }

    /** @return {Observable<Array<CompanyUC>>} */
    execute() {
        return this.repository.fetchCompanies()
    }
}