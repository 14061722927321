import {Helmet} from 'react-helmet-async'
import {Container} from '@mui/material'
import {useLocation} from "react-router-dom";
import getAppName from "../../utils/EnvUtils"
import JobEditForm from "../../sections/@dashboard/job/JobEditForm";

export default function EditJobPage() {
    const appName = getAppName()
    const {state} = useLocation()
    const {jobToEdit} = state

    return (
        <>
            <Helmet>
                <title> Job: Edit job | {appName}</title>
            </Helmet>

            <Container maxWidth={'lg'}>
                <JobEditForm jobToEdit={jobToEdit}/>
            </Container>
        </>
    )
}