const ROOTS_DASHBOARD = '/dashboard'

function path(root, sublink) {
    return `${root}${sublink}`
}

export const PATH_DASHBOARD = {
    root: '/dashboard',
    users: {
        root: path(ROOTS_DASHBOARD, '/users'),
        list: path(ROOTS_DASHBOARD, '/users/list'),
        new: path(ROOTS_DASHBOARD, '/users/new'),
        edit: (name) => path(ROOTS_DASHBOARD, `/users/${name}/edit`),
    },
    companies: {
        root: path(ROOTS_DASHBOARD, '/companies'),
        list: path(ROOTS_DASHBOARD, '/companies/list'),
        new: path(ROOTS_DASHBOARD, '/companies/new'),
        edit: (name) => path(ROOTS_DASHBOARD, `/companies/${name}/edit`),
    },
    jobs: {
        root: path(ROOTS_DASHBOARD, '/jobs'),
        list: path(ROOTS_DASHBOARD, '/jobs/list'),
        new: path(ROOTS_DASHBOARD, '/jobs/new'),
        edit: (name) => path(ROOTS_DASHBOARD, `/jobs/${name}/edit`),
    },
    support: {
        root: path(ROOTS_DASHBOARD, '/support')
    },
    info: {
        root: path(ROOTS_DASHBOARD, '/info')
    }
}