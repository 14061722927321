import PropTypes from 'prop-types'
import {useState} from 'react'
import {Button, IconButton, MenuItem, TableCell, TableRow, Typography,} from '@mui/material'
import Label from '../../../../components/label'
import Iconify from '../../../../components/iconify'
import ConfirmDialog from '../../../../components/confirm-dialog'
import MenuPopover from "../../../../components/menu-popover/MenuPopover"

CompanyTableRow.propTypes = {
    data: PropTypes.object,
    selected: PropTypes.bool,
    onEditRow: PropTypes.func,
    onDeleteRow: PropTypes.func,
}

export default function CompanyTableRow({data, selected, onEditRow, onDeleteRow}) {
    const {name, billingEmail, billingRate, country, status, createdAt} = data

    const [openConfirm, setOpenConfirm] = useState(false)
    const [openPopover, setOpenPopover] = useState(null)

    const handleOpenConfirm = () => setOpenConfirm(true)
    const handleCloseConfirm = () => setOpenConfirm(false)
    const handleOpenPopover = (event) => setOpenPopover(event.currentTarget)
    const handleClosePopover = () => setOpenPopover(null)

    function getDateFormatted(date) {
        return new Date(date).toLocaleDateString("en-US")
    }

    return (
        <>
            <TableRow hover selected={selected}>
                <TableCell>
                    <Typography variant="subtitle2" noWrap>
                        {name}
                    </Typography>
                </TableCell>

                <TableCell align="left">{billingEmail}</TableCell>

                <TableCell align="center">{billingRate}</TableCell>

                <TableCell align="left">{country}</TableCell>

                <TableCell align="left">{getDateFormatted(createdAt)}</TableCell>

                <TableCell align="center">
                    <Label
                        variant="soft"
                        color={(status === 'enabled' && 'info') || 'error'}
                        sx={{textTransform: 'capitalize'}}
                    >
                        {(status === 'enabled' && 'Enabled') || 'Disabled'}
                    </Label>
                </TableCell>

                <TableCell align="right">
                    <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}>
                        <Iconify icon="eva:more-vertical-fill"/>
                    </IconButton>
                </TableCell>
            </TableRow>

            <MenuPopover
                open={openPopover}
                onClose={handleClosePopover}
                arrow="right-top"
                sx={{width: 140}}
            >
                <MenuItem
                    onClick={() => {
                        onEditRow()
                        handleClosePopover()
                    }}
                >
                    <Iconify icon="eva:edit-fill"/>
                    Edit
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleOpenConfirm()
                        handleClosePopover()
                    }}
                    sx={{color: 'error.main'}}
                >
                    <Iconify icon="eva:trash-2-outline"/>
                    Delete
                </MenuItem>
            </MenuPopover>

            <ConfirmDialog
                open={openConfirm}
                onClose={handleCloseConfirm}
                title="Delete"
                content="Are you sure want to delete?"
                action={
                    <Button variant="contained" color="error" onClick={onDeleteRow}>
                        Delete
                    </Button>
                }
            />
        </>
    )
}