import {defer} from "rxjs";
import {Auth} from "aws-amplify";

export class RequestVerifyCode {
    constructor(email, code) {
        this.email = email
        this.code = code
    }
}

export default class VerifyCodeUseCase {
    // eslint-disable-next-line class-methods-use-this
    execute(request) {
        const {email, code} = request
        return defer(() => Auth.confirmSignUp(email, code))
    }
}