import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {Box, Divider, Drawer} from '@mui/material';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section/NavSection';
import Logo from "../../../components/logo";

const NAV_WIDTH = 280

Nav.propTypes = {
    navConfig: PropTypes.array,
    openNav: PropTypes.bool,
    onCloseNav: PropTypes.func,
}

export default function Nav({navConfig, openNav, onCloseNav}) {

    const {pathname} = useLocation()

    useEffect(() => {
        if (openNav) {
            onCloseNav()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])

    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                '& .simplebar-content': {height: 1, display: 'flex', flexDirection: 'column'},
            }}
        >
            <Box sx={{px: 2.5, py: 3, display: 'inline-flex'}}>
                <Logo />
            </Box>

            {navConfig && <NavSection data={navConfig}/>}
            <Divider/>
        </Scrollbar>
    )

    return (
        <Drawer
            open={openNav}
            onClose={onCloseNav}
            ModalProps={{
                keepMounted: true,
            }}
            PaperProps={{
                sx: {width: NAV_WIDTH},
            }}
        >
            {renderContent}
        </Drawer>
    )
}
