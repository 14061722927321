// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Settings, UserProfiles, Company, Jobs } = initSchema(schema);

export {
  Settings,
  UserProfiles,
  Company,
  Jobs
};