import {UserRepository} from "../../repositories/UserRepository";

export class RequestGetUserById {
    constructor(userId) {
        this.userId = userId
    }
}

export default class GetUserByIdUseCase {
    constructor(repository = new UserRepository()) {
        this.repository = repository
    }

    execute(request) {
        const {userId} = request
        return this.repository.getUserById(userId)
    }
}