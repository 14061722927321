import StepJobInfo from "./StepJobInfo"
import StepBoundary from "./StepBoundary"
import StepVerifyBoundary from "./StepVerifyBoundary"
import StepDataSource from "./StepDataSource"
import StepOptionalBreaklines from "./StepCriticalBreaklines"
import StepUpload from "./StepUpload"

export const JobStep = {
    STEP_JOB_INFO: {
        index: 0,
        id: 'STEP_JOB_INFO',
        isOptional: false,
        label: 'Job Info',
        title: 'Name Your Job',
        description: 'Job Name helps you identify this project, and Billing Reference is for your accounting purposes.'
    },
    STEP_BOUNDARY: {
        index: 1,
        id: 'STEP_BOUNDARY',
        isOptional: false,
        label: 'AOI',
        title: 'Select Area of Interest (AOI)',
        description: 'Provide a DXF or SHP file containing a single polygon bounding the area within your elevation data to process.',
        instruction:
            '<ul>' +
                '<li>Can be DXF or SHP, but we expect it to contain a single polygon</li>' +
                '<li>The AOI defines an area within the point cloud/DEM for focused processing</li>' +
                '<li>It can have void areas which will not be processed (water, buildings, etc.)</li>' +
                '<li>Multiple polygons will be joined into a single Multi-Polygon</li>' +
                '<li>If AOI goes outside point cloud/DEM, it will produce -99999 data</li>' +
            '</ul>'
    },
    STEP_VERIFY_BOUNDARY: {
        index: 2,
        id: 'STEP_VERIFY_BOUNDARY',
        isOptional: false,
        label: 'Verify AOI',
        title: 'Verifying AOI...',
        description:'Please wait while we check if your AOI boundary is valid.',
    },
    STEP_DATA_SOURCE: {
        index: 3,
        id: 'STEP_DATA_SOURCE',
        isOptional: false,
        label: 'Elevation Data',
        title: 'Provide an Elevation Data File',
        description: 'Select a classified point cloud file or a DEM in TIF format.',
        instruction:
            '<ul>' +
                '<li>Provide a single LAZ (preferred) or LAS. Alternatively provide a DEM in TIF format</li>' +
                '<li>The cleaned point cloud needs to at minimum include ground classification</li>' +
                '<li>Keep below 7gb and 5,000 acres (contact us for larger projects)</li>' +
                '<li>Optimum density is between 40 and 300 ppsm, but will still work with more or less</li>' +
                '<li>LAZ should extend at least 10ft beyond the boundary to avoid edge effects</li>' +
            '</ul>'
    },
    STEP_BREAKLINES: {
        index: 4,
        id: 'STEP_BREAKLINES',
        isOptional: true,
        label: 'Critical Breaklines',
        title: 'Provide Breakline File (optional)',
        description: 'Breaklines you provide here will be included in and override the generated breaklines.',
        instruction:
            '<ul>' +
                '<li>Not required, skip this step if you only need automated breaklines</li>\n' +
                '<li>Your breaklines will be added first and automated breaks will avoid them</li>\n' +
                '<li>There must be some overlap with AOI</li>\n' +
                '<li>Can be DXF or SHP, but should be all 3D polylines</li>\n' +
                '<li>Can have multiple layers, we will preserve them as-is</li>\n' +
                '<li>If they have no layer attribute, they will be layered as V-TOPO-UBRK (user breakline)</li>' +
            '</ul>'
    },
    STEP_COMPLETE_UPLOAD: {
        index: 5,
        id: 'STEP_COMPLETE_UPLOAD',
        isOptional: false,
        label: 'Upload',
        title: 'Ready to upload your files'
    }
}

export function getJobStepNames() {
    const jobStepNames = []
    // eslint-disable-next-line no-restricted-syntax,guard-for-in
    for (const key in JobStep) {
        jobStepNames.push(JobStep[key].label)
    }

    return jobStepNames
}

export function getJobStepIds() {
    const jobStepIds = []
    // eslint-disable-next-line no-restricted-syntax,guard-for-in
    for (const key in JobStep) {
        jobStepIds.push(JobStep[key].id)
    }

    return jobStepIds
}

export function getStepIndexById(stepId) {
    return getJobStepIds().indexOf(stepId)
}

export function getStep(stepId) {
    return JobStep[stepId]
}

export default function getStepView(stepId, jobInfo, onStepInfo, onReject) {
    switch (stepId) {
        case JobStep.STEP_JOB_INFO.id:
            return <StepJobInfo onStepInfo={onStepInfo} jobInfo={jobInfo} onReject={onReject}/>
        case JobStep.STEP_BOUNDARY.id:
            return <StepBoundary onStepInfo={onStepInfo} jobInfo={jobInfo} onReject={onReject}/>
        case JobStep.STEP_VERIFY_BOUNDARY.id:
            return <StepVerifyBoundary onStepInfo={onStepInfo} jobInfo={jobInfo} onReject={onReject}/>
        case JobStep.STEP_DATA_SOURCE.id:
            return <StepDataSource onStepInfo={onStepInfo} jobInfo={jobInfo} onReject={onReject}/>
        case JobStep.STEP_BREAKLINES.id:
            return <StepOptionalBreaklines onStepInfo={onStepInfo} jobInfo={jobInfo} onReject={onReject}/>
        case JobStep.STEP_COMPLETE_UPLOAD.id:
            return <StepUpload onStepInfo={onStepInfo} jobInfo={jobInfo} onReject={onReject}/>
        default:
            return <StepJobInfo/>
    }
}