import {Auth} from 'aws-amplify';
import {Subject} from "rxjs";
import {UserRepository} from "../../repositories/UserRepository";

export class RequestSignUp {
    /** @param {UserProfile} userProfile
     *  @param {Boolean} autoSignIn
     */
    constructor(userProfile, autoSignIn = true) {
        this.user = userProfile
        this.autoSignIn = autoSignIn
    }
}

export class SignUpUseCase {
    constructor(repository = new UserRepository()) {
        this.reposiroty = repository
    }

    /**
     *
     * @param {RequestSignUp} request
     * @return {Subject<UserProfile>}
     */
    execute(request) {
        const obs = new Subject()

        const {user, autoSignIn} = request
        const userParams = {
            username: user.userName,
            password: user.password,
            autoSignIn: {enabled: autoSignIn}
        }

        Auth.signUp(userParams)
            .then(cognitoUser => {
                user.cognitoId = cognitoUser.userSub
                this.reposiroty.createUser(user)
                    .subscribe(profileUser => {
                        obs.next(profileUser)
                        obs.complete()
                    })
            })
            .catch(e => {
                obs.error(e)
                obs.complete()
            })

        return obs
    }
}

export const TEMP_PASS = 'Resetme1@'