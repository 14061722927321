import {UserProfiles} from "../../models";
import {rolesEnum} from "../../assets/data/roles";

export default class UserProfile {
    constructor(id, cognitoId, userName, firstName, lastName, phone, companyId, role, status, password = null) {
        this.id = id
        this.cognitoId = cognitoId
        this.userName = userName
        this.firstName = firstName
        this.lastName = lastName
        this.email = userName
        this.phone = phone
        this.companyId = companyId
        this.role = role
        this.status = status
        this.password = password
        this.termsAgreedAt = null
        this.createdAt = ""
    }

    fullName() {
        return `${this.firstName} ${this.lastName}`
    }

    toUserProfileAmplifyModel() {
        // note id is read-only and is autogenerated every new Company instance
        return new UserProfiles({
            cognitoId: this.cognitoId,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            phone: this.phone,
            companyId: this.companyId,
            role: this.role,
            status: this.status,
            termsAgreedAt: this.termsAgreedAt
        })
    }

    static fromCognitoUser(cognitoUser, params) {
        const userProfile = new UserProfile(
            cognitoUser.userSub,
            cognitoUser.user.username,
            params.firstName,
            params.lastName
        )

        userProfile.companyId = 'to_define'
        userProfile.role = rolesEnum.USER.code

        return userProfile
    }

    /** @param {UserProfiles} userProfileAmplifyModel */
    static fromUserProfileAmplifyModel(userProfileAmplifyModel) {
        const userProfile = new UserProfile()
        userProfile.id = userProfileAmplifyModel.id
        userProfile.userName = userProfileAmplifyModel.email
        userProfile.cognitoId = userProfileAmplifyModel.cognitoId
        userProfile.email = userProfileAmplifyModel.email
        userProfile.firstName = userProfileAmplifyModel.firstName
        userProfile.lastName = userProfileAmplifyModel.lastName
        userProfile.email = userProfileAmplifyModel.email
        userProfile.phone = userProfileAmplifyModel.phone
        userProfile.companyId = userProfileAmplifyModel.companyId
        userProfile.role = userProfileAmplifyModel.role
        userProfile.status = userProfileAmplifyModel.status
        userProfile.termsAgreedAt  = userProfileAmplifyModel.termsAgreedAt
        userProfile.createdAt = userProfileAmplifyModel.createdAt
        return userProfile
    }
}