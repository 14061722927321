import {Subject} from "rxjs";
import {Auth} from "aws-amplify";

export class RequestChangePassword {
    constructor(oldPassword, newPassword) {
        this.oldPassword = oldPassword
        this.newPassword = newPassword
    }
}

export class ChangePasswordUseCase {
    // eslint-disable-next-line class-methods-use-this
    execute(request) {
        const {oldPassword, newPassword} = request

        const obs = new Subject()

        Auth.currentAuthenticatedUser()
            .then(authenticatedUser => {
                Auth.changePassword(authenticatedUser, oldPassword, newPassword)
                    .then(result => {
                        console.log('result', result)
                        obs.next(result)
                        obs.complete()
                    })
                    .catch(err => obs.error(err))
            })
            .catch(err => obs.error(err))

        return obs
    }
}