import {DataStore} from "aws-amplify";
import {map} from "rxjs";
import {
    acceptTermsAndConditions,
    createUser,
    fetchUsers,
    getUserByCognitoId,
    getUserById,
    getUsersByEmail,
    removeUser,
    updateUser,
    updateUserStatus
} from "./UserProfileUtils";
import UserProfile from "../../usecases/models/UserProfile";
import {createCompany, fetchCompanies, getCompany, removeCompany, updateCompany} from "./CompanyUtils";
import CompanyUC from "../../usecases/models/Company";
import {createJob, deleteJob, fetchAllJobs, updateJob, updateJobStatus} from "./JobUtils";
import Job from "../../usecases/models/Job";
import {getSettingByKey, updateSetting} from "./SettingsUtils";
import SettingsUC from "../../usecases/models/Settings";

export default class AmplifyDB {

    constructor(ds = DataStore) {
        this.ds = ds
    }

    /**
     * @param {UserProfile} userProfile
     * @return {Observable<UserProfile>}
     */
    createUser(userProfile) {
        const userProfileAmplifyModel = userProfile.toUserProfileAmplifyModel()
        return createUser(this.ds, userProfileAmplifyModel)
            .pipe(map(amplifyModel => UserProfile.fromUserProfileAmplifyModel(amplifyModel)))
    }

    getUserByCognitoId(userId) {
        return getUserByCognitoId(this.ds, userId)
    }

    getUserByEmail(userEmail) {
        return getUsersByEmail(this.ds, userEmail)
            .pipe(map(users => users.map(amplifyModel => UserProfile.fromUserProfileAmplifyModel(amplifyModel))))
    }

    getUserById(userId) {
        return getUserById(this.ds, userId)
            .pipe(map(amplifyModel => UserProfile.fromUserProfileAmplifyModel(amplifyModel)))
    }

    fetchUsers(userProfile) {
        return fetchUsers(this.ds, userProfile)
            .pipe(map(users => users.map(amplifyModel => UserProfile.fromUserProfileAmplifyModel(amplifyModel))))
    }

    removeUser(userId) {
        return removeUser(this.ds, userId)
            .pipe(map(amplifyModel => UserProfile.fromUserProfileAmplifyModel(amplifyModel)))
    }

    updateUser(userId, userUpdated) {
        return updateUser(this.ds, userId, userUpdated)
            .pipe(map(amplifyModel => UserProfile.fromUserProfileAmplifyModel(amplifyModel)))
    }

    updateUserStatus(userId, userStatus) {
        return updateUserStatus(this.ds, userId, userStatus)
            .pipe(map(amplifyModel => UserProfile.fromUserProfileAmplifyModel(amplifyModel)))
    }

    acceptTermsAndConditions(userId, dateAccepted) {
        return acceptTermsAndConditions(this.ds, userId, dateAccepted)
            .pipe(map(amplifyModel => UserProfile.fromUserProfileAmplifyModel(amplifyModel)))
    }

    /**
     * @param {CompanyUC} company
     * @return {Observable<CompanyUC>}
     */
    createCompany(company) {
        const companyAmplifyModel = company.toCompanyAmplifyModel()
        return createCompany(this.ds, companyAmplifyModel)
            .pipe(map(amplifyModel => CompanyUC.fromCompanyAmplifyModel(amplifyModel)))
    }

    /** @return {Observable<CompanyUC>} */
    getCompany(companyId) {
        return getCompany(this.ds, companyId)
            .pipe(map(amplifyModel => CompanyUC.fromCompanyAmplifyModel(amplifyModel)))}

    /** @return {Observable<Array<CompanyUC>>} */
    fetchCompanies() {
        return fetchCompanies(this.ds)
            .pipe(map(companies => companies.map(amplifyModel => CompanyUC.fromCompanyAmplifyModel(amplifyModel))))
    }

    /**
     * @param companyId
     * @return {Observable<CompanyUC>}
     */
    removeCompany(companyId) {
        return removeCompany(this.ds, companyId)
            .pipe(map(amplifyModel => CompanyUC.fromCompanyAmplifyModel(amplifyModel)))
    }

    /**
     * @param {String} companyId
     * @param {Object} companyUpdated
     * @return {Observable<CompanyUC>}
     */
    updateCompany(companyId, companyUpdated) {
        return updateCompany(this.ds, companyId, companyUpdated)
            .pipe(map(amplifyModel => CompanyUC.fromCompanyAmplifyModel(amplifyModel)))
    }

    /**
     * @param {Job} job
     * @return {Observable<Job>}
     */
    createJob(job) {
        const jobAmplifyModel = job.toJobAmplifyModel()
        return createJob(this.ds, jobAmplifyModel)
            .pipe(map(amplifyModel => Job.fromJobAmplifyModel(amplifyModel)))
    }

    /**
     * @param jobId
     * @return {Observable<Job>}
     */
    deleteJob(jobId) {
        return deleteJob(this.ds, jobId)
            .pipe(map(amplifyModel => Job.fromJobAmplifyModel(amplifyModel)))
    }

    /**
     * @param {String} jobId
     * @param {Object} jobUpdated
     * @return {Observable<Job>}
     */
    updateJob(jobId, jobUpdated) {
        return updateJob(this.ds, jobId, jobUpdated)
            .pipe(map(amplifyModel => Job.fromJobAmplifyModel(amplifyModel)))
    }

    /** @return {Observable<Array<Job>>} */
    fetchAllJobs(userProfile) {
        return fetchAllJobs(this.ds, userProfile)
            .pipe(map(jobs => jobs.map(amplifyModel => Job.fromJobAmplifyModel(amplifyModel))))
    }


    updateJobStatus(jobId, newStatus) {
        return updateJobStatus(this.ds, jobId, newStatus)
            .pipe(map(amplifyModel => Job.fromJobAmplifyModel(amplifyModel)))
    }

    getSettingByKey(settingKey) {
        return getSettingByKey(this.ds, settingKey)
            .pipe(map(settings => SettingsUC.fromSettingsAmplifyModel(settings[0])))
    }

    updateSetting(settingId, settingUpdated) {
        return updateSetting(this.ds, settingId, settingUpdated)
            .pipe(map(amplifyModel => SettingsUC.fromSettingsAmplifyModel(amplifyModel)))
    }
}