import PropTypes from 'prop-types';
import {Link as RouterLink} from "react-router-dom";
import {Button, InputAdornment, Stack, TextField} from '@mui/material';
import Iconify from '../../../../components/iconify';

CompanyTableToolbar.propTypes = {
    isFiltered: PropTypes.bool,
    filterInput: PropTypes.string,
    onFilterInput: PropTypes.func,
    onResetFilter: PropTypes.func,
}

export default function CompanyTableToolbar(
    {isFiltered, filterInput, onFilterInput, onResetFilter}
) {
    return (
        <Stack
            spacing={2}
            alignItems="center"
            direction={{
                xs: 'column',
                sm: 'row',
            }}
            sx={{px: 2.5, py: 3}}
        >

            <TextField
                fullWidth
                value={filterInput}
                onChange={onFilterInput}
                placeholder="Search by name, email or country"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon="eva:search-fill" sx={{color: 'text.disabled'}}/>
                        </InputAdornment>
                    ),
                }}
            />

            {isFiltered && (
                <Button
                    color="info"
                    sx={{flexShrink: 0}}
                    onClick={onResetFilter}
                    startIcon={<Iconify icon="mdi:clear-circle-multiple-outline"/>}
                >
                    Clear
                </Button>
            )}

            <Button
                component={RouterLink}
                to={"/dashboard/companies/new"}
                sx={{flexShrink: 0}}
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill"/>}
            >
                New&nbsp;Company
            </Button>

        </Stack>
    )
}
