import {Typography} from "@mui/material";
import PropTypes from "prop-types";
import useResponsive from "../../hooks/useResponsive";
import {StyledSection} from "../../pages/Login/LoginStyleUtils";

export default function LoginLeftBanner({title}) {

    const mdUp = useResponsive('up', 'md');

    return (
        <>
            {mdUp && (
                <StyledSection sx={{p: 10}}>
                    <Typography variant="h4" sx={{px: 5, mt: 10, mb: 5}}>
                        {title}
                    </Typography>
                    <img src="/assets/illustrations/datasight_logo_sm.png" alt="login"/>
                </StyledSection>
            )}
        </>
    )
}

LoginLeftBanner.propTypes = {
    title: PropTypes.string
}