export function getHtmlEmailFrom(userInfo, companyInfo, message) {

    let htmlEmail = '<div style="text-align: left; padding-top: 5px;">'+
        '<h4>'+
        '<strong>User Information:</strong></h4>'+
        '<ul>'+
        '<li>Name: name_value</li>'+
        '<li>Email: email_value</li>'+
        '<li>Company: company_value</li>'+
        '<li>Phone: phone_value<br /><br /></li>'+
        '</ul>'+
        '<p><strong>Message: </strong><br /><br />message_value</p>'+
        '</div>'

    const {firstName, lastName} = userInfo
    const fullName = `${firstName} ${lastName}`

    htmlEmail = htmlEmail.replace('name_value', fullName)
    htmlEmail = htmlEmail.replace('email_value', userInfo.email)
    htmlEmail = htmlEmail.replace('company_value', companyInfo.name)
    htmlEmail = htmlEmail.replace('phone_value', userInfo.phone)
    htmlEmail = htmlEmail.replace('message_value', message)

    return htmlEmail
}