import {Stack} from "@mui/material";
import PropTypes from "prop-types";
import {FilePreviewUpload} from "./FilePreviewUpload";

FilesPreviewUpload.propTypes = {
    filesInfo: PropTypes.array,
    progress: PropTypes.bool,
    descriptionVisible: PropTypes.bool,
    iconVisible: PropTypes.bool,
    actionDownload: PropTypes.func,
    actionDelete: PropTypes.func,
}

export function FilesPreviewUpload(
    {
        filesInfo,
        descriptionVisible = true,
        iconVisible = true,
        progress = true,
        actionDownload = null,
        actionDelete = null,
    }
) {

    return <>
        <Stack spacing={1}>
            {
                filesInfo.map((fileInfo, index) =>
                    <FilePreviewUpload
                        key={index}
                        filePreviewInfo={fileInfo}
                        progress={progress}
                        iconVisible={iconVisible}
                        descriptionVisible={descriptionVisible}
                        actionDownload={actionDownload}
                        actionDelete={actionDelete}
                    />
                )
            }
        </Stack>
    </>
}