import JobRepository from "../../repositories/JobRepository";

export class RequestDeleteJob {
    constructor(jobId) {
        this.jobId = jobId
    }
}

export class DeleteJobUseCase {
    constructor(repository = new JobRepository()) {
        this.repository = repository
    }

    execute(request) {
        const {jobId} = request
        return this.repository.deleteJob(jobId)
    }
}