import PropTypes from 'prop-types';
import * as Yup from "yup";
import {useEffect, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {Controller, useForm} from 'react-hook-form';
import {LoadingButton} from '@mui/lab';
import {Box, Card, Divider, FormControlLabel, Grid, Stack, Switch, Typography} from '@mui/material';
import {yupResolver} from "@hookform/resolvers/yup";
import {countries} from '../../../assets/data';
import Label from '../../../components/label';
import FormProvider, {RHFSelect, RHFTextField,} from '../../../components/hook-form';
import CreateCompanyUseCase, {RequestCreateCompany} from "../../../usecases/company/CreateCompanyUseCase";
import CompanyUC from "../../../usecases/models/Company";
import UseCase from "../../../usecases/UseCase";
import {PATH_DASHBOARD} from "../../../routes/paths";
import UpdateCompanyUseCase, {RequestUpdateCompany} from "../../../usecases/company/UpdateCompanyUseCase";
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";
import {DEFAULT_BILLING_RATE} from "../../../utils/constants";

CompanyNewEditForm.propTypes = {
    isEdit: PropTypes.bool,
    company: PropTypes.object
}

export default function CompanyNewEditForm({isEdit = false, company}) {

    const createCompany = new CreateCompanyUseCase()
    const updateCompany = new UpdateCompanyUseCase()

    const navigate = useNavigate()

    const NewUserSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        phone: Yup.string().notRequired(),
        address: Yup.string().notRequired(),
        country: Yup.string().notRequired(),
        state: Yup.string().notRequired(),
        city: Yup.string().notRequired(),
        zipCode: Yup.string().notRequired(),
        billingEmail: Yup.string().notRequired().email('Email must be a valid email address'),
        billingRate: Yup.number().required('Must have a valid dollar amount per acre'),
        quotaAcres: Yup.number().integer().notRequired(),
        quotaJobs: Yup.number().integer().notRequired()
    })

    const defaultValues = useMemo(
        () => ({
            name: company?.name || '',
            phone: company?.phone || '',
            address: company?.address || '',
            country: company?.country || '',
            state: company?.state || '',
            city: company?.city || '',
            zipCode: company?.zipCode || '',
            billingEmail: company?.billingEmail || '',
            billingRate: company?.billingRate || DEFAULT_BILLING_RATE,
            referrerEmail: company?.referrerEmail || '',
            status: company?.status || 'enabled',
            trial: company?.trial || false,
            quotaAcres: company?.quotaAcres || 0,
            quotaJobs: company?.quotaJobs || 0,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [company]
    )

    const methods = useForm({resolver: yupResolver(NewUserSchema), defaultValues})
    const {reset, watch, control, handleSubmit, formState: {isSubmitting}} = methods
    const values = watch()

    useEffect(() => {
        if (isEdit && company) reset(defaultValues)
        if (!isEdit) reset(defaultValues)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEdit, company])

    /** @return CompanyUC */
    function getCompanyFromFormData(data) {
        const companyFromForm = new CompanyUC()
        companyFromForm.id = ''
        companyFromForm.name = data.name
        companyFromForm.phone = data.phone
        companyFromForm.address = data.address
        companyFromForm.country = data.country
        companyFromForm.state = data.state
        companyFromForm.city = data.city
        companyFromForm.zipCode = data.zipCode
        companyFromForm.billingEmail = data.billingEmail
        companyFromForm.billingRate = Number(data.billingRate)
        companyFromForm.referrerEmail = data.referrerEmail
        companyFromForm.status = data.status
        companyFromForm.trial = data.trial
        companyFromForm.quotaAcres = Number(data.quotaAcres)
        companyFromForm.quotaJobs = Number(data.quotaJobs)
        return companyFromForm
    }

    const onCreateCompany = (data) => {
        const companyToCreate = getCompanyFromFormData(data)
        if (isEdit) {
            const request = new RequestUpdateCompany(company.id, companyToCreate)
            UseCase.execute(updateCompany, request, onCompanyUpdated, onFailure)
            return
        }

        const request = new RequestCreateCompany(companyToCreate)
        UseCase.execute(createCompany, request, onCompanyCreated, onFailure)
    }

    const onCompanyCreated = () => {
        navigate(PATH_DASHBOARD.companies.list, {replace: true})
    }

    const onFailure = (error) => {
        console.log('error', error)
    }

    const onCompanyUpdated = () => {
        navigate(PATH_DASHBOARD.companies.list, {replace: true})
    }


    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onCreateCompany)}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Card sx={{pt: 3, pb: 5, px: 3}}>

                        <CustomBreadcrumbs
                            links={[
                                {
                                    name: 'Companies',
                                    href: PATH_DASHBOARD.companies.list,
                                },
                                {name: isEdit ? 'Edit Company' : 'New Company'},
                            ]}
                        />

                        <Stack direction="row" spacing={2} justifyContent={"end"} sx={{pb: 3}}>

                            {values.trial && <Label
                                color={'warning'}
                                sx={{textTransform: 'uppercase', top: 24, right: 24}}
                            >
                                Trial
                            </Label>}

                            <Label
                                color={values.status === 'enabled' ? 'info' : 'error'}
                                sx={{textTransform: 'uppercase', top: 24, right: 24}}
                            >
                                {values.status}
                            </Label>

                        </Stack>

                        <FormControlLabel
                            labelPlacement="start"
                            control={
                                <Controller
                                    name="status"
                                    control={control}
                                    render={({field}) => (
                                        <Switch
                                            {...field}
                                            checked={field.value === 'enabled'}
                                            onChange={(event) => {
                                                // console.log('status change', event.target.checked)
                                                field.onChange(event.target.checked ? 'enabled' : 'disabled ')
                                            }}
                                        />
                                    )}
                                />
                            }
                            label={
                                <>
                                    <Typography variant="subtitle2" sx={{mb: 0.5}}>
                                        Enabled
                                    </Typography>
                                    <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                        Enable/Disable the account
                                    </Typography>
                                </>
                            }
                            sx={{mx: 0, mb: 3, width: 1, justifyContent: 'space-between'}}
                        />


                        <Divider sx={{mb: 3}}/>

                        <FormControlLabel
                            labelPlacement="start"
                            control={
                                <Controller
                                    name="trial"
                                    control={control}
                                    render={({field}) => (
                                        <Switch
                                            {...field}
                                            checked={field.value}
                                            onChange={(event) => {
                                                // console.log('trial change', event.target.checked)
                                                field.onChange(event.target.checked)
                                            }}
                                        />
                                    )}
                                />
                            }
                            label={
                                <>
                                    <Typography variant="subtitle2" sx={{mb: 0.5}}>
                                        Trial Account
                                    </Typography>
                                </>
                            }
                            sx={{mx: 0, mb: 0, width: 1, justifyContent: 'space-between'}}
                        />

                        {values.trial && <Stack spacing={2}>
                            <RHFTextField name="quotaAcres" label="Acre Quota" type="number" />
                            <RHFTextField name="quotaJobs" label="Job Quota" type="number" />
                        </Stack>}

                    </Card>
                </Grid>

                <Grid item xs={12} md={8}>
                    <Card sx={{p: 3}}>

                        <Typography variant="h4" gutterBottom sx={{pb:2}}>
                            { isEdit ? 'Edit Company' : 'New Company' }
                        </Typography>

                        <Box
                            rowGap={3}
                            columnGap={2}
                            display="grid"
                            paddingBottom={3}
                            gridTemplateColumns={{
                                xs: 'repeat(1, 1fr)',
                                sm: 'repeat(2, 1fr)',
                            }}
                        >
                            <RHFTextField name="name" label="Company Name" required/>
                            <RHFTextField name="phone" label="Phone Number"/>
                        </Box>

                        <Box
                            display="grid"
                            rowGap={3}
                            paddingBottom={3}
                            gridTemplateColumns={{
                                xs: 'repeat(1, 1fr)',
                                sm: 'repeat(1, 1fr)',
                            }}
                        >
                            <RHFTextField name="address" label="Company Address"/>
                        </Box>

                        <Box
                            rowGap={3}
                            columnGap={2}
                            display="grid"
                            gridTemplateColumns={{
                                xs: 'repeat(1, 1fr)',
                                sm: 'repeat(2, 1fr)',
                            }}
                        >
                            <RHFSelect native name="country" label="Country" placeholder="Country">
                                <option value=""/>
                                {countries.map((country) => (
                                    <option key={country.code} value={country.label}>
                                        {country.label}
                                    </option>
                                ))}
                            </RHFSelect>

                            <RHFTextField name="state" label="State/Region"/>
                            <RHFTextField name="city" label="City"/>
                            <RHFTextField name="zipCode" label="Zip/Code"/>
                            <RHFTextField name="billingEmail" label="Billing Email"/>
                            <RHFTextField name="billingRate" label="Billing Rate"/>
                            <RHFTextField name="referrerEmail" label="Referrer Email"/>

                        </Box>

                        <Stack alignItems="flex-end" sx={{mt: 3}}>
                            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                                {!isEdit ? 'Create Company' : 'Save Changes'}
                            </LoadingButton>
                        </Stack>
                    </Card>
                </Grid>
            </Grid>
        </FormProvider>
    )
}