import AmplifyDB from "../services/amplify/AmplifyDB";

export class UserRepository {
    /** @param {AmplifyDB} service */
    constructor(service = new AmplifyDB()) {
        this.service = service
    }

    /**
     * @param {UserProfile} userProfile
     * @return {Observable<UserProfile>}
     */
    createUser(userProfile) {
        return this.service.createUser(userProfile)
    }

    getUserByCognitoId(cognitoId) {
        return this.service.getUserByCognitoId(cognitoId)
    }

    getUserByEmail(userEmail) {
        return this.service.getUserByEmail(userEmail)
    }

    getUserById(userId) {
        return this.service.getUserById(userId)
    }

    fetchUsers(userProfile) {
        return this.service.fetchUsers(userProfile)
    }

    removeUser(userId) {
        return this.service.removeUser(userId)
    }

    updateUser(userId, userUpdated) {
        return this.service.updateUser(userId, userUpdated)
    }

    disableUser(userId) {
        return this.service.updateUserStatus(userId, 'disabled')
    }

    enableUser(userId) {
        return this.service.updateUserStatus(userId, 'enabled')
    }

    acceptTerms(userId, dateFormatted) {
        return this.service.acceptTermsAndConditions(userId, dateFormatted)
    }
}