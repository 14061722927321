import {Link as RouterLink, useLocation, useNavigate} from "react-router-dom";
import {Box, Container, Divider, LinearProgress, Link, Stack, Typography} from '@mui/material';
import {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import PropTypes from "prop-types";
import getAppName from "../../utils/EnvUtils";
import {StyledContent} from "./LoginStyleUtils";
import VerifyCodeForm from "../../sections/auth/login/VerifyCodeForm";
import {Head} from "../../components/utils";
import {isNonEmpty} from "../../utils/StringUtils";
import {EMPTY_STRING} from "../../utils/constants";
import VerifyCodeUseCase, {RequestVerifyCode} from "../../usecases/login/VerifyCodeUseCase";
import {useAuthContext} from "../../auth/AuthProvider";
import {TEMP_PASS} from "../../usecases/login/SignUpUseCase";
import SendCodeUseCase, {RequestSendCode} from "../../usecases/login/SendCodeUseCase";
import UseCase from "../../usecases/UseCase";

export default function VerifyCodePage() {

    const appName = getAppName()
    const location = useLocation()
    const navigate = useNavigate()
    const {enqueueSnackbar} = useSnackbar()

    const {login} = useAuthContext()

    const verifyCode = new VerifyCodeUseCase()
    const sendCodeUseCase = new SendCodeUseCase()

    const {state, search} = location

    const [verifyInProgress, setVerifyInProgress] = useState(false)
    const [errorOnVerification, setErrorOnVerification] = useState(null)

    const params = new Map()

    const getUserEmail = () => isNonEmpty(state) ? state.userInfo.email : EMPTY_STRING

    const [formInfo, setFormInfo] = useState({
        // email: params.get('email'),
        email: getUserEmail(),
        code: params.get('code')
    })

    useEffect(() => {
        setVerifyInProgress(true)

        if (isNonEmpty(search)) {
            console.log(search)

            const searchParams = search.substring(1)
            const rawParams = searchParams.split('&')

            rawParams.forEach(rawParam => {
                const paramContent = rawParam.split('=')
                const paramKey = paramContent[0]
                const paramValue = paramContent[1]
                params.set(paramKey, paramValue)
            })

            if (params.has("code") && params.has("email")) {
                const userEmail = params.get("email")
                const code = params.get("code")

                setFormInfo({
                    email: userEmail,
                    code: null
                })

                verifyEmail(userEmail, code)
            } else {
                setVerifyInProgress(false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    const userInfo = {}

    useEffect(() => {

        if (state != null) {
            const {userInfo} = state

            setFormInfo({
                email: userInfo.email,
                code: null
            })

            setVerifyInProgress(false)
        }

    }, [location, state])


    function verifyEmail(email, code) {
        console.log('verifying account for ', email, code)
        const request = new RequestVerifyCode(email, code)
        UseCase.execute(verifyCode, request, onCodeVerified, onVerificationError)
    }

    function onCodeVerifiedByInvitation() {
        navigate('/verification/setup-password', {replace: true})
    }

    function onCodeVerifiedBySelfSignUp() {
        navigate('/auth/login', {replace: true})
        enqueueSnackbar("You have been verified", {variant: 'success'})
    }

    const onCodeVerified = () => {
        const userEmail = params.get("email")
        login(userEmail, TEMP_PASS).subscribe({
            next: () => onCodeVerifiedByInvitation,
            error: err => {
                if (err.code === 'NotAuthorizedException') {
                    onCodeVerifiedBySelfSignUp()
                    return
                }

                navigate('/auth/login', {replace: true})
                enqueueSnackbar(err.message, {variant: 'error'})
            }
        })
    }

    const onVerificationError = (error) => {
        setErrorOnVerification(error)
        console.log('something went wrong', error)
    }

    const resendCode = () => {
        const userEmail = formInfo.email

        const request = new RequestSendCode(userEmail)
        sendCodeUseCase.execute(request).subscribe({
            next: () => {
                enqueueSnackbar(`New invitation was sent to ${userEmail}`, {variant: 'info'})
                navigate('/auth', {replace: true})
            },
            error: (err) => enqueueSnackbar(err, {variant: 'error'})
        })
    }

    function getMessageSection(error) {
        switch (error.code) {
            case 'ExpiredCodeException':
                return <Typography variant="body2" align="center">
                    Your code is invalid or has expired. Please click
                    <Link component={RouterLink}
                          variant="subtitle2"
                          onClick={resendCode}
                          sx={{mx: 1}}
                    >
                        Resend code
                    </Link>
                    to request a new one.
                </Typography>
            default:
                return <Typography variant="body2">
                    {error.message}
                </Typography>
        }
    }


    AutoVerifySection.propTypes = {
        error: PropTypes.string,
    };

    function AutoVerifySection({error}) {
        return <>
            {
                error ?
                    <Stack>
                        {getMessageSection(error)}

                        <Divider sx={{marginY: 4}}/>
                        <Typography variant="body2" align="center">
                            <Link component={RouterLink} variant="subtitle2"
                                  to={'/auth/login'}
                            >
                                Return to sign in
                            </Link>
                        </Typography>
                    </Stack>
                    :
                    <Box sx={{width: '80%'}}>
                        <Typography variant="subtitle1">
                            Verifying account
                        </Typography>

                        <LinearProgress/>
                    </Box>
            }
        </>
    }

    return (
        <>
            <Head title={`Sign Up | ${appName}`}/>

            <Container maxWidth="sm">
                <StyledContent>

                    {
                        verifyInProgress ?
                            <AutoVerifySection error={errorOnVerification}/>
                            :
                            <VerifyCodeForm
                                email={formInfo.email}
                                code={formInfo.code}
                                sendCode={userInfo.sendCode}
                            />
                    }

                </StyledContent>
            </Container>
        </>
    );
}
