import {Container, Link, Stack, Typography} from "@mui/material";
import {Link as RouterLink, useLocation} from 'react-router-dom';
import getAppName from "../../utils/EnvUtils";
import {StyledContent} from "./LoginStyleUtils";
import {Head} from "../../components/utils";
import NewPasswordForm from "../../sections/auth/login/NewPasswordForm";

export default function NewPasswordPage() {

    const appName = getAppName()
    const {state} = useLocation()
    const {userInfo} = state

    return (
        <>
            <Head title={`New Password | ${appName}`}/>

            <Container maxWidth="sm">
                <StyledContent>

                    <Typography variant="h4" gutterBottom>
                        Set Your Password
                    </Typography>

                    <Typography variant="body2" sx={{mb: 5}}>
                        We've sent a 6-digit code to your email. Please enter the code and your new password below.
                    </Typography>

                    <NewPasswordForm
                        email={userInfo.email}
                        needVerifyTerms={userInfo.needTerms}
                    />

                    <Stack direction="row" justifyContent="center" marginTop={2}>
                        <Typography variant="body2">
                            <Link component={RouterLink} variant="subtitle2"
                                  to={'/auth/login'}
                            >
                                Return to sign in
                            </Link>
                        </Typography>
                    </Stack>

                </StyledContent>
            </Container>

        </>
    )
}