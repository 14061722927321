import PropTypes from 'prop-types';
import * as Yup from "yup";
import {useEffect, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {LoadingButton} from '@mui/lab';
import {Box, Card, FormControlLabel, Grid, Stack, Switch, Typography} from '@mui/material';
import Label from '../../../components/label';
import FormProvider, {RHFSelect, RHFTextField,} from '../../../components/hook-form';
import {getArrayRolesForCreation, rolesEnum} from "../../../assets/data/roles";
import UserProfile from "../../../usecases/models/UserProfile";
import {RequestSignUp, SignUpUseCase, TEMP_PASS} from "../../../usecases/login/SignUpUseCase";
import UseCase from "../../../usecases/UseCase";
import {PATH_DASHBOARD} from "../../../routes/paths";
import UpdateUserUseCase, {RequestUpdateUser} from "../../../usecases/user/UpdateUserUseCase";
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";
import {useAuthContext} from "../../../auth/AuthProvider";

UserNewEditForm.propTypes = {
    isEdit: PropTypes.bool,
    userProfile: PropTypes.object,
    companies: PropTypes.array
}

export default function UserNewEditForm({isEdit = false, userProfile, companies}) {

    const signUp = new SignUpUseCase()
    const updateUser = new UpdateUserUseCase()

    const navigate = useNavigate()
    const {user} = useAuthContext()

    // const { enqueueSnackbar } = useSnackbar()

    function getDefaultCompany() {
        return isEdit ? userProfile.companyId : user.companyId
    }

    const NewUserSchema = Yup.object().shape({
        firstName: Yup.string().required('Name is required'),
        lastName: Yup.string().notRequired(),
        email: Yup.string().required().email('Email must be a valid email address'),
        phone: Yup.string().notRequired(),
        company: Yup.string().notRequired(),
        role: Yup.string().required('Role is required')
    })

    const defaultValues = useMemo(
        () => ({
            firstName: userProfile?.firstName || '',
            lastName: userProfile?.lastName || '',
            email: userProfile?.email || '',
            phone: userProfile?.phone || '',
            company: getDefaultCompany(),
            role: userProfile?.role || '',
            status: getStatus(userProfile.status),
            redirectTo: getRedirect(userProfile.status)
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [userProfile]
    )

    const methods = useForm({
        resolver: yupResolver(NewUserSchema),
        defaultValues,
    })

    const {
        reset,
        watch,
        control,
        handleSubmit,
        formState: {isSubmitting},
    } = methods

    const values = watch()

    useEffect(() => {
        console.log('profile', userProfile)

        if (isEdit && userProfile) reset(defaultValues)
        if (!isEdit) reset(defaultValues)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEdit, userProfile])

    function getStatus(value) {
        if (value === null) return 'enabled'
        const settings = value.split('_')
        return settings[0]
    }

    function getRedirect(value) {
        const settings = value.split('_')
        if (settings.length === 1) return "info"
        return settings[1]
    }

    function getUserFromFormData(data) {
        const userFromForm = new UserProfile()
        userFromForm.userName = data.email
        userFromForm.cognitoId = data.cognitoId
        userFromForm.email = data.email
        userFromForm.firstName = data.firstName
        userFromForm.lastName = data.lastName
        userFromForm.email = data.email
        userFromForm.phone = data.phone
        userFromForm.companyId = data.company
        userFromForm.role = data.role
        userFromForm.status = `${data.status}_${data.redirectTo}`

        return userFromForm
    }

    const onCreateUser = (data) => {
        const userToCreate = getUserFromFormData(data)
        console.log('user to create/edit', userToCreate)

        if (isEdit) {
            const request = new RequestUpdateUser(userProfile.id, userToCreate)
            UseCase.execute(updateUser, request, onUserUpdated, onFailure)
            return
        }

        // amplify forces set a password when register a new user
        userToCreate.password = TEMP_PASS
        userToCreate.status = 'reset_password'
        const request = new RequestSignUp(userToCreate, false)
        UseCase.execute(signUp, request, onUserCreated, onFailure)
    }

    const onUserUpdated = (user) => {
        console.log('user updated successfully', user)
        navigate(PATH_DASHBOARD.users.list, {replace: true})

    }

    const onUserCreated = (user) => {
        console.log('user created successfully', user)
        navigate(PATH_DASHBOARD.users.list, {replace: true})
    }

    const onFailure = (error) => {
        console.log('unable to create user', error)
    }

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onCreateUser)}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Card sx={{pt: 3, pb: 5, px: 3}}>

                        <CustomBreadcrumbs
                            links={[
                                {
                                    name: 'Users',
                                    href: PATH_DASHBOARD.users.list,
                                },
                                {name: isEdit ? 'Edit user' : 'New User' },
                            ]}
                        />

                        <Stack direction="row" spacing={2} justifyContent={"end"} sx={{pb: 3}}>

                            <Label
                                color={values.status === 'enabled' ? 'info' : 'error'}
                                sx={{textTransform: 'uppercase', top: 24, right: 24}}
                            >
                                {values.status}
                            </Label>

                        </Stack>

                        <FormControlLabel
                            labelPlacement="start"
                            control={
                                <Controller
                                    name="status"
                                    control={control}
                                    render={({field}) => (
                                        <Switch
                                            {...field}
                                            checked={field.value === 'enabled'}
                                            onChange={(event) => {
                                                // console.log('status change', event.target.checked)
                                                field.onChange(event.target.checked ? 'enabled' : 'disabled ')
                                            }}
                                        />
                                    )}
                                />
                            }
                            label={
                                <>
                                    <Typography variant="subtitle2" sx={{mb: 0.5}}>
                                        Enabled
                                    </Typography>
                                    <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                        Enable/Disable the account
                                    </Typography>
                                </>
                            }
                            sx={{mx: 0, mb: 3, width: 1, justifyContent: 'space-between'}}
                        />

                        {
                            user.role === rolesEnum.SUPER_ADMIN.code && <FormControlLabel
                                labelPlacement="start"
                                control={
                                    <Controller
                                        name="redirectTo"
                                        control={control}
                                        render={({field}) => (
                                            <Switch
                                                {...field}
                                                checked={field.value === 'info'}
                                                onChange={(event) => {
                                                    console.log('status change', event.target.checked)
                                                    field.onChange(event.target.checked ? 'info' : 'jobs')
                                                }}
                                            />
                                        )}
                                    />
                                }
                                label={
                                    <>
                                        <Typography variant="subtitle2" sx={{mb: 0.5}}>
                                            Default first page
                                        </Typography>
                                        <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                            Redirect to Jobs/Info. switch on to redirect to Info, switch off to redirect to jobs
                                        </Typography>
                                    </>
                                }
                                sx={{mx: 0, mb: 3, width: 1, justifyContent: 'space-between'}}
                            />
                        }

                    </Card>
                </Grid>

                <Grid item xs={12} md={8}>
                    <Card sx={{p: 3}}>
                        <Typography variant="h4" gutterBottom>
                            { isEdit ? 'Edit user' : 'New User' }
                        </Typography>

                        <Box
                            rowGap={3}
                            columnGap={2}
                            display="grid"
                            gridTemplateColumns={{
                                xs: 'repeat(1, 1fr)',
                                sm: 'repeat(2, 1fr)',
                            }}
                        >
                            <RHFTextField name="firstName" label="First Name" required/>
                            <RHFTextField name="lastName" label="Last Name"/>
                            <RHFTextField name="email" label="Email Address" required disabled={isEdit}/>
                            <RHFTextField name="phone" label="Phone Number"/>

                            {
                                user.role === rolesEnum.SUPER_ADMIN.code &&
                                <RHFSelect name="company" label="Company" placeholder="Company"
                                           required native open
                                >
                                    <option value=""/>
                                    {
                                        companies.map((company) => (
                                            <option key={company.id} value={company.id}>
                                                {company.name}
                                            </option>
                                        ))
                                    }
                                </RHFSelect>
                            }

                            <RHFSelect name="role" label="Role" placeholder="Role"
                                       required native
                            >
                                <option value="" selected={false}/>
                                {
                                    getArrayRolesForCreation(user).map(({label, code}) => (
                                        <option key={code} value={code}>
                                            {label}
                                        </option>
                                    ))
                                }
                            </RHFSelect>
                        </Box>

                        <Stack alignItems="flex-end" sx={{mt: 3}}>
                            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                                {!isEdit ? 'Create User' : 'Save Changes'}
                            </LoadingButton>
                        </Stack>
                    </Card>
                </Grid>
            </Grid>
        </FormProvider>
    )
}
