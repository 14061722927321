import PropTypes from 'prop-types'
import React, {useState} from 'react'
import {
    Alert,
    AlertTitle,
    Button,
    Collapse,
    IconButton,
    MenuItem,
    Stack,
    TableCell,
    TableRow,
    Typography
} from '@mui/material'
import Iconify from "../../../../components/iconify"
import MenuPopover from "../../../../components/menu-popover/MenuPopover"
import ConfirmDialog from "../../../../components/confirm-dialog"
import Label from "../../../../components/label"
import {isNonEmpty} from "../../../../utils/StringUtils"
import {getJobOutputUrl, JobStatus} from "../../../../utils/JobUtils"
import {rolesEnum} from "../../../../assets/data/roles";
import {useAuthContext} from "../../../../auth/AuthProvider";
import JobResumeCard from "./JobResumeCard";
import {AWS_S3_BASE_URL, EMPTY_STRING} from "../../../../utils/constants";
import awsExports from "../../../../aws-exports";

JobTableRow.propTypes = {
    job: PropTypes.object,
    onEditRow: PropTypes.func,
    onDeleteJob: PropTypes.func,
    onRun: PropTypes.func
}

export default function JobTableRow({job, onEditRow, onDeleteJob, onRun}) {
    const {id, name, acreage, jobCreatedAt, status, errorMessage, downloadUrl, createdAt, ecsArn} = job

    const {user} = useAuthContext()

    const [openConfirm, setOpenConfirm] = useState(false)
    const [openPopover, setOpenPopover] = useState(null)
    const [open, setOpen] = useState(false)
    const [isButtonActive, setIsButtonActive] = useState(false)

    const handleCloseConfirm = () => setOpenConfirm(false)
    const handleOpenPopover = (event) => setOpenPopover(event.currentTarget)
    const handleClosePopover = () => setOpenPopover(null)

    const handleRun = async () => {
        setIsButtonActive(true)
        await onRun()
        setIsButtonActive(false)
    }

    function getColorStatus(status) {
        switch (status) {
            case JobStatus.PENDING:
                return "warning"
            case JobStatus.READY:
                return "info"
            case JobStatus.UPLOADING:
            case JobStatus.RUNNING:
                return "info"
            case JobStatus.COMPLETED:
                return "success"
            case JobStatus.FAILED:
                return "error"
            default:
                return "warning"
        }
    }

    function getStatusName(status) {
        return `${status}`
    }

    function existError() {
        return isNonEmpty(errorMessage)
            && errorMessage !== 'none'
            && !isJobCompleted()
    }

    function isJobCompleted() {
        return status === JobStatus.COMPLETED
    }

    TableButton.propTypes = {
        icon: PropTypes.string,
        label: PropTypes.string,
        onClick: PropTypes.func,
    }

    function TableButton({icon, label, onClick}) {
        return (
            <Button
                onClick={onClick}
                size="small"
                variant="outlined"
                startIcon={<Iconify icon={icon} width={20}/>}
                color="secondary"
                disabled={isButtonActive}
                sx={{width: 120}}
            >
                {label}
            </Button>
        )
    }

    function downloadJobResult() {
        console.log('downloading job', downloadUrl)
        const link = document.createElement("a")
        link.download = `downloadJob.zip`;
        link.href = downloadUrl
        link.click()
    }

    function getAcreageFormatted() {
        return !acreage ? EMPTY_STRING : acreage.toFixed(1)
    }

    function getRemainingDaysForDownload(dateJobCreation) {
        const today = new Date()
        const lastDayToDownload = new Date(dateJobCreation)
        lastDayToDownload.setDate(lastDayToDownload.getDate() + 7)

        const remainingDays = lastDayToDownload.getDate() - today.getDate()
        return remainingDays
    }

    const navToJobLog = () => {
        handleClosePopover()

        const baseLogUrl = "https://us-east-2.console.aws.amazon.com/cloudwatch/home?region=us-east-2#logsV2:log-groups/log-group/$252Fecs$252Fbreakline/log-events/ecs$252Fengine-worker$252F"
        const logId = ecsArn.split("/").pop()
        window.open(`${baseLogUrl}${logId}`, '_blank')
    }

    const navToS3OutputBucket = () => {
        const stream = downloadUrl.split("output/")
        const tokens = stream[1].split("/")

        const userId = tokens[0]
        const jobId = tokens[1]
        const baseS3Url = AWS_S3_BASE_URL
        const bucketName = awsExports.aws_user_files_s3_bucket
        const bucketRegion = awsExports.aws_user_files_s3_bucket_region

        const jobOutputUrl = getJobOutputUrl(baseS3Url, bucketName, bucketRegion, userId, jobId)

        window.open(jobOutputUrl, '_blank')
    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(downloadUrl)
        handleClosePopover()
    }

    return (
        <>
            <TableRow sx={{'& > *': {borderBottom: 'unset'}}} hover>

                <TableCell component="th" scope="row" onClick={() => setOpen(!open)}>
                    {name}
                </TableCell>
                <TableCell align="left" onClick={() => setOpen(!open)}>{getAcreageFormatted()}</TableCell>
                <TableCell align="left" onClick={() => setOpen(!open)}>{jobCreatedAt}</TableCell>
                <TableCell align="center" onClick={() => setOpen(!open)}>
                    <Label
                        variant="soft"
                        color={getColorStatus(status)}
                        sx={{textTransform: 'capitalize', width: 100}}
                    >
                        {status === JobStatus.RUNNING && <Iconify icon="line-md:loading-twotone-loop" width={20} sx={{pr:0.5}} />}
                        {status === JobStatus.FAILED && <Iconify icon="material-symbols:error" width={20} sx={{pr:0.5}} />}
                        {status === JobStatus.UPLOADING && <Iconify icon="line-md:uploading-loop" width={20} sx={{pr:0.5}} />}
                        {status === JobStatus.COMPLETED && <Iconify icon="gg:check-o" width={20} sx={{pr:0.5}} />}
                        {getStatusName(status)}
                    </Label>

                </TableCell>

                <TableCell align="center">
                    { status === JobStatus.COMPLETED &&
                        <TableButton
                            label="Download"
                            icon="material-symbols:download"
                            onClick={() => downloadJobResult(downloadUrl)}
                            width={50}
                        />
                    }
                    { status === JobStatus.READY &&
                        <TableButton
                            label="Run"
                            icon="material-symbols:playcircle"
                            onClick={handleRun}
                        />
                    }
                    { status === JobStatus.FAILED && (user?.role === rolesEnum.SUPER_ADMIN.code) &&
                        <TableButton
                            icon="material-symbols:playcircle"
                            label="Retry"
                            onClick={handleRun}
                        />
                    }
                </TableCell>

                <TableCell align="right">
                    {user.role === rolesEnum.SUPER_ADMIN.code && <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}>
                         <Iconify icon="eva:more-vertical-fill" width={20}/>
                    </IconButton>}
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Stack sx={{margin: 1}}>

                            <Typography variant="overline">
                                Job ID: {id.substr(0, 6)}
                            </Typography>

                            {
                                existError() &&
                                <Alert severity="error">
                                    <AlertTitle>Unable to generate breaklines from source files: {errorMessage}</AlertTitle>
                                </Alert>
                            }

                            <JobResumeCard data={job}/>

                            {
                                isJobCompleted() &&
                                <Typography variant="caption" alignSelf={"end"}>
                                    { getRemainingDaysForDownload(createdAt) } remaining days to download.
                                </Typography>
                            }

                        </Stack>
                    </Collapse>
                </TableCell>
            </TableRow>

            {user.role === rolesEnum.SUPER_ADMIN.code && <MenuPopover
                open={openPopover}
                onClose={handleClosePopover}
                arrow="right-top"
                sx={{width: 160}}
            >
                {
                    job.hasLogs() &&
                    <MenuItem onClick={navToJobLog}>
                        <Iconify icon="mdi:console"/>
                        Logs
                    </MenuItem>
                }

                {
                    isJobCompleted() &&
                    <MenuItem onClick={copyToClipboard}>
                        <Iconify icon="mdi:link-variant"/>
                        Download URL
                    </MenuItem>
                }

                {
                    isJobCompleted() &&
                    <MenuItem onClick={navToS3OutputBucket}>
                        <Iconify icon="clarity:storage-solid"/>
                         S3 Outputs
                    </MenuItem>
                }

                <MenuItem
                    onClick={() => {
                        onEditRow()
                        handleClosePopover()
                    }}
                >
                    <Iconify icon="mdi:square-edit-outline"/>
                    Edit
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        setOpenConfirm(true)
                        handleClosePopover()
                    }}
                >
                    <Iconify icon="material-symbols:delete-outline"/>
                    Delete
                </MenuItem>

            </MenuPopover>}

            <ConfirmDialog
                open={openConfirm}
                onClose={handleCloseConfirm}
                title={`Delete job`}
                content={`Are you sure want to delete job: ${name}?`}
                action={
                    <Button variant="contained" color="error" onClick={() => {
                        setOpenConfirm(false)
                        onDeleteJob()
                    }}>
                        Delete
                    </Button>
                }
            />
        </>

    )
}