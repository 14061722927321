import AmplifyDB from "../services/amplify/AmplifyDB";

export default class CompanyRepository {
    /** @param {AmplifyDB} service */
    constructor(service = new AmplifyDB()) {
        this.service = service
    }

    /**
     * @param {CompanyUC} company
     * @return {Observable<CompanyUC>}
     */
    createCompany(company) {
        return this.service.createCompany(company)
    }

    /** @return {Observable<CompanyUC>>} */
    getCompany(companyId) {
        return this.service.getCompany(companyId)
    }

    /** @return {Observable<Array<CompanyUC>>} */
    fetchCompanies() {
        return this.service.fetchCompanies()
    }

    removeCompany(companyId) {
        return this.service.removeCompany(companyId)
    }

    updateCompany(companyId, companyUpdated) {
        return this.service.updateCompany(companyId, companyUpdated)
    }
}