import {Company} from "../../models";

export default class CompanyUC {
    constructor(
        id, name, phone, address, country, state, city, zipCode,
        billingEmail, billingRate, referrerEmail, status, trial, quotaAcres, quotaJobs
    ) {
        this.id = id
        this.name = name
        this.phone = phone
        this.address = address
        this.country = country
        this.state = state
        this.city = city
        this.zipCode = zipCode
        this.billingEmail = billingEmail
        this.billingRate = billingRate
        this.referrerEmail = referrerEmail
        this.status = status
        this.trial = trial
        this.quotaAcres = quotaAcres
        this.quotaJobs = quotaJobs
        this.accountUpgradedAt = null
        this.createdAt = ""
    }

    getProcessedBillingRate() {
        return (this.trial) ? 0 : this.billingRate
    }

    toCompanyAmplifyModel() {
        // note id is read-only and is autogenerated every new Company instance
        return new Company({
            name: this.name,
            phone: this.phone,
            address: this.address,
            country: this.country,
            state: this.state,
            city: this.city,
            zipCode: this.zipCode,
            billingEmail: this.billingEmail,
            billingRate: this.billingRate,
            referrerEmail: this.referrerEmail,
            status: this.status,
            trial: this.trial,
            quotaAcres: this.quotaAcres,
            quotaJobs: this.quotaJobs,
            accountUpgradedAt: this.accountUpgradedAt
        })
    }

    /** @param {Company} companyAmplifyModel */
    static fromCompanyAmplifyModel(companyAmplifyModel) {
        const company = new CompanyUC()
        company.id = companyAmplifyModel.id
        company.name = companyAmplifyModel.name
        company.phone = companyAmplifyModel.phone
        company.address = companyAmplifyModel.address
        company.country = companyAmplifyModel.country
        company.state = companyAmplifyModel.state
        company.city = companyAmplifyModel.city
        company.zipCode = companyAmplifyModel.zipCode
        company.billingEmail = companyAmplifyModel.billingEmail
        company.billingRate = companyAmplifyModel.billingRate
        company.referrerEmail = companyAmplifyModel.referrerEmail
        company.status = companyAmplifyModel.status
        company.trial = companyAmplifyModel.trial
        company.quotaAcres = companyAmplifyModel.quotaAcres
        company.quotaJobs = companyAmplifyModel.quotaJobs
        company.accountUpgradedAt = companyAmplifyModel.accountUpgradedAt
        company.createdAt = companyAmplifyModel.createdAt
        return company
    }
}