import {Container} from '@mui/material';
import {useLocation} from "react-router-dom";
import UserNewEditForm from "../../sections/@dashboard/user/UserNewEditForm";

export default function EditUserPage() {

    const {state} = useLocation()
    const {user: userProfile, companies} = state

    return (
        <>
            <Container maxWidth={'lg'}>
                <UserNewEditForm isEdit userProfile={userProfile} companies={companies}/>
            </Container>
        </>
    );
}
