import {fData} from "../../../utils/formatNumber";
import {EMPTY_STRING} from "../../../utils/constants";

export class FileUploadPreviewModel {
    constructor(blob, name, description, progressValue = 0) {
        this.blob = blob
        this.name = name
        this.description = description
        this.progressValue = progressValue
    }

    static fromFile(file) {
        return new FileUploadPreviewModel(
            file,
            file.name,
            fData(file.size),
            0
        )
    }

    static fromFileName(fileName, uploadProgressValue = 100) {
        return new FileUploadPreviewModel(
            null,
            fileName,
            EMPTY_STRING,
            uploadProgressValue
        )
    }
}