import JobRepository from "../../repositories/JobRepository";

export class RequestCreateJob {
    constructor(job) {
        this.job = job
    }
}

export default class CreateJobUseCase {
    constructor(repository = new JobRepository()) {
        this.repository = repository
    }

    /**
     * @param {RequestCreateJob} request
     * @return {Observable<Job>}
     */
    execute(request) {
        const {job} = request
        return this.repository.createJob(job)
    }
}