import {UserRepository} from "../../repositories/UserRepository";

export class RequestGetUsers {
    constructor(userProfile) {
        this.userProfile = userProfile
    }
}

export default class GetUsersUseCase {
    constructor(repository = new UserRepository()) {
        this.repository = repository
    }

    execute(request) {
        const {userProfile} = request
        return this.repository.fetchUsers(userProfile)
    }
}