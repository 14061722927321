import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
import Iconify from "../iconify";

Article.propTypes = {
    title: PropTypes.string,
    description: PropTypes.node,
    mediaUrl: PropTypes.string,
    expanded: PropTypes.bool,
}

export default function Article({title, description, mediaUrl, expanded = false}) {
    return <>
        <Accordion defaultExpanded={expanded}>
            <AccordionSummary
                expandIcon={<Iconify icon="material-symbols:expand-more"/>}
            >
                <Typography>{title}</Typography>
            </AccordionSummary>

            <AccordionDetails>

                <Typography>{description}</Typography>
                <ReactPlayer url={mediaUrl} width="auto"/>

            </AccordionDetails>
        </Accordion>
    </>
}