export default function GlobalDebug(environment) {

    // note: environment provided by NODE_ENV is
    // production: if app is deployed no matter the branch,
    // development: otherwise
    if (environment === 'production') {
        console.log = () => {}
        console.warn = () => {}
        console.info = () => {}
        console.error = () => {}
    }
}