import Iconify from "../../../components/iconify";
import {rolesEnum} from "../../../assets/data/roles";
import {PATH_DASHBOARD} from "../../../routes/paths";

export function getNavConfig(role) {
    switch (role) {
        case rolesEnum.USER.code:
            return navConfigUser
        case rolesEnum.ADMIN.code:
            return navConfigAdmin
        case rolesEnum.SUPER_ADMIN.code:
            return navConfigSuperAdmin
        default:
            return navConfigUser
    }
}

export const navConfigSuperAdmin = [
    {
        title: 'Jobs',
        path: '/dashboard/jobs',
        icon: <Iconify icon="mdi:cube-send"/>
    },
    {
        title: 'Users',
        path: '/dashboard/users/list',
        icon: <Iconify icon="ic:round-supervised-user-circle"/>
    },
    {
        title: 'Companies',
        path: '/dashboard/companies/list',
        icon: <Iconify icon="mdi:company"/>
    },
    {
        title: 'Info',
        path: PATH_DASHBOARD.info.root,
        icon: <Iconify icon="mage:message-info-round-fill"/>
    },
    {
        title: 'Support',
        path: PATH_DASHBOARD.support.root,
        icon: <Iconify icon="material-symbols:contact-support"/>
    }
];

export const navConfigAdmin = [
    {
        title: 'Jobs',
        path: '/dashboard/jobs',
        icon: <Iconify icon="mdi:cube-send"/>
    },
    {
        title: 'Users',
        path: '/dashboard/users/list',
        icon: <Iconify icon="ic:round-supervised-user-circle"/>
    },
    {
        title: 'Info',
        path: PATH_DASHBOARD.info.root,
        icon: <Iconify icon="mage:message-info-round-fill"/>
    },
    {
        title: 'Support',
        path: PATH_DASHBOARD.support.root,
        icon: <Iconify icon="material-symbols:contact-support"/>
    }
]

export const navConfigUser = [
    {
        title: 'Jobs',
        path: '/dashboard/jobs',
        icon: <Iconify icon="mdi:cube-send"/>
    },
    {
        title: 'Info',
        path: PATH_DASHBOARD.info.root,
        icon: <Iconify icon="mage:message-info-round-fill"/>
    },
    {
        title: 'Support',
        path: PATH_DASHBOARD.support.root,
        icon: <Iconify icon="material-symbols:contact-support"/>
    }
]