import SettingRepository from "../../repositories/SettingRepository";

export class RequestGetSettingById {
    constructor(key) {
        this.key = key
    }
}

export default class GetSettingByKeyUseCase {
    constructor(repository = new SettingRepository()) {
        this.repository = repository
    }

    execute(request) {
        const {key} = request
        return this.repository.getSettingByKey(key)
    }
}