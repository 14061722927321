import {defer} from "rxjs";
import {Auth} from "aws-amplify";

export class RequestSendCode {
    constructor(email) {
        this.email = email
    }
}

export default class SendCodeUseCase {
    // eslint-disable-next-line class-methods-use-this
    execute(request) {
        const {email} = request
        return defer(() => Auth.resendSignUp(email))
    }
}