import {UserRepository} from "../../repositories/UserRepository";

export class RequestUpdateUser {
    constructor(userId, userUpdated) {
        this.userId = userId
        this.userUpdated = userUpdated
    }
}

export default class UpdateUserUseCase {
    constructor(repository = new UserRepository()) {
        this.repository = repository
    }

    execute(request) {
        const {userId, userUpdated} = request
        return this.repository.updateUser(userId, userUpdated)
    }
}