import {UserRepository} from "../../repositories/UserRepository";

export class RequestGetUserByEmail {
    constructor(email) {
        this.email = email
    }
}

export default class GetUserByEmailUseCase {
    constructor(repository = new UserRepository()) {
        this.repository = repository
    }

    execute(request) {
        const {email} = request
        return this.repository.getUserByEmail(email)
    }
}