import PropTypes from 'prop-types'
import {alpha} from '@mui/material/styles'
import {Box, Paper, Typography} from '@mui/material'

RejectionFiles.propTypes = {
    fileRejections: PropTypes.array,
}

export default function RejectionFiles({fileRejections}) {
    if (!fileRejections.length) {
        return null
    }

    function getFileRejectionsGroup() {
        const errorGroup = new Map()

        fileRejections.forEach(rejection => {
            rejection.errors.forEach(error => {
                if (errorGroup.has(error.code)) {
                    errorGroup.get(error.code).message = error.message
                    errorGroup.get(error.code).files.push(rejection.file)
                } else {
                    errorGroup.set(error.code, {message: "", files: []})
                    errorGroup.get(error.code).message = error.message
                    errorGroup.get(error.code).files.push(rejection.file)
                }
            })
        })

        return [...errorGroup]
    }

    return (
        <Paper
            variant="outlined"
            sx={{
                py: 1, px: 2, mt: 3,
                bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
                borderColor: (theme) => alpha(theme.palette.error.main, 0.24),
            }}
        >
            {
                getFileRejectionsGroup().map((error, index) =>
                    (
                        <Box key={index} sx={{my: 1}}>
                            <Typography variant="subtitle2" noWrap>
                                {error[1].message}
                            </Typography>

                            <Box component="span" sx={{typography: 'caption'}}>
                                Files affected: {error[1].files.map(file => file.name).join(', ')}
                            </Box>
                        </Box>
                    )
                )}
        </Paper>
    )
}