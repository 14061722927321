import CompanyRepository from "../../repositories/CompanyRepository";

export class RequestUpdateCompany {
    constructor(companyId, companyUpdated) {
        this.companyId = companyId
        this.companyUpdated = companyUpdated
    }
}

export default class UpdateCompanyUseCase {
    constructor(repository = new CompanyRepository()) {
        this.repository = repository
    }

    /** @return {Observable<CompanyUC>} */
    execute(request) {
        const {companyId, companyUpdated} = request
        return this.repository.updateCompany(companyId, companyUpdated)
    }
}