import PropTypes from 'prop-types'
import {Navigate} from 'react-router-dom'
import {useAuthContext} from "./AuthProvider"
import LoadingScreen from "../components/loading-screen/LoadingScreen";

AuthGuard.propTypes = {
    children: PropTypes.node,
}

export default function AuthGuard({children}) {
    const {isAuthenticated, isInitialized} = useAuthContext()

    if (!isInitialized) {
        return <LoadingScreen/>
    }

    if (!isAuthenticated) {
        return <Navigate to="/auth"/>
    }

    return <> {children} </>
}