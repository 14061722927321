import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/iconify';
import AccountPopover from './AccountPopover';
import TrialAccountInfoBox from './TrialAccountInfoBox'
import HeaderNavSection from "../../../components/nav-section/HeaderNavSection";
import Logo from "../../../components/logo";

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
    ...bgBlur({ color: theme.palette.background.default }),
    boxShadow: 'none',
    [theme.breakpoints.up('xl')]: {
        width: `calc(100% - ${NAV_WIDTH + 1}px)`,
    },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    minHeight: HEADER_MOBILE,
    [theme.breakpoints.up('lg')]: {
        minHeight: HEADER_DESKTOP,
        padding: theme.spacing(0, 5),
    },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
    navConfig: PropTypes.array,
    onOpenNav: PropTypes.func,
};

export default function Header({ navConfig, onOpenNav }) {

    return (
        <StyledRoot>
            <StyledToolbar>
                <IconButton
                    onClick={onOpenNav}
                    sx={{
                        mr: 1,
                        color: 'text.primary',
                        display: { md: 'none', lg: 'none' },
                    }}
                >
                    <Iconify icon="eva:menu-2-fill" />
                </IconButton>

                <Logo />

                <Box sx={{ flexGrow: 1 }} />
                { navConfig &&
                    <HeaderNavSection
                        data={navConfig}
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'none', md: 'flex' }}}
                    />
                }

                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={{
                        xs: 0.5,
                        sm: 1,
                    }}
                >
                    { /* <LanguagePopover /> */ }
                    {/* <NotificationsPopover /> */}
                    <TrialAccountInfoBox />

                    <AccountPopover />
                </Stack>
            </StyledToolbar>
        </StyledRoot>
    );
}
