import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Stack, TextField} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {AlertError} from "../../../components/utils";
import {isValidEmail} from "../../../utils/validatorUtils";
import UseCase from "../../../usecases/UseCase";
import {RequestResetPassword, ResetPasswordUseCase} from "../../../usecases/login/ResetPasswordUseCase";

export default function ResetPasswordForm() {

    const resetPassword = new ResetPasswordUseCase()

    const [sendCodeInProgress, setSendCodeInProgress] = useState(false)
    const [formInfo, setFormInfo] = useState({})
    const [errorMessage, setErrorMessage] = useState('')

    const navigate = useNavigate()

    const btnSendRequest = () => {
        if (!validateForm()) return

        setSendCodeInProgress(true)

        const request = new RequestResetPassword(formInfo.email)
        UseCase.execute(resetPassword, request, onSendCodeSuccess, onFailure)
    }

    const onSendCodeSuccess = () => {
        const userInfo = {
            email: formInfo.email
        }

        navigate('/auth/new-password', {
            replace: true,
            state: {userInfo}
        })
    }

    const onFailure = (error) => {
        if (isUserNotVerified(error)) return

        showAlertError(error.message)
        setSendCodeInProgress(false)
    }

    function isUserNotVerified(error) {
        if (error.code !== 'UserNotConfirmedException') return false

        const userInfo = {
            email: formInfo.email,
            sendCode: true
        }

        navigate('/auth/verify', {
            replace: true,
            state: {userInfo}
        })

        return true
    }

    function onFormChangeEmail(userEmail) {
        setFormInfo({...formInfo, email: userEmail})
    }

    function showAlertError(message) {
        setErrorMessage(message)
    }

    function validateForm() {
        if (!isValidEmail(formInfo.email)) {
            showAlertError('Invalid email')
            return false
        }

        return true
    }

    return (
        <>
            <Stack spacing={3}>

                <AlertError message={errorMessage} setMessage={setErrorMessage}/>

                <TextField
                    name="email" label="Email address" type="email" required
                    onChange={e => onFormChangeEmail(e.target.value)}
                />
            </Stack>

            <LoadingButton sx={{mt: 5, mb: 2}}
                           fullWidth size="large" type="submit" variant="contained"
                           loading={sendCodeInProgress}
                           onClick={btnSendRequest}
            >
                Send Code
            </LoadingButton>
        </>
    );
}