export const rolesEnum = {
    USER: {label: "User", code: "0"},
    ADMIN: {label: "Administrator", code: "100"},
    SUPER_ADMIN: {label: "Super Administrator", code: "200"},
}

export function getArrayRoles() {
    const roles = []
    // eslint-disable-next-line no-restricted-syntax,guard-for-in
    for (const key in rolesEnum) {
        roles.push(rolesEnum[key])
    }

    return roles
}

export function getArrayRolesForCreation(userProfile) {
    let roles = []
    if (userProfile.role === rolesEnum.SUPER_ADMIN.code) {
        roles = getArrayRoles()
    }

    if (userProfile.role === rolesEnum.ADMIN.code) {
        roles = getArrayRoles().filter(role => role.code !== rolesEnum.SUPER_ADMIN.code)
    }

    return roles
}

export function getRoleName(roleCode) {
    const roleName = getArrayRoles().find(role => role.code === roleCode)
    if (!roleName) return 'Unknown'
    return roleName.label || 'Unknown'
}