import {useState} from 'react'
import {alpha} from '@mui/material/styles'
import {Avatar, Box, Divider, IconButton, MenuItem, Popover, Stack, Typography} from '@mui/material'
import Iconify from "../../../components/iconify";
import {useAuthContext} from "../../../auth/AuthProvider";

const MENU_OPTIONS = [
    {
        label: 'Documentation',
        icon: 'eva:info-fill',
        action: () => { window.open('https://datasightusa.com/dtmHelp/', '_blank') },
    },
    {
        label: 'Terms & Conditions',
        icon: 'eva:file-text-fill',
        action: () => { window.open('https://datasightusa.com/terms-of-service/', '_blank') },
    },
    {
        label: 'Privacy Policy',
        icon: 'eva:shield-fill',
        action: () => { window.open('https://datasightusa.com/privacy-policy/', '_blank') },
    },
]

export default function AccountPopover() {

    const [open, setOpen] = useState(null)
    const {user, logout} = useAuthContext()

    const handleOpen = (event) => {
        setOpen(event.currentTarget)
    }

    const handleClose = () => {
        setOpen(null)
    }

    const handleClick = (action) => {
        action()
        handleClose()
    }

    function btnLogOut() {
        logout().subscribe({
            next: response => console.log(response),
            error: err => console.log(err)
        })
    }

    function getUserNameLetters() {
        try {
            return user.firstName[0] + user.lastName[0]
        } catch (e) {
            console.warn(e)
        }

        return "NN"
    }

    function getUserName() {
        try {
            return `${user.firstName} ${user.lastName}`
        } catch (e) {
            console.log(e)
        }

        return "Unknown user"
    }

    function getUserEmail() {
        try {
            return user.email
        } catch (e) {
            console.log(e)
        }
        return "email@undefined.com"
    }

    return (
        <>
            <IconButton
                onClick={handleOpen}
                sx={{
                    p: 0,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                        },
                    }),
                }}
            >
                <Avatar sx={{bgcolor: "#E0941C"}}>{getUserNameLetters()}</Avatar>
            </IconButton>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                PaperProps={{
                    sx: {
                        p: 0,
                        mt: 1.5,
                        ml: 0.75,
                        width: 220,
                        '& .MuiMenuItem-root': {
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <Box sx={{my: 1.5, px: 2.5}}>
                    <Typography variant="subtitle2" noWrap>
                        {getUserName()}
                    </Typography>
                    <Typography variant="body2" sx={{color: 'text.secondary'}} noWrap>
                        {getUserEmail()}
                    </Typography>
                </Box>

                <Divider sx={{borderStyle: 'dashed'}}/>

                <Stack sx={{p: 1}}>
                    {MENU_OPTIONS.map((option) => (
                        <MenuItem key={option.label} onClick={() => {
                            handleClick(option.action)
                        }}>
                            <Iconify icon={option.icon} sx={{mr: 1}}/>
                            {option.label}
                        </MenuItem>
                    ))}
                </Stack>

                <Divider sx={{borderStyle: 'dashed'}}/>

                <MenuItem
                    sx={{m: 1}}
                    onClick={() => btnLogOut()}
                >
                    <Iconify icon={'eva:close-square-fill'} sx={{mr: 1}}/>
                    Sign Out
                </MenuItem>
            </Popover>
        </>
    )
}
