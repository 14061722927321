import {defer, from, Subject} from "rxjs";
import {Settings} from "../../models";

export function getSettingByKey(ds, key) {
    return from(ds.query(Settings, setting => setting.key.eq(key)))
}

export function updateSetting(ds, settingId, settingToUpdate) {
    const obs = new Subject()

    defer(() => ds.query(Settings, setting => setting.key.eq(settingId)))
        .subscribe( originalSetting => {

            console.log('preparing, setting to update...', originalSetting)
            console.log('update with...', settingToUpdate)
            const model = originalSetting[0]
            console.log("setting >> ", model)
            const modelUpdated = Settings.copyOf(model, updated => {
                updated.value = settingToUpdate.value
            })

            ds.save(modelUpdated)
                .then(settingUpdated => {
                    console.log('[SUCCESS] updateSetting', settingUpdated)
                    obs.next(settingUpdated)
                    obs.complete()
                })
                .catch(e => {
                    console.log('[FAIL] updateSetting', e)
                    obs.error(e)
                    obs.complete()
                })
        })

    return obs
}