export function isValidEmail(email) {
    const regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
    return regexEmail.test(email)
}

export function isValidPassword(password) {
    const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.[\]{}()?"!@#%&/\\,><':;|_~`])\S{8,99}$/;
    return regexPassword.test(password)
}

export function isAlphaNumeric(value) {
    const regexEmail = /^[a-zA-Z0-9-]+$/;
    return regexEmail.test(value)
}

export function isSamePassword(password, password2) {
    return password === password2
}

export function isNonEmptyFields(fields) {
    let validFields = true 
    
    for(let i=0; i<fields.length; i+=1) {
        const value = fields[i]
        if (value.trim().length === 0) {
            validFields = false
            break;
        }
    }

    return validFields
}