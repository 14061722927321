import React, {useCallback, useEffect, useState} from "react"
import PropTypes from "prop-types"
import {FormControl, Stack} from "@mui/material"
import {ToggleButton, ToggleButtonGroup} from "@mui/lab";
import Upload from "../../../../components/upload/Upload"
import {JobStep} from "./StepManager"
import {EMPTY_STRING} from "../../../../utils/constants"
import {StepStatus} from "./StepStatus"
import {isFilesCompleted} from "../../../../utils/JobUtils";

export const BreaklinesFileTypes = {
    DXF: {
        label: 'DXF',
        type: 'breaklines_dxf',
        multipleFiles: false,
        required: ['.dxf'],
        minRequired: 1,
        optional: [],
    },
    SHAPEFILES: {
        label: 'Shapefiles',
        type: 'breaklines_shapefiles',
        multipleFiles: true,
        required: ['.shp', '.shx', '.dbf'],
        minRequired: 3,
        optional: ['.cpg', '.prj', '.cppg', '.idx', '.mshp',
            '.sbn', '.sbx', '.fbn', '.fbx', '.ain',
            '.aih', '.atx', '.ixs', '.mxs', '.xml']
    }
}

export function getBreaklinesFileType(type) {
    switch (type) {
        case BreaklinesFileTypes.DXF.type:
            return BreaklinesFileTypes.DXF
        case BreaklinesFileTypes.SHAPEFILES.type:
            return BreaklinesFileTypes.SHAPEFILES
        default:
            return {}
    }
}

StepCriticalBreaklines.propTypes = {
    onStepInfo: PropTypes.func,
    jobInfo: PropTypes.object
}

export default function StepCriticalBreaklines({onStepInfo, jobInfo}) {
    const defaultBreaklinesType = BreaklinesFileTypes.DXF
    const stepId = JobStep.STEP_BREAKLINES.id

    const [files, setFiles] = useState([])
    const [formInfo, setFormInfo] = useState({})
    const [breaklinesType, setBreaklinesType] = useState(defaultBreaklinesType.type)
    const [multiUpload, setMultiUpload] = useState(defaultBreaklinesType.multipleFiles)

    useEffect(() => {
        function restoreFormState() {
            if (isNewStep()) {
                setFormInfo({...formInfo, breaklinesFiles: [], breaklinesType: defaultBreaklinesType.type})
                return
            }

            setBreaklinesType(jobInfo.breaklinesType)
            setFiles(jobInfo.breaklinesFiles)
            setFormInfo(jobInfo)
        }

        restoreFormState()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        updateStepStatus()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formInfo])

    function getHelperMessage(type) {
        const {required, optional} = getBreaklinesFileType(type)
        const optionalMessage = (optional.length !== 0) ? `and optionally ${optional.join(' ')}` : EMPTY_STRING
        return `Select ${required.join(' ')} ${optionalMessage}`
    }

    function getAcceptedFileTypes(type) {
        const {required, optional} = getBreaklinesFileType(type)
        return [...required, ...optional]
    }

    function updateStepStatus() {
        const stepStatus = getStepStatus()
        onStepInfo(stepId, stepStatus, formInfo)
    }

    const handleChangeBreaklinesType = (type) => {
        const {multipleFiles} = getBreaklinesFileType(type)

        setFiles([])
        setMultiUpload(multipleFiles)
        setBreaklinesType(type)

        setFormInfo({...formInfo, breaklinesType: type, breaklinesFiles: []})
    }

    function isNewStep() {
        return jobInfo.breaklinesFiles === undefined
    }

    const handleDropMultiFile = useCallback((acceptedFiles) => {
            const filesUpdated = [...files, ...acceptedFiles]
            setFiles(filesUpdated)

            setFormInfo({...formInfo, breaklinesFiles: filesUpdated})
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [files]
    )

    const handleRemoveFile = (inputFile) => {
        const filesUpdated = files.filter((fileFiltered) => fileFiltered !== inputFile)
        setFiles(filesUpdated)

        setFormInfo({...formInfo, breaklinesFiles: filesUpdated})
    }

    const handleRemoveAllFiles = () => {
        setFiles([])

        setFormInfo({...formInfo, breaklinesFiles: []})
    }

    function getStepStatus() {
        const fileTypeInfo = getBreaklinesFileType(breaklinesType)

        const stepCompleted = isFilesCompleted(files, fileTypeInfo)
        return stepCompleted ? StepStatus.COMPLETED : StepStatus.UNCOMPLETED
    }

    return <>
        <Stack pt={2}>

            <FormControl fullWidth>
                <ToggleButtonGroup
                    value={breaklinesType}
                    exclusive
                    fullWidth
                    onChange={e => handleChangeBreaklinesType(e.target.value)}
                    aria-label="breaklines type"
                >
                    <ToggleButton value={BreaklinesFileTypes.DXF.type} aria-label="DXF" color={'primary'}>
                        {BreaklinesFileTypes.DXF.label}
                    </ToggleButton>
                    <ToggleButton value={BreaklinesFileTypes.SHAPEFILES.type} aria-label="Shapefile" color={'primary'}>
                        {BreaklinesFileTypes.SHAPEFILES.label}
                    </ToggleButton>
                </ToggleButtonGroup>
            </FormControl>

            <Upload sx={{mt: 2}}
                    helperText={getHelperMessage(breaklinesType)}
                    files={files}
                    multiple={multiUpload}
                    accept={{
                        'text/plain': getAcceptedFileTypes(breaklinesType)
                    }}
                    onDrop={handleDropMultiFile}
                    onRemove={handleRemoveFile}
                    onRemoveAll={handleRemoveAllFiles}
            />
        </Stack>
    </>
}