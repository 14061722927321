import PropTypes from "prop-types";
import {Box, Card, Grid, IconButton, Stack, Tooltip, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {m} from "framer-motion";
import {from} from "rxjs";
import {Storage} from "aws-amplify";
import {getBasePathForJobFile, JobResolutions} from "../../../../utils/JobUtils";
import Iconify from "../../../../components/iconify";
import GetCompanyUseCase, {RequestGetCompany} from "../../../../usecases/company/GetCompanyUseCase";
import GetUserByIdUseCase, {RequestGetUserById} from "../../../../usecases/user/GetUserByIdUseCase";
import {EMPTY_STRING} from "../../../../utils/constants";

JobResumeCard.propTypes = {
    data: PropTypes.object
}

export default function JobResumeCard({data}) {

    const getCompany = new GetCompanyUseCase()
    const getUserById = new GetUserByIdUseCase()

    const [company, setCompany] = useState()
    const [user, setUser] = useState()

    const {
        id, aoi, billingNumber, companyId, customBreaklines,
        las, resolution, type, userProfileId
    } = data

    useEffect(() => {
        const request = new RequestGetCompany(companyId)
        getCompany.execute(request).subscribe({
            next: companyFetched => setCompany(companyFetched),
            error: err => console.log(err)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const request = new RequestGetUserById(userProfileId)
        getUserById.execute(request).subscribe({
            next: userFetched => setUser(userFetched),
            error: err => console.log(err)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function getJobResolution() {
        if (resolution === "0" || resolution === JobResolutions.NORMAL.id) {
            return 'Normal'
        }

        return 'High'
    }

    function getCompanyName() {
        return !company ? EMPTY_STRING : company.name
    }

    function getUserName() {
        return !user ? EMPTY_STRING : `${user.firstName} ${user.lastName}`
    }

    function getUserEmail() {
        return !user ? EMPTY_STRING : user.email
    }

    function getFormattedFileName(fileName) {
        if (fileName.length <= 15) return fileName

        const start = fileName.substring(0, 7)
        const end = fileName.substring(fileName.length - 7)

        return `${start} ... ${end}`
    }

    ListFiles.propTypes = {
        title: PropTypes.string,
        files: PropTypes.array,
    }

    function ListFiles({title, files}) {
        return <>
            <Typography variant="overline" gutterBottom>
                {title}
            </Typography>
            <Stack spacing={1} sx={{pt: 1}}>
                {
                    files.map((fileName, index) => (
                        <Box key={index}>
                            <Stack
                                component={m.div}
                                spacing={2}
                                direction="row"
                                alignItems="center"
                                sx={{
                                    px: 1,
                                    py: 0.1,
                                    borderRadius: 0.75,
                                    border: (theme) => `solid 1px ${theme.palette.divider}`
                                }}
                            >
                                <Stack flexGrow={1} sx={{minWidth: 0}}>
                                    <Tooltip title={fileName} placement="left" arrow>
                                        <Typography variant="body2" noWrap ellipsizeMode={'middle'}>
                                            {getFormattedFileName(fileName)}
                                        </Typography>
                                    </Tooltip>
                                </Stack>

                                <IconButton onClick={() => {
                                    const filePath = getBasePathForJobFile(user.id, id) + fileName

                                    from(Storage.get(filePath)).subscribe({
                                        next: result => {
                                            const link = document.createElement("a")
                                            link.download = fileName
                                            link.href = result
                                            link.click()
                                        },
                                        error: err => console.log(err)
                                    })

                                }}>
                                    <Iconify icon="ep:download" width={20}/>
                                </IconButton>

                            </Stack>
                        </Box>
                    ))
                }
            </Stack>
        </>
    }

    return (
        <>
            <Card sx={{p: 4, my: 2}}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>

                        <Stack spacing={1}>
                            <Stack>
                                <Typography variant="overline">Resolution</Typography>
                                <Typography variant="body2"> {getJobResolution()} </Typography>
                            </Stack>
                            <Stack>
                                <Typography variant="overline">Type</Typography>
                                <Typography variant="body2"> {type} </Typography>
                            </Stack>
                            <Stack>
                                <Typography variant="overline">Author</Typography>
                                <Typography variant="body2"> {getUserName()} </Typography>
                                <Typography variant="body2"> {getUserEmail()} </Typography>
                            </Stack>
                            <Stack>
                                <Typography variant="overline">Company</Typography>
                                <Typography variant="body2"> {getCompanyName()} </Typography>
                                <Typography variant="body2"> Bill number: {billingNumber} </Typography>
                            </Stack>
                        </Stack>

                    </Grid>

                    {aoi.length > 0 &&
                        <Grid item xs={3}>
                            <ListFiles title="Boundary" files={aoi}/>
                        </Grid>
                    }

                    {las.length > 0 &&
                        <Grid item xs={3}>
                            <ListFiles title="Data Source" files={las}/>
                        </Grid>
                    }

                    {customBreaklines.length > 0 &&
                        <Grid item xs={3}>
                            <ListFiles title="Breaklines" files={customBreaklines}/>
                        </Grid>
                    }
                </Grid>
            </Card>

        </>
    )
}