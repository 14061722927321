import PropTypes from 'prop-types';
import {Button, InputAdornment, MenuItem, Stack, TextField} from '@mui/material';
import Iconify from '../../../../components/iconify';
import {useAuthContext} from "../../../../auth/AuthProvider";
import {rolesEnum} from "../../../../assets/data/roles";

UserTableToolbar.propTypes = {
    isFiltered: PropTypes.bool,
    filterInput: PropTypes.string,
    filterCompany: PropTypes.string,
    onFilterInput: PropTypes.func,
    onFilterCompany: PropTypes.func,
    onResetFilter: PropTypes.func,
    onNewUserClick: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.string),
}

export default function UserTableToolbar(
    {
        isFiltered,
        filterInput,
        filterCompany,
        options,
        onFilterInput,
        onFilterCompany,
        onResetFilter,
        onNewUserClick,
    }
) {

    const {user} = useAuthContext()

    return (
        <Stack
            spacing={2}
            alignItems="center"
            direction={{
                xs: 'column',
                sm: 'row',
            }}
            sx={{px: 2.5, py: 3}}
        >
            {
                user.role === rolesEnum.SUPER_ADMIN.code && <TextField
                    fullWidth
                    select
                    label="Company"
                    value={filterCompany}
                    onChange={onFilterCompany}
                    SelectProps={{
                        MenuProps: {
                            PaperProps: {
                                sx: {
                                    maxHeight: 260,
                                },
                            },
                        },
                    }}
                    sx={{
                        maxWidth: {sm: 240},
                        textTransform: 'capitalize',
                    }}
                >
                    {options.map((option) => (
                        <MenuItem
                            key={option.id}
                            value={option.id}
                            sx={{
                                mx: 1,
                                borderRadius: 0.75,
                                typography: 'body2',
                                textTransform: 'capitalize',
                            }}
                        >
                            {option.name}
                        </MenuItem>
                    ))}
                </TextField>
            }

            <TextField
                fullWidth
                value={filterInput}
                onChange={onFilterInput}
                placeholder="Search by name or email"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon="eva:search-fill" sx={{color: 'text.disabled'}}/>
                        </InputAdornment>
                    ),
                }}
            />

            {isFiltered && (
                <Button
                    color="info"
                    sx={{flexShrink: 0}}
                    onClick={onResetFilter}
                    startIcon={<Iconify icon="mdi:clear-circle-multiple-outline"/>}
                >
                    Clear
                </Button>
            )}

            <Button
                onClick={onNewUserClick}
                sx={{flexShrink: 0}}
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill"/>}
            >
                New&nbsp;User
            </Button>

        </Stack>
    )
}
