import {Jobs} from "../../models";
import {JobStatus} from "../../utils/JobUtils";

export default class Job {
    constructor(
        id, userProfileId, companyId, name, status, type, acreage,
        billingNumber, prefix, las, aoi, s3Bucket, resolution,
        customBreaklines, errorMessage, downloadUrl, ecsArn,
        jobCreatedAt, jobUpdatedAt, jobStartedAt, jobFinishedAt,
        billingRate
    ) {
        this.id = id
        this.userProfileId = userProfileId
        this.companyId = companyId
        this.name = name
        this.status = status
        this.type = type
        this.acreage = acreage
        this.billingNumber = billingNumber
        this.prefix = prefix
        this.las = las
        this.aoi = aoi
        this.s3Bucket = s3Bucket
        this.resolution = resolution
        this.customBreaklines = customBreaklines
        this.errorMessage = errorMessage
        this.downloadUrl = downloadUrl
        this.ecsArn = ecsArn
        this.jobCreatedAt = jobCreatedAt
        this.jobUpdatedAt = jobUpdatedAt
        this.jobStartedAt = jobStartedAt
        this.jobFinishedAt = jobFinishedAt
        this.createdAt = ""
        this.billingRate = billingRate
    }

    isPending() {
        return this.status === JobStatus.PENDING
    }

    hasLogs() {
        return !this.isPending()
    }

    toJobAmplifyModel() {
        // note id is read-only and is autogenerated every new Job instance
        const amplifyModel = new Jobs({
            userProfileId: this.userProfileId,
            companyId: this.companyId,
            name: this.name,
            status: this.status,
            type: this.type,
            acreage: this.acreage,
            billingNumber: this.billingNumber,
            prefix: this.prefix,
            las: this.las,
            aoi: this.aoi,
            s3Bucket: this.s3Bucket,
            resolution: this.resolution,
            customBreaklines: this.customBreaklines,
            errorMessage: this.errorMessage,
            downloadUrl: this.downloadUrl,
            ecs_arn: this.ecsArn,
            jobCreatedAt: this.jobCreatedAt,
            jobUpdatedAt: this.jobUpdatedAt,
            jobStartedAt: this.jobStartedAt,
            jobFinishedAt: this.jobFinishedAt,
            billingRate: this.billingRate
        })

        return amplifyModel
    }

    static fromJobAmplifyModel(jobAmplifyModel) {
        const job = new Job()
        job.id = jobAmplifyModel.id
        job.userProfileId = jobAmplifyModel.userProfileId
        job.companyId = jobAmplifyModel.companyId
        job.name = jobAmplifyModel.name
        job.status = jobAmplifyModel.status
        job.type = jobAmplifyModel.type
        job.acreage = jobAmplifyModel.acreage
        job.billingNumber = jobAmplifyModel.billingNumber
        job.prefix = jobAmplifyModel.prefix
        job.las = jobAmplifyModel.las
        job.aoi = jobAmplifyModel.aoi
        job.s3Bucket = jobAmplifyModel.s3Bucket
        job.resolution = jobAmplifyModel.resolution
        job.customBreaklines = jobAmplifyModel.customBreaklines
        job.errorMessage = jobAmplifyModel.errorMessage
        job.downloadUrl = jobAmplifyModel.downloadUrl
        job.ecsArn = jobAmplifyModel.ecs_arn
        job.jobCreatedAt = jobAmplifyModel.jobCreatedAt
        job.jobUpdatedAt = jobAmplifyModel.jobUpdatedAt
        job.jobStartedAt = jobAmplifyModel.jobStartedAt
        job.jobFinishedAt = jobAmplifyModel.jobFinishedAt
        job.createdAt = jobAmplifyModel.createdAt
        job.billingRate = jobAmplifyModel.billingRate
        return job
    }
}