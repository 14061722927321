import {Alert, Box, CircularProgress, Stack} from "@mui/material"
import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import {forkJoin} from "rxjs"
import Iconify from "../../../../components/iconify"
import UseCase from "../../../../usecases/UseCase"
import Job from "../../../../usecases/models/Job"
import CreateJobUseCase, {RequestCreateJob} from "../../../../usecases/job/CreateJobUseCase"
import FileStorage from "../../../../services/FileStorage"
import {EMPTY_STRING, UNKNOWN} from "../../../../utils/constants"
import {StepStatus} from "./StepStatus"
import {getTodayFormattedMMDDYYY} from "../../../../utils/DateUtils"
import {getBasePathForJobFile, getFilesFromJobInfo, JobFileType, JobStatus} from "../../../../utils/JobUtils"
import {JobStep} from "./StepManager"
import * as BreaklinesApi from "../../../../services/BreaklinesApi";
import UpdateJobUseCase, {RequestUpdateJob} from "../../../../usecases/job/UpdateJobUseCase";
import {useAuthContext} from "../../../../auth/AuthProvider";
import AccountUpgradeDialog from "../../../../components/account-upgrade-dialog";

StepVerifyBoundary.propTypes = {
    onStepInfo: PropTypes.func,
    jobInfo: PropTypes.object,
    onReject: PropTypes.func
}

export default function StepVerifyBoundary({onStepInfo, jobInfo, onReject}) {
    const stepId = JobStep.STEP_VERIFY_BOUNDARY.id

    const fileStorage = new FileStorage()
    const createJob = new CreateJobUseCase()
    const updateJob = new UpdateJobUseCase()

    const {user, company} = useAuthContext()

    const [formInfo, setFormInfo] = useState({})
    const [alertInfo, setAlertInfo] = useState({})
    const [requestedBoundaryAcres, setRequestedBoundaryAcres] = useState()
    const [verificationCompleted, setVerificationCompleted] = useState(false)
    const [verificationFailed, setVerificationFailed] = useState(false)

    const existJob = () => jobInfo.id !== undefined
    const jobFromForm = () => getJobFromForm(jobInfo)

    useEffect(() => {
        function disableNextUntilCompleteFileVerification() {
            const stepCompleted = false
            updateStepStatus(stepCompleted)
        }

        function createJobFromForm() {
            const request = new RequestCreateJob(jobFromForm())
            UseCase.execute(createJob, request, onSuccessJobCreated, onFailure)
        }

        function updateJobFromForm(jobId) {
            if (jobInfo.boundaryVerificationInfo) {
                onSuccessFileVerification(jobInfo.boundaryVerificationInfo)
                return
            }

            const poolObs = []

            jobInfo.boundaryFilesUploaded.forEach(filePath => {
                poolObs.push(fileStorage.removeFile(filePath))
            })

            forkJoin(poolObs).subscribe({
                next: filesRemoved => {
                    console.log('files removed', filesRemoved)
                    const request = new RequestUpdateJob(jobId, {...jobFromForm(), acreage: 0})
                    UseCase.execute(updateJob, request, onSuccessJobUpdated, onFailure)
                }
            })
        }

        disableNextUntilCompleteFileVerification()

        if (existJob()) {
            updateJobFromForm(jobInfo.id)
            return
        }

        createJobFromForm()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        updateStepStatus()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formInfo])

    const onSuccessFileVerification = ({validFiles, boundaryFilesInfo, acres}) => {
        const filesVerificationInfo = {validFiles, boundaryFilesInfo, acres}
        setVerificationCompleted(true)
        setFormInfo({...formInfo, boundaryAcres: acres, boundaryVerificationInfo: filesVerificationInfo})
        setRequestedBoundaryAcres(acres)

        updateAlertMessage(validFiles, boundaryFilesInfo)
    }

    const onFailure = ({validFiles, boundaryFilesInfo}, acres) => {
        setVerificationFailed(true)
        setFormInfo({...formInfo, boundaryAcres: acres})

        updateAlertMessage(validFiles, boundaryFilesInfo)
    }

    const onSuccessJobCreated = (job) => {
        setFormInfo({...formInfo, id: job.id})

        uploadBoundaryFiles(job)
    }

    const onSuccessJobUpdated = (job) => {
        setFormInfo({...formInfo, id: job.id})

        uploadBoundaryFiles(job)
    }

    function uploadBoundaryFiles(job) {
        const poolObs = []

        const basePath = getBasePathForJobFile(user.id, job.id)

        jobInfo.boundaryFiles.forEach(file => {
            console.log('uploading', file.name)
            poolObs.push(
                fileStorage.saveFile(
                    basePath + file.name, file,
                    JobFileType.BOUNDARY,
                    file.name,
                    onUploadProgressUpdate
                ))
        })

        forkJoin(poolObs).subscribe({
            next: filesUploaded => {
                console.log('files uploaded', filesUploaded)
                setFormInfo({...formInfo, boundaryFilesUploaded: filesUploaded.map(file => file.key)})

                BreaklinesApi.validateBoundary(job)
                    .then((result) => {
                        const verifiedMessage = result.valid
                            ? `Boundary is valid and has ${result.acreage.toFixed(1)} acres.`
                            : `Boundary is invalid. Please confirm that your boundary is a closed polygon.`
                        onSuccessFileVerification({
                            validFiles: result.valid,
                            boundaryFilesInfo: verifiedMessage,
                            acres: result.acreage
                        })
                    }).catch((error) => {
                        console.error('StepVerifyBoundary: Failed to validate boundary file(s)', error)
                        onFailure({
                            validFiles: false,
                            boundaryFilesInfo: 'Unable to verify your boundaries. Proceed to the next step.'
                        }, null)
                    })
            }
        })
    }

    const onUploadProgressUpdate = (fileType, fileName, loaded, total) => {
        console.log(fileType, fileName, `${loaded}/${total}`)
    }

    function updateAlertMessage(validFiles, alertMessage) {
        const alertSeverity = validFiles ? 'success' : 'error'
        setAlertInfo({severity: alertSeverity, message: alertMessage})
    }

    function updateStepStatus() {
        const stepStatus = getStepStatus()
        onStepInfo(stepId, stepStatus, formInfo)
    }

    function getStepStatus() {
        // TODO: Also verify that acres are not above quota
        const stepCompleted = (verificationCompleted && formInfo.boundaryAcres > 0) || verificationFailed
        return stepCompleted ? StepStatus.COMPLETED : StepStatus.UNCOMPLETED
    }

    function getAlertIcon(severity) {
        switch (severity) {
            case 'error':
                return 'mdi:warning-octagram-outline'
            case 'success':
                return 'material-symbols:done'
            default:
                return 'material-symbols:info-outline'
        }
    }

    function getJobFromForm(jobInfo) {
        const todayFormatted = getTodayFormattedMMDDYYY()

        const job = new Job()
        job.userProfileId = user.id
        job.companyId = user.companyId
        job.name = jobInfo.name
        job.status = JobStatus.PENDING
        job.type = jobInfo.boundaryType
        job.acreage = jobInfo.boundaryAcres
        job.billingNumber = jobInfo.billingNumber
        job.prefix = 'to_define'
        job.las = getFilesFromJobInfo(jobInfo, 'datasourceFiles')
        job.aoi = getFilesFromJobInfo(jobInfo, 'boundaryFiles')
        job.s3Bucket = UNKNOWN
        job.resolution = jobInfo.resolution
        job.customBreaklines = getFilesFromJobInfo(jobInfo, 'breaklinesFiles')
        job.errorMessage = EMPTY_STRING
        job.downloadUrl = UNKNOWN
        job.jobCreatedAt = todayFormatted
        job.jobUpdatedAt = todayFormatted
        job.jobStartedAt = todayFormatted
        job.jobFinishedAt = EMPTY_STRING
        job.billingRate = company.getProcessedBillingRate()

        return job
    }

    return <>
        <Stack spacing={3} pt={2} sx={{display: 'flex'}}>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                {(!verificationCompleted && !verificationFailed) && <CircularProgress width={48} sx={{
                    color: "#DBA901"
                }}/>}
            </Box>

            {
                (verificationCompleted || verificationFailed) &&
                <Alert icon={<Iconify icon={getAlertIcon(alertInfo.severity)}/>}
                       severity={alertInfo.severity}
                >
                    {alertInfo.message}
                </Alert>
            }
        </Stack>

        <AccountUpgradeDialog
            enforceAcreQuota
            requestedAcres={requestedBoundaryAcres}
            onCancel={onReject}
            onClose={() => {}}
        />
    </>
}