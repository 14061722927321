import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import * as Yup from "yup";
import {Checkbox, IconButton, InputAdornment, Link, Stack, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import Iconify from '../../../components/iconify';
import {RequestSignUp, SignUpUseCase} from "../../../usecases/login/SignUpUseCase";
import UserProfile from "../../../usecases/models/UserProfile";
import {AlertError} from "../../../components/utils";
import {isValidPassword} from "../../../utils/validatorUtils";
import UseCase from "../../../usecases/UseCase";
import {rolesEnum} from "../../../assets/data/roles";
import CreateCompanyUseCase, {RequestCreateCompany} from "../../../usecases/company/CreateCompanyUseCase";
import CompanyUC from "../../../usecases/models/Company";
import {DEFAULT_BILLING_RATE, EMPTY_STRING} from "../../../utils/constants";
import FormProvider, {RHFTextField} from "../../../components/hook-form";
import GetUserByEmailUseCase, {RequestGetUserByEmail} from "../../../usecases/user/GetUserByEmailUseCase";
import {getTodayFormattedMMDDYYY} from "../../../utils/DateUtils";

export default function SignUpForm() {

    const signUp = new SignUpUseCase()
    const getUserByEmail = new GetUserByEmailUseCase()
    const createCompany = new CreateCompanyUseCase()

    const [showPassword, setShowPassword] = useState(false)
    const [signUpInProgress, setSignUpInProgress] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const navigate = useNavigate();

    const [termsAccepted, setTermsAccepted] = useState(false)

    const handleChange = (newValue) => {
        setTermsAccepted(newValue)
    }

    const onSignUp = (formInfo) => {
        if (!validatePass(formInfo)) return
        if (!validateTermsAndConditions()) return

        setSignUpInProgress(true)

        // 1. verify email is available
        const requestGetUserByEmail = new RequestGetUserByEmail(formInfo.email)
        getUserByEmail.execute(requestGetUserByEmail).subscribe({
            next: users => signUpCompanyUser(formInfo, users),
            error: err => {
                console.error("Can't fetch user", err)
                showAlertError("Ops, something went wrong")
                setSignUpInProgress(false)
            }
        })
    }

    function signUpCompanyUser(formInfo, users) {
        if (users.length !== 0) {
            showAlertError(`${formInfo.email} is already registered`)
            setSignUpInProgress(false)
            return
        }

        // 2. create company
        const companyToCreate = getCompanyFromForm(formInfo)
        const requestCreateCompany = new RequestCreateCompany(companyToCreate)
        createCompany.execute(requestCreateCompany).subscribe({
            next: companyCreated => {
                // 3. create user
                const userProfile = getUserProfileFromForm(formInfo)
                userProfile.companyId = companyCreated.id
                const request = new RequestSignUp(userProfile)
                UseCase.execute(signUp, request, onSignUpSuccess, onFailure)
            },
            error: err => {
                console.error('error on create company', err)
                showAlertError("Ops, something went wrong")
                setSignUpInProgress(false)
            }
        })
    }

    const onSignUpSuccess = (userProfile) => {
        console.log('user registered', userProfile)

        // AuthProvider will redirect to job list automatically
    }

    const onFailure = (error) => {
        showAlertError(error.message)
        setSignUpInProgress(false)
    }

    const formSchema = Yup.object().shape({
        companyPhone: Yup.string().default(EMPTY_STRING),
        companyName: Yup.string().required('Company name is required'),
        firstName: Yup.string().default(EMPTY_STRING),
        lastName: Yup.string().default(EMPTY_STRING),
        email: Yup.string().required('Email is required').email('Email must be a valid email address'),
        password: Yup.string().required('Password required'),
        passwordVerified: Yup.string().required('Verify password is required'),
    })

    const methods = useForm({resolver: yupResolver(formSchema)})
    const {handleSubmit} = methods

    function getCompanyFromForm(formInfo) {
        const companyFromForm = new CompanyUC()
        companyFromForm.name = formInfo.companyName
        companyFromForm.phone = formInfo.companyPhone
        companyFromForm.address = EMPTY_STRING
        companyFromForm.country = EMPTY_STRING
        companyFromForm.state = EMPTY_STRING
        companyFromForm.city = EMPTY_STRING
        companyFromForm.zipCode = EMPTY_STRING
        companyFromForm.billingEmail = EMPTY_STRING
        companyFromForm.billingRate = DEFAULT_BILLING_RATE
        companyFromForm.referrerEmail = EMPTY_STRING
        companyFromForm.status = 'enabled'
        companyFromForm.trial = true
        companyFromForm.quotaAcres = 100
        companyFromForm.quotaJobs = 2
        return companyFromForm
    }

    function getUserProfileFromForm(formInfo) {
        const today = getTodayFormattedMMDDYYY()
        const userProfileFromForm = new UserProfile()
        userProfileFromForm.userName = formInfo.email
        userProfileFromForm.firstName = formInfo.firstName
        userProfileFromForm.lastName = formInfo.lastName
        userProfileFromForm.email = formInfo.email
        userProfileFromForm.companyId = 'to_define'
        userProfileFromForm.role = rolesEnum.ADMIN.code
        userProfileFromForm.status = 'enabled'
        userProfileFromForm.password = formInfo.password
        userProfileFromForm.termsAgreedAt = termsAccepted ? today : EMPTY_STRING

        return userProfileFromForm
    }

    function showAlertError(message) {
        setErrorMessage(message)
    }

    function validateTermsAndConditions() {
        if (!termsAccepted) {
            showAlertError('Please indicate that you have read and agree to the Terms and Conditions')
        }

        return termsAccepted
    }

    function validatePass(formInfo) {
        if (!isValidPassword(formInfo.password)) {
            showAlertError('Insecure password, minimum 8 characters. ' +
                'Secure password should include lowercase, ' +
                'uppercase, numerals and symbols characters')
            return false
        }

        if (formInfo.password !== formInfo.passwordVerified) {
            showAlertError('Passwords do NOT match')
            return false
        }

        return true
    }

    return (
        <>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSignUp)}>

                <Stack spacing={3} paddingBottom={4}>

                    <AlertError message={errorMessage} setMessage={setErrorMessage}/>

                    <RHFTextField name="email" label="Email address" required/>
                    <RHFTextField name="companyName" label="Company Name" required/>
                    <RHFTextField name="companyPhone" label="Company Phone"/>
                    <RHFTextField name="firstName" label="First Name"/>
                    <RHFTextField name="lastName" label="Last Name"/>
                    <RHFTextField name="password" label="Password" required
                                  type={showPassword ? 'text' : 'password'}
                                  InputProps={{
                                      endAdornment: (
                                          <InputAdornment position="end">
                                              <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                  <Iconify
                                                      icon={showPassword ? 'mdi:eye-outline' : 'mdi:eye-off-outline'}/>
                                              </IconButton>
                                          </InputAdornment>
                                      ),
                                  }}
                    />
                    <RHFTextField name="passwordVerified" label="Confirm password" required
                                  type={showPassword ? 'text' : 'password'}
                    />

                </Stack>

                <Stack direction={"row"} mb={2} alignItems={"center"}>
                    <Checkbox
                        checked={termsAccepted}
                        onChange={(e) => handleChange(e.target.checked)}

                    />
                    <Typography>
                        By signing up, you agree to our
                        <Link href="https://datasightusa.com/terms-of-service/" sx={{ml: 0.5}}>
                            Terms & Conditions
                        </Link>
                    </Typography>

                </Stack>

                <LoadingButton
                    fullWidth size="large" type="submit" variant="contained"
                    loading={signUpInProgress}
                >
                    Register
                </LoadingButton>

            </FormProvider>
        </>
    )
}