import {IconButton, ListItem, ListItemText} from "@mui/material";
import PropTypes from "prop-types";
import Iconify from "../iconify";
import palette from "../../theme/palette";

InfoItem.propTypes = {
    icon: PropTypes.string,
    primary: PropTypes.string,
    secondary: PropTypes.node,
    onIconClick: PropTypes.func,
}

export default function InfoItem({icon, primary, secondary, onIconClick}) {

    return <>
        <ListItem>
            <IconButton
                onClick={onIconClick}
                sx={{
                    marginRight: 2,
                    bgcolor: palette.primary.main
                }}
            >
                <Iconify icon={icon} color={"white"}/>
            </IconButton>
            <ListItemText
                primary={primary}
                secondary={<>{secondary}</>}
            />
        </ListItem>
    </>
}