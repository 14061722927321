import {Helmet} from "react-helmet-async";
import {Button, Container, Grid, Link, Paper, Stack, TextField, Typography} from "@mui/material";
import {SES} from "@aws-sdk/client-ses";
import {useState} from "react";
import {useSnackbar} from "notistack";
import getAppName from "../../utils/EnvUtils";
import InfoItem from "../../components/support/InfoItem";
import Iconify from "../../components/iconify";
import Article from "../../components/support/Article";
import awsExports from "../../aws-exports";
import {useAuthContext} from "../../auth/AuthProvider";
import {getHtmlEmailFrom} from "./utils";


export default function SupportPage() {
    const appName = getAppName()

    const {user, company} = useAuthContext()
    const {enqueueSnackbar} = useSnackbar()

    const supportEmail = "support@datasightusa.com"
    const supportPhone = "866-889-2722"

    const [message, setMessage] = useState('')

    const redirectToEmail = () => {
        window.location.href = `mailto:${supportEmail}`
    }

    const redirectToPhone = () => {
        window.location.href = `tel:${supportPhone}`
    }

    const ses = new SES({
        region: awsExports.aws_project_region,
        credentials: {
            accessKeyId: process.env.REACT_APP_SES_KEY,
            secretAccessKey: process.env.REACT_APP_SES_SECRET
        }
    })

    function getEmailParams(message) {

        const emailMessage = `${getHtmlEmailFrom(user, company, message)}`
        const emailSubject = `[User Requested Support] ${user.email}`

        return {
            Source: `${supportEmail}`,
            Destination: {
                ToAddresses: [`${supportEmail}`],
            },
            Message: {
                Body: {
                    Html: {
                        Charset: 'UTF-8',
                        Data: `${emailMessage}`
                    },
                },
                Subject: {
                    Charset: 'UTF-8',
                    Data: `${emailSubject}`,
                },
            },
        };
    }

    const sendEmail = () => {
        const emailParams = getEmailParams(message)
        ses.sendEmail(emailParams)
            .then(() => {
                enqueueSnackbar('Email delivered', {variant: 'success'})
            })
            .catch(error => {
                console.log(error)
                enqueueSnackbar('Ops, something went wrong. Try later.', {variant: 'error'})
            })

        setMessage('')
    }

    return (
        <>
            <Helmet>
                <title> Support | {appName}</title>
            </Helmet>

            <Container maxWidth={'lg'}>

                <Paper
                    elevation={1}
                    sx={{py: 2, px: 4, justifyContent: "center"}}

                >
                    <Grid container>
                        <Grid item xs={12} sm={7} padding={4}>
                            <Typography variant="h6" color="text.primary">
                                Support Articles
                            </Typography>
                            <Stack>
                                <Article
                                    title="Breakline Builder Tutorial (video)"
                                    description={""}
                                    mediaUrl={"https://youtu.be/OGnxpS0bU0g?t=35"}
                                />

                                <Article
                                    title="Create DTMs in Civil 3D from breakline data (video)"
                                    description={""}
                                    mediaUrl={"https://youtu.be/g2Uqcz3E42Y"}
                                />
                                <Article
                                    title="AOI - Basic Shapefile (video)"
                                    description={""}
                                    mediaUrl={"https://youtu.be/VqeGlbWta3s"}
                                />
                                <Article
                                    title="AOI - As a basic DXSF (video)"
                                    description={""}
                                    mediaUrl={"https://youtu.be/KRmL6yqIetY"}
                                />
                                <Article
                                    title="AOI - Polygon with Voids (video)"
                                    description={""}
                                    mediaUrl={"https://youtu.be/Wx60mmhj7GQ"}
                                />
                                <Article
                                    title="AOI - Complex (video)"
                                    description={""}
                                    mediaUrl={"https://youtu.be/k5D0Nihi4Z0"}
                                />
                                <Article
                                    title="Tutorial Article"
                                    description={
                                        <>
                                            <Link
                                                paddingRight={0.5}
                                                href="https://datasightusa.com/dtmHelp/"
                                                target="_blank">
                                                Click here
                                            </Link>
                                            for a step-by-step guide to submitting a breakline job, and a list of common issues
                                        </>
                                    }
                                />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} sm={5} padding={4}>

                            <Typography variant="h6" color="text.primary">
                                Contact Info
                            </Typography>

                            <InfoItem
                                icon={"ic:outline-email"}
                                primary={"Email"}
                                secondary={
                                    <Link href="#"
                                          onClick={redirectToEmail}
                                    >
                                        {supportEmail}
                                    </Link>
                                }
                                onIconClick={redirectToEmail}
                            />

                            <InfoItem
                                icon={"ic:baseline-phone"}
                                primary={"Telephone"}
                                secondary={
                                    <Link href="#"
                                          onClick={redirectToPhone}
                                    >
                                        {supportPhone}
                                    </Link>
                                }
                                onIconClick={redirectToPhone}
                            />

                            <Stack spacing={1} paddingTop={4}>
                                <Typography variant="h6" color="text.primary">
                                    Message Support
                                </Typography>

                                <TextField
                                    placeholder="Write your message"
                                    multiline
                                    rows={6}
                                    variant="outlined"
                                    fullWidth
                                    value={message}
                                    inputProps={{maxLength: 500}}
                                    onChange={(e) => {
                                        setMessage(e.target.value)
                                    }}
                                />

                                <Button
                                    variant="contained"
                                    endIcon={<Iconify icon="material-symbols:send" width={24} height={24}/>}
                                    sx={{width: 100}}
                                    onClick={sendEmail}
                                >
                                    Send
                                </Button>
                            </Stack>

                        </Grid>
                    </Grid>

                </Paper>

            </Container>
        </>
    )
}
