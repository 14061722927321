import {UserRepository} from "../../repositories/UserRepository";

export class RequestAcceptTerms {
    constructor(userId, dateAccepted) {
        this.userId = userId
        this.dateAccepted = dateAccepted
    }
}

export class AcceptTermsUseCase {
    constructor(repository = new UserRepository()) {
        this.repository = repository
    }

    execute(request) {
        const {userId, dateAccepted} = request
        return this.repository.acceptTerms(userId, dateAccepted)
    }
}