import {Settings} from "../../models";

export default class SettingsUC {
    constructor(id, key, value) {
        this.id = id
        this.key =  key
        this.value = value
    }

    toSettingAmplifyModel() {
        return new Settings({
            key: this.key,
            value: this.value
        })
    }

    static fromSettingsAmplifyModel(settingAmplifyModel) {
        console.log("mapping from", settingAmplifyModel)
        const setting = new SettingsUC()
        setting.id = settingAmplifyModel.id
        setting.key = settingAmplifyModel.key
        setting.value = settingAmplifyModel.value

        console.log("returning", setting)
        return setting
    }

}