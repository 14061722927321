import {Helmet} from 'react-helmet-async'
import {Container} from '@mui/material'
import getAppName from "../../utils/EnvUtils"
import JobNewForm from "../../sections/@dashboard/job/JobNewForm"

export default function CreateJobPage() {
    const appName = getAppName()

    return (
        <>
            <Helmet>
                <title> Job: Create job | {appName}</title>
            </Helmet>

            <Container maxWidth={'lg'}>
                <JobNewForm/>
            </Container>
        </>
    )
}