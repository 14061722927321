import CompanyRepository from "../../repositories/CompanyRepository";

export class RequestRemoveCompany {
    constructor(companyId) {
        this.companyId = companyId
    }
}

export default class RemoveCompanyUseCase {
    constructor(repository = new CompanyRepository()) {
        this.repository = repository
    }

    /** @return {Observable<CompanyUC>} */
    execute(request) {
        const {companyId} = request
        return this.repository.removeCompany(companyId)
    }
}