import {UserRepository} from "../../repositories/UserRepository";

export class RequestGetUserByCognitoId {
    constructor(cognitoId) {
        this.cognitoId = cognitoId
    }
}

export default class GetUserByCognitoIdUseCase {
    constructor(repository = new UserRepository()) {
        this.repository = repository
    }

    execute(request) {
        const {cognitoId} = request
        return this.repository.getUserByCognitoId(cognitoId)
    }
}