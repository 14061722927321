import {Helmet} from 'react-helmet-async';
import {useEffect, useState} from 'react';
import {paramCase} from 'change-case';
import {useNavigate} from 'react-router-dom';
import {
    Button,
    Card,
    Container,
    Divider,
    IconButton,
    Tab,
    Table,
    TableBody,
    TableContainer,
    Tabs,
    Tooltip,
} from '@mui/material';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import ConfirmDialog from '../../components/confirm-dialog';
import {
    emptyRows,
    getComparator,
    TableEmptyRows,
    TableHeadCustom,
    TableNoData,
    TablePaginationCustom,
    TableSelectedAction,
    useTable,
} from '../../components/table';
import {UserTableRow, UserTableToolbar} from '../../sections/@dashboard/user/list';
import {PATH_DASHBOARD} from "../../routes/paths";
import GetUsersUseCase, {RequestGetUsers} from "../../usecases/user/GetUsersUseCase";
import GetCompaniesUseCase from "../../usecases/company/GetCompaniesUseCase";
import getAppName from "../../utils/EnvUtils";
import UseCase from "../../usecases/UseCase";
import DisableUserUseCase, {RequestDisableUser} from "../../usecases/user/DisableUserUseCase";
import EnableUserUseCase, {RequestEnableUser} from "../../usecases/user/EnableUserUseCase";
import {useAuthContext} from "../../auth/AuthProvider";
import {getRoleName} from "../../assets/data/roles";
import {containsInFields} from "../../utils/SearchUtils";

const STATUS_OPTIONS = ['all', 'enabled', 'disabled'];
const TABLE_HEAD = [
    {id: 'fullName', label: 'Full Name', align: 'left'},
    {id: 'email', label: 'Email', align: 'left'},
    {id: 'companyName', label: 'Company', align: 'left'},
    {id: 'roleName', label: 'role', align: 'left'},
    {id: 'createdAt', label: 'member since', align: 'left'},
    {id: 'status', label: 'Status', align: 'center'},
    {id: ''},
]

export default function UserListPage() {

    const fetchUsers = new GetUsersUseCase()
    const fetchCompanies = new GetCompaniesUseCase()
    const disableUser = new DisableUserUseCase()
    const enableUser = new EnableUserUseCase()

    const appName = getAppName()
    const navigate = useNavigate()
    const {user} = useAuthContext()

    const [tableData, setTableData] = useState([])
    const [companies, setCompanies] = useState([])
    const [openConfirm, setOpenConfirm] = useState(false)
    const [filterInput, setFilterInput] = useState('')
    const [filterCompany, setFilterCompany] = useState('all')
    const [filterStatus, setFilterStatus] = useState('all')

    const {
        dense, page, order, orderBy, rowsPerPage, setPage,
        selected, setSelected, onSelectRow, onSelectAllRows,
        onSort, onChangeDense, onChangePage, onChangeRowsPerPage,
    } = useTable()

    const dataFiltered = applyFilter({
        inputData: tableData,
        companyList: companies,
        comparator: getComparator(order, orderBy),
        filterInput,
        filterCompany,
        filterStatus,
    })

    const dataInPage = dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    const denseHeight = dense ? 52 : 72
    const isFiltered = filterInput !== '' || filterCompany !== 'all' || filterStatus !== 'all'

    const isNotFound = (!dataFiltered.length && !!filterInput) ||
        (!dataFiltered.length && !!filterCompany) ||
        (!dataFiltered.length && !!filterStatus)

    useEffect(() => {
        const request = new RequestGetUsers(user)
        UseCase.execute(fetchUsers, request, onUsersFetched, onFailure)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleOpenConfirm = () => setOpenConfirm(true)
    const handleCloseConfirm = () => setOpenConfirm(false)

    const handleFilterStatus = (event, newValue) => {
        setPage(0)
        setFilterStatus(newValue)
    }

    const handleFilterInput = (event) => {
        setPage(0)
        setFilterInput(event.target.value)
    }

    const handleFilterCompany = (event) => {
        setPage(0)
        setFilterCompany(event.target.value)
    }

    const handleDisableUser = (userId) => {
        const userStatus = tableData.find(row => row.id === userId).status

        let request
        let updateStateUseCase
        switch (userStatus) {
            case 'enabled':
                request = new RequestDisableUser(userId)
                updateStateUseCase = disableUser
                break
            case 'disabled':
                request = new RequestEnableUser(userId)
                updateStateUseCase = enableUser
                break
            default:
                console.log('user status not found', userStatus)
                return
        }

        UseCase.execute(updateStateUseCase, request, onUserStatusUpdated, onFailure)
    }

    const onUserStatusUpdated = (user) => {
        const tableUpdated = tableData.map((row) => {
            if (row.id === user.id) {
                return user
            }
            return row
        })

        setSelected([])
        setTableData(tableUpdated)
    }

    const onUsersFetched = (users) => {
        setTableData(users)
    }

    const onFailure = (error) => {
        console.log(error)
    }

    const handleDeleteRows = (selectedRows) => {
        const deleteRows = tableData.filter((row) => !selectedRows.includes(row.id))
        setSelected([])
        setTableData(deleteRows)

        if (page > 0) {
            if (selectedRows.length === dataInPage.length) {
                setPage(page - 1)
            } else if (selectedRows.length === dataFiltered.length) {
                setPage(0)
            } else if (selectedRows.length > dataInPage.length) {
                const newPage = Math.ceil((tableData.length - selectedRows.length) / rowsPerPage) - 1
                setPage(newPage)
            }
        }
    }

    const handleEditRow = (user) => {
        navigate(
            PATH_DASHBOARD.users.edit(paramCase(user.firstName)), {
                state: {user, companies}
            }
        )
    }

    const handleResetFilter = () => {
        setFilterInput('')
        setFilterCompany('all')
        setFilterStatus('all')
    }

    const handleNewUserClick = () => {
        navigate(
            PATH_DASHBOARD.users.new, {
                state: {companies}
            }
        );
    }

    useEffect(() => {
        function loadCompaniesForSelection() {
            fetchCompanies.execute().subscribe({
                next: (companies) => {

                    const allOption = {id: 'all', name: 'All'}
                    let companyOptions = [allOption]
                    companyOptions = companyOptions.concat(companies)

                    setCompanies(companyOptions)
                }
            })
        }

        loadCompaniesForSelection()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Helmet>
                <title> User: List | {appName}</title>
            </Helmet>

            <Container maxWidth={'lg'}>

                <Card>
                    <Tabs
                        value={filterStatus}
                        onChange={handleFilterStatus}
                        sx={{
                            px: 2,
                            bgcolor: 'background.neutral',
                        }}
                    >
                        {STATUS_OPTIONS.map((tab) => (
                            <Tab key={tab} label={tab} value={tab}/>
                        ))}
                    </Tabs>

                    <Divider/>

                    <UserTableToolbar
                        isFiltered={isFiltered}
                        filterInput={filterInput}
                        filterCompany={filterCompany}
                        options={companies}
                        onFilterInput={handleFilterInput}
                        onFilterCompany={handleFilterCompany}
                        onResetFilter={handleResetFilter}
                        onNewUserClick={handleNewUserClick}
                    />

                    <TableContainer sx={{position: 'relative', overflow: 'unset'}}>
                        <TableSelectedAction
                            dense={dense}
                            numSelected={selected.length}
                            rowCount={tableData.length}
                            onSelectAllRows={(checked) =>
                                onSelectAllRows(
                                    checked,
                                    tableData.map((row) => row.id)
                                )
                            }
                            action={
                                <Tooltip title="Delete">
                                    <IconButton color="primary" onClick={handleOpenConfirm}>
                                        <Iconify icon="eva:trash-2-outline"/>
                                    </IconButton>
                                </Tooltip>
                            }
                        />

                        <Scrollbar>
                            <Table size={dense ? 'small' : 'medium'} sx={{minWidth: 800}}>
                                <TableHeadCustom
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={tableData.length}
                                    numSelected={selected.length}
                                    onSort={onSort}
                                    onSelectAllRows={(checked) =>
                                        onSelectAllRows(
                                            checked,
                                            tableData.map((row) => row.id)
                                        )
                                    }
                                />

                                <TableBody>
                                    {dataFiltered
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((user) => (
                                            <UserTableRow
                                                key={user.id}
                                                row={user}
                                                companies={companies}
                                                selected={selected.includes(user.id)}
                                                onSelectRow={() => onSelectRow(user.id)}
                                                onUpdateStatusRow={() => handleDisableUser(user.id)}
                                                onEditRow={() => handleEditRow(user)}
                                            />
                                        ))}

                                    <TableEmptyRows
                                        height={denseHeight}
                                        emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                                    />

                                    <TableNoData isNotFound={isNotFound}/>
                                </TableBody>
                            </Table>
                        </Scrollbar>
                    </TableContainer>

                    <TablePaginationCustom
                        count={dataFiltered.length}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onPageChange={onChangePage}
                        onRowsPerPageChange={onChangeRowsPerPage}
                        //
                        dense={dense}
                        onChangeDense={onChangeDense}
                    />
                </Card>
            </Container>

            <ConfirmDialog
                open={openConfirm}
                onClose={handleCloseConfirm}
                title="Delete"
                content={
                    <>
                        Are you sure want to delete <strong> {selected.length} </strong> items?
                    </>
                }
                action={
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            handleDeleteRows(selected)
                            handleCloseConfirm()
                        }}
                    >
                        Delete
                    </Button>
                }
            />
        </>
    )
}

function applyFilter({inputData, companyList, comparator, filterInput, filterStatus, filterCompany}) {
    if (companyList.length === 0) return inputData

    const stabilizedThis = inputData.map((user, index) => {
        user = {
            ...user,
            companyName: 'Unknown',
            roleName: getRoleName(user.role),
            fullName: `${user.firstName} ${user.lastName}`
        }

        const company = companyList.find(c => user.companyId === c.id)
        if (company !== undefined) {
            user.companyName = company.name
        }

        return [user, index]
    })

    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })

    inputData = stabilizedThis.map((el) => el[0])

    if (filterStatus !== 'all') {
        inputData = containsInFields(inputData, ['status'], filterStatus)
    }

    if (filterCompany !== 'all') {
        inputData = containsInFields(inputData, ['companyId'], filterCompany)
    }

    const fields = ['fullName', 'email']
    inputData = containsInFields(inputData, fields, filterInput)

    return inputData
}