export default class UseCase {

    static execute(usecase, request, onSuccess, onFailure, onComplete = () => {}) {
        console.log('executing use case', usecase, 'with request', request)

        usecase.execute(request).subscribe({
            next: n => onSuccess(n),
            error: e => onFailure(e),
            complete: () => onComplete()
        })
    }

    static async executeAsync(usecase, request) {
        return new Promise((resolve, reject) => {
            UseCase.execute(
                usecase,
                request,
                (result) => {
                    resolve(result)
                },
                (error) => {
                    reject(error)
                }
            )
        })
    }
}