import React from "react";
import {Outlet} from 'react-router-dom';
import {StyledRoot} from "../../pages/Login/LoginStyleUtils";
import LoginLeftBanner from "../../components/login/LoginLeftBanner";

export default function AuthLayout() {
    return (
        <>
            <StyledRoot>
                <LoginLeftBanner title={''}/>
                <Outlet/>
            </StyledRoot>
        </>
    );
}