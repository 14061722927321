import {useContext, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Box, Button, Card, Collapse, Grid, Stack, Step, StepContent, StepLabel, Stepper, Typography} from '@mui/material'
import {forkJoin} from "rxjs"
import {LoadingButton} from "@mui/lab"
import {useSnackbar} from "notistack";
import UseCase from "../../../usecases/UseCase"
import {PATH_DASHBOARD} from "../../../routes/paths"
import getStepView, {getJobStepIds, getJobStepNames, getStep, getStepIndexById, JobStep} from "./jobstep/StepManager"
import Job from "../../../usecases/models/Job"
import FileStorage from "../../../services/FileStorage"
import Iconify from "../../../components/iconify"
import UpdateJobUseCase, {RequestUpdateJob} from "../../../usecases/job/UpdateJobUseCase"
import {getBasePathForJobFile, getFilesFromJobInfo, JobFileType, JobStatus} from "../../../utils/JobUtils"
import {getTodayFormattedMMDDYYY} from "../../../utils/DateUtils"
import {EMPTY_STRING, UNKNOWN} from "../../../utils/constants"
import {StepStatus} from "./jobstep/StepStatus"
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";
import * as JobUtils from "../../../services/amplify/JobUtils";
import UpdateJobStatusUseCase, {RequestUpdateJobStatus} from "../../../usecases/job/UpdateJobStatusUseCase";
import {UploadStatusContext} from "../../../context-providers/UploadStatusProvider";
import {useAuthContext} from "../../../auth/AuthProvider";

export default function JobNewForm() {

    const {user, company} = useAuthContext()
    const {setOnUpload, setUploadStatus} = useContext(UploadStatusContext)

    const {enqueueSnackbar} = useSnackbar();

    const updateJob = new UpdateJobUseCase()
    const updateJobStatus = new UpdateJobStatusUseCase()

    const navigate = useNavigate()

    const [jobInfo, setJobInfo] = useState({})
    const [activeStepId, setActiveStepId] = useState(JobStep.STEP_JOB_INFO.id)
    const [skipped, setSkipped] = useState(new Set())
    const [uploadingFiles, setUploadingFiles] = useState(false)
    const [stepCompleted, setStepCompleted] = useState(false)
    const isInstructionOpenDefault = company.trial
    const [isInstructionOpen, setIsInstructionOpen] = useState(isInstructionOpenDefault)

    const steps = getJobStepNames()
    const stepIds = getJobStepIds()

    const isStepSkipped = (step) => skipped.has(step)

    const handleNext = () => {
        console.log('handling next button', `${activeStepId}/${steps.length}`)

        let newSkipped = skipped
        if (isStepSkipped(activeStepId)) {
            newSkipped = new Set(newSkipped.values())
            newSkipped.delete(activeStepId)
        }

        setActiveStepId((prevActiveStep) => getNextStepId(prevActiveStep))
        setSkipped(newSkipped)
        setIsInstructionOpen(isInstructionOpenDefault)
    }

    function getNextStepId(stepId) {
        return stepIds[stepIds.indexOf(stepId) + 1]
    }

    function getPreviousStepId(stepId) {
        return stepIds[stepIds.indexOf(stepId) - 1]
    }

    const updateJobAndUploadFiles = () => {
        setUploadingFiles(true)
        const jobToUpdate = getJobFromForm(jobInfo)

        console.log('EXECUTING LAST STEP')
        console.log('job to update', jobInfo.id, jobToUpdate)

        const request = new RequestUpdateJob(jobInfo.id, jobToUpdate)
        UseCase.execute(updateJob, request, onSuccessJobUpdatedCommitted, onFailure)
    }

    const onSuccessJobUpdatedCommitted = (job) => {
        const basePath = getBasePathForJobFile(user.id, job.id)
        const fileStorage = new FileStorage()
        const poolObs = []

        console.log(`JobNewForm: Completed job creation form. Uploading files to ${basePath}`, job)
        // Upload data source files
        setOnUpload(true)

        jobInfo.datasourceFiles.forEach(file => {
            poolObs.push(
                fileStorage.saveFile(
                    basePath + file.name, file,
                    JobFileType.DATASOURCE,
                    file.name,
                    onUploadProgressUpdate
                )
            )
        })
        // Upload critical breakline files
        jobInfo.breaklinesFiles.forEach(file => {
            poolObs.push(
                fileStorage.saveFile(
                    basePath + file.name, file,
                    JobFileType.BREAKLINES,
                    file.name,
                    onUploadProgressUpdate
                )
            )
        })

        forkJoin(poolObs).subscribe({
            next: filesUploaded => {
                console.log('uploaded', filesUploaded)
                // After uploading files, update status to ready
                const request = new RequestUpdateJobStatus(job.id, JobStatus.READY)
                UseCase.execute(
                    updateJobStatus,
                    request,
                    (jobUpdated) => {
                        onJobStatusUpdated(jobUpdated).then(() => {
                            setUploadingFiles(false)
                            setOnUpload(false)
                        })
                    },
                    (error) => {
                        setUploadingFiles(false)
                        setOnUpload(false)
                        onFailure(error)
                    }
                )
            }
        })
    }

    const onUploadProgressUpdate = (fileType, fileName, loaded, total) => {
        console.log(fileType, fileName, `${loaded}/${total}`)
        // const newProgressValue = Math.round(loaded * 100 / total)
        // const filesUpdated = getNewUploadStatus(filesUploading, file, jobId, jobFileType, newProgressValue)

        setUploadStatus({fileType, fileName, loaded, total})
    }

    const onJobStatusUpdated = async (jobUpdated) => {
        console.log(`JobNewForm: job #${jobUpdated.id} status updated to ${jobUpdated.status}`)
        if (jobUpdated.status === JobStatus.READY) {
            try {
                const didSucceed = await JobUtils.runJob(jobUpdated)
                if (didSucceed) {
                    enqueueSnackbar('Job was started', {variant: 'success'});
                } else {
                    enqueueSnackbar('Failed to start job', {variant: 'error'});
                }
            } catch (error) {
                enqueueSnackbar(`Failed to start job: ${error.message}`, {variant: 'error'})
            } finally {
                navigate(PATH_DASHBOARD.jobs.list, {replace: true})
            }
        }
    }

    const onFailure = (error) => {
        console.error('JobNewForm: Error callback', error)
    }

    function getJobFromForm(jobInfo) {
        const todayFormatted = getTodayFormattedMMDDYYY()

        const job = new Job()
        job.userProfileId = user.id
        job.companyId = user.companyId
        job.name = jobInfo.name
        job.status = JobStatus.UPLOADING
        job.type = jobInfo.boundaryType
        job.acreage = jobInfo.boundaryAcres
        job.billingNumber = jobInfo.billingNumber
        job.prefix = 'to_define'
        job.las = getFilesFromJobInfo(jobInfo, 'datasourceFiles')
        job.aoi = getFilesFromJobInfo(jobInfo, 'boundaryFiles')
        job.s3Bucket = UNKNOWN
        job.resolution = jobInfo.resolution
        job.customBreaklines = getFilesFromJobInfo(jobInfo, 'breaklinesFiles')
        job.errorMessage = EMPTY_STRING
        job.downloadUrl = UNKNOWN
        job.jobCreatedAt = todayFormatted
        job.jobUpdatedAt = todayFormatted
        job.jobStartedAt = todayFormatted
        job.jobFinishedAt = EMPTY_STRING
        job.billingRate = company.getProcessedBillingRate()

        return job
    }

    const handleBack = () => {
        if (getStep(activeStepId).index === 0) {
            navigate(PATH_DASHBOARD.jobs.list, {replace: true})
        } else {
            setActiveStepId((prevActiveStepId) => getPreviousStepId(prevActiveStepId))
        }
        setIsInstructionOpen(isInstructionOpenDefault)
    }

    const handleSkip = () => {
        if (!getStep(activeStepId).isOptional) {
            throw new Error("You can't skip a step that isn't optional.")
        }

        setActiveStepId((prevActiveStepId) => getNextStepId(prevActiveStepId))
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values())
            newSkipped.add(activeStepId)
            return newSkipped
        })
    }

    const navToJobList = () => {
        navigate(PATH_DASHBOARD.jobs.list, {replace: true})
    }

    const onStepInfo = (stepId, stepStatus, jobStepInfo) => {
        const isCompleted = isStepCompleted(stepId, stepStatus)
        console.log('step completed', stepId, stepStatus, jobStepInfo)
        setStepCompleted(isCompleted)

        const jobInfoUpdated = {...jobInfo, ...jobStepInfo}
        setJobInfo(jobInfoUpdated)
    }

    const handleReject = () => {
        enqueueSnackbar('Unable to proceed', {variant: 'warning'});
        handleBack()
    }

    function isStepCompleted(stepId, stepStatus) {
        return (activeStepId === stepId && stepStatus === StepStatus.COMPLETED)
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <Card sx={{p: 4}}>

                        <CustomBreadcrumbs
                            links={[
                                {
                                    name: 'Jobs',
                                    href: PATH_DASHBOARD.jobs.list,
                                },
                                {name: 'New Job'},
                            ]}
                        />

                        <Stepper
                            activeStep={getStepIndexById(activeStepId)}
                            orientation={"vertical"}
                        >
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>
                                        <Typography variant="subtitle1">
                                            {label}
                                        </Typography>
                                    </StepLabel>
                                    <StepContent>
                                        {/* {label} */}
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>

                    </Card>
                </Grid>
                <Grid item xs={9}>
                    <Card sx={{py: 2, px: 4}}>
                        <>
                            <Stack display="flex">
                                <Typography variant="h4" sx={{mt: 2, mb: 1}}>
                                    {getStep(activeStepId).title}
                                </Typography>
                                <Typography variant="subtitle1" sx={{mt: 2, mb: 1}}>
                                    {getStep(activeStepId).description}
                                    {getStep(activeStepId).instruction && !isInstructionOpen &&
                                        <Button
                                            onClick={() => setIsInstructionOpen(!isInstructionOpen)}
                                            aria-label="learn more"
                                            sx={{p:0.5, mt:-0.1}}
                                        >
                                            <Iconify icon="material-symbols:info" width={16} sx={{mr:0.5}}/>
                                            Learn More
                                        </Button>
                                    }
                                </Typography>

                                <Collapse in={isInstructionOpen} timeout="auto" unmountOnExit>
                                    <Box sx={{display: 'flex', flexDirection: 'row', ml: 2}}>
                                        {/* eslint-disable-next-line react/no-danger */}
                                        <span dangerouslySetInnerHTML={{__html:getStep(activeStepId).instruction }} />
                                    </Box>
                                </Collapse>

                                {getStepView(activeStepId, jobInfo, onStepInfo, handleReject, setStepCompleted)}

                                <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                                    <Button
                                        color="inherit"
                                        variant={"outlined"}
                                        disabled={(activeStepId === JobStep.STEP_JOB_INFO.id) || uploadingFiles}
                                        onClick={handleBack}
                                        sx={{mr: 1}}
                                    >
                                        Back
                                    </Button>
                                    <Box sx={{flex: '1 1 auto'}}/>
                                    {
                                        getStep(activeStepId).isOptional && (
                                            <Button color="inherit" onClick={handleSkip} sx={{mr: 1}}>
                                                Skip
                                            </Button>
                                        )
                                    }
                                    {
                                        activeStepId === JobStep.STEP_COMPLETE_UPLOAD.id
                                            ? <div>
                                                {
                                                    uploadingFiles &&
                                                    <Button color="inherit" onClick={navToJobList} sx={{mr: 1}}>
                                                        Go to Jobs
                                                    </Button>
                                                }
                                                <LoadingButton
                                                    size="small"
                                                    color="secondary"
                                                    onClick={updateJobAndUploadFiles}
                                                    loading={uploadingFiles}
                                                    loadingPosition="start"
                                                    startIcon={<Iconify icon="line-md:cloud-upload-loop"/>}
                                                    variant="contained"
                                                >
                                                    <span>Confirm and Upload</span>
                                                </LoadingButton>

                                            </div>
                                            : <Button variant={"contained"}
                                                      onClick={handleNext}
                                                      disabled={!stepCompleted}
                                            >
                                                {'Next'}
                                            </Button>
                                    }
                                </Box>
                            </Stack>
                        </>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}