import {UserRepository} from "../../repositories/UserRepository";

export class RequestEnableUser {
    constructor(userId) {
        this.userId = userId
    }
}

export default class EnableUserUseCase {
    constructor(repository = new UserRepository()) {
        this.repository = repository
    }

    execute(request) {
        const {userId} = request
        return this.repository.enableUser(userId)
    }
}