import PropTypes from 'prop-types'
import {Navigate} from 'react-router-dom'
import {useAuthContext} from "./AuthProvider"
import LoadingScreen from "../components/loading-screen/LoadingScreen";

GuestGuard.propTypes = {
    children: PropTypes.node,
}

export default function GuestGuard({children}) {
    const {isAuthenticated, isInitialized, resetPasswordRequired} = useAuthContext()

    console.log('[GG] initialized?', isInitialized)
    console.log('[GG] authenticated?', isAuthenticated)
    console.log('[GG] resetPassword?', resetPasswordRequired)

    if (resetPasswordRequired) {
        return <Navigate to="/verification/setup-password"/>
    }

    if (isAuthenticated) {
        return <Navigate to="/dashboard"/>
    }

    if (!isInitialized) {
        return <LoadingScreen/>
    }

    return <> {children} </>
}
