import {defer, from, Subject} from "rxjs";
import {SortDirection} from "aws-amplify";
import {UserProfiles} from "../../models";
import {rolesEnum} from "../../assets/data/roles";

export function createUser(ds, userProfile) {
    console.log('User to create>>', userProfile)
    const obs = new Subject()

    ds.save(userProfile)
        .then(userCreated => {
            console.log('[SUCCESS] createUser', userCreated)
            obs.next(userCreated)
            obs.complete()
        })
        .catch(e => {
            console.log('[FAIL] createUser', e)
            obs.error(e)
            obs.complete()
        })

    return obs
}

export function getUserByCognitoId(ds, userId) {
    const obs = new Subject()

    ds.query(UserProfiles, user => user.cognitoId.eq(userId))
        .then(user => {
            console.log('[SUCCESS] getUser', user)
            obs.next(user[0])
            obs.complete()
        })
        .catch(e => {
            console.log('[FAIL] getUser', e)
            obs.error(e)
            obs.complete()
        })

    return obs
}

/**
 * @param userEmail
 * @return {Observable<List<UserProfiles>}  Observable of list with a unique
 *                                          element if user profile exists
 *                                          otherwise, a empty list
 */
export function getUsersByEmail(ds, userEmail) {
    return from(ds.query(UserProfiles, user => user.email.eq(userEmail)))
}

export function getUserById(ds, userId) {
    return from(ds.query(UserProfiles, userId))
}

export function fetchUsers(ds, userProfile) {
    const obs = new Subject()

    const {id, role, companyId} = userProfile

    ds.query(UserProfiles, user => {
        if (role === rolesEnum.SUPER_ADMIN.code) {
            return user
        }

        if (role === rolesEnum.ADMIN.code) {
            return user.companyId.eq(companyId)
        }

        return user.id.eq(id)
    }, {
        sort: s => s.createdAt(SortDirection.DESCENDING)
    })
        .then(users => {
            console.log('[SUCCESS] fetchUsers', users)
            obs.next(users)
            obs.complete()
        })
        .catch(e => {
            console.log('[FAIL] fetchUsers', e)
            obs.error(e)
            obs.complete()
        })

    return obs
}

export function removeUser(ds, userId) {
    const obs = new Subject()

    console.log('removing', userId)
    defer(() => ds.query(UserProfiles, userId))
        .subscribe(userToRemove => {
            console.log('to remove', userToRemove)
            ds.delete(userToRemove)
                .then(userRemoved => {
                    console.log('[SUCCESS] removeUser', userRemoved)
                    obs.next(userRemoved)
                    obs.complete()
                })
                .catch(e => {
                    console.log('[FAIL] removeUser', e)
                    obs.error(e)
                    obs.complete()
                })
        })

    return obs
}

export function updateUser(ds, userId, userUpdated) {
    const obs = new Subject()

    defer(() => ds.query(UserProfiles, userId))
        .subscribe(originalUser => {

            console.log('preparing, user to update...', originalUser)
            console.log('update with...', userUpdated)

            const modelUpdated = UserProfiles.copyOf(originalUser, updated => {
                updated.firstName = userUpdated.firstName
                updated.lastName = userUpdated.lastName
                updated.email = userUpdated.email
                updated.phone = userUpdated.phone
                updated.companyId = userUpdated.companyId
                updated.role = userUpdated.role
                updated.status = userUpdated.status
            })

            ds.save(modelUpdated)
                .then(userUpdated => {
                    console.log('[SUCCESS] updateUser', userUpdated)
                    obs.next(userUpdated)
                    obs.complete()
                })
                .catch(e => {
                    console.log('[FAIL] updateUser', e)
                    obs.error(e)
                    obs.complete()
                })
        })

    return obs
}

export function updateUserStatus(ds, userId, userStatus) {
    const obs = new Subject()

    defer(() => ds.query(UserProfiles, userId))
        .subscribe(originalUser => {
            const modelUpdated = UserProfiles.copyOf(originalUser, updated => {
                updated.status = userStatus
            })

            ds.save(modelUpdated)
                .then(userUpdated => {
                    console.log('[SUCCESS] updateUserStatus', userUpdated)
                    obs.next(userUpdated)
                    obs.complete()
                })
                .catch(e => {
                    console.log('[FAIL] updateUserStatus', e)
                    obs.error(e)
                    obs.complete()
                })
        })

    return obs
}

export function acceptTermsAndConditions(ds, userId, dateAccepted) {
    const obs = new Subject()

    defer(() => ds.query(UserProfiles, userId))
        .subscribe(originalUser => {
            const modelUpdated = UserProfiles.copyOf(originalUser, updated => {
                updated.termsAgreedAt = dateAccepted
                updated.status = 'enabled'
            })

            ds.save(modelUpdated)
                .then(userUpdated => {
                    console.log('[SUCCESS] updateTermsAndConditions', userUpdated)
                    obs.next(userUpdated)
                    obs.complete()
                })
                .catch(e => {
                    console.log('[FAIL] updateTermsAndConditions', e)
                    obs.error(e)
                    obs.complete()
                })
        })

    return obs
}