import AmplifyDB from "../services/amplify/AmplifyDB"

export default class JobRepository {

    /** @param {AmplifyDB} service */
    constructor(service = new AmplifyDB()) {
        this.service = service
    }

    /** @return {Observable<Array<Job>>} */
    fetchAllJobs(userProfile) {
        return this.service.fetchAllJobs(userProfile)
    }

    /**
     * @param {Job} job
     * @return {Observable<Job>}
     */
    createJob(job) {
        return this.service.createJob(job)
    }

    deleteJob(jobId) {
        return this.service.deleteJob(jobId)
    }

    /**
     * @param {String} jobId
     * @param {Job} jobUpdated
     * @return {Observable<Job>}
     */
    updateJob(jobId, jobUpdated) {
        return this.service.updateJob(jobId, jobUpdated)
    }

    updateJobStatus(jobId, newStatus) {
        return this.service.updateJobStatus(jobId, newStatus)
    }
}