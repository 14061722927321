import {Navigate, useRoutes} from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import LoginPage from './pages/Login/LoginPage';
import SignUpPage from "./pages/Login/SignUpPage";
import AuthLayout from "./layouts/auth/AuthLayout";
import VerifyCodePage from "./pages/Login/VerifyCodePage";
import ResetPasswordPage from "./pages/Login/ResetPasswordPage";
import NewPasswordPage from "./pages/Login/NewPasswordPage";
import UserListPage from "./pages/Users/UserListPage";
import CreateUserPage from "./pages/Users/CreateUserPage";
import CompanyListPage from "./pages/Companies/CompanyListPage";
import CreateCompanyPage from "./pages/Companies/CreateCompanyPage";
import EditCompanyPage from "./pages/Companies/EditCompanyPage";
import EditUserPage from "./pages/Users/EditUserPage";
import JobListPage from "./pages/Jobs/JobListPage";
import CreateJobPage from "./pages/Jobs/CreateJobPage";
import AuthGuard from "./auth/AuthGuard";
import GuestGuard from "./auth/GuestGuard";
import Page404 from "./pages/Page404";
import EditJobPage from "./pages/Jobs/EditJobPage";
import {CompleteVerificationPage} from "./pages/User/CompleteVerificationPage";
import SupportPage from "./pages/support/SupportPage";
import InfoPage from "./pages/info/InfoPage";
import {useAuthContext} from "./auth/AuthProvider";

export default function Router() {

    const {user} = useAuthContext()

    function getDefaultRedirect() {
        if (user == null) return "/dashboard/info"

        const settings = user.status.split('_')
        console.log(user.status, `test ${  settings}`)
        if (settings.length === 1) return "/dashboard/info"

        return `/dashboard/${settings[1]}`
    }

    return useRoutes([
        {
            path: '/dashboard',
            element: (<AuthGuard><DashboardLayout/></AuthGuard>),
            children: [
                {element: <Navigate to={getDefaultRedirect()}/>, index: true},
                {
                    path: 'jobs',
                    children: [
                        {element: <Navigate to="/dashboard/jobs/list" replace/>, index: true},
                        {path: 'list', element: <JobListPage/>},
                        {path: 'new', element: <CreateJobPage/>},
                        {path: ':name/edit', element: <EditJobPage/>},
                    ],
                },
                {
                    path: 'users',
                    children: [
                        {element: <Navigate to="/dashboard/users/list" replace/>, index: true},
                        {path: 'list', element: <UserListPage/>},
                        {path: 'new', element: <CreateUserPage/>},
                        {path: ':name/edit', element: <EditUserPage/>},
                    ],
                },
                {
                    path: 'companies',
                    children: [
                        {element: <Navigate to="/dashboard/companies/list" replace/>, index: true},
                        {path: 'list', element: <CompanyListPage/>},
                        {path: 'new', element: <CreateCompanyPage/>},
                        {path: ':name/edit', element: <EditCompanyPage/>},
                    ],
                },
                {
                    path: 'support',
                    element: <SupportPage/>
                },
                {
                    path: 'info',
                    element: <InfoPage/>
                },
            ],
        },
        {
            path: '/auth',
            element: (<GuestGuard><AuthLayout/></GuestGuard>),
            children: [
                {element: <Navigate to="/auth/login"/>, index: true},
                {path: 'login', element: <LoginPage/>},
                {path: 'signup', element: <SignUpPage/>},
                {path: 'verify', element: <VerifyCodePage/>},
                {path: 'reset-password', element: <ResetPasswordPage/>},
                {path: 'new-password', element: <NewPasswordPage/>}
            ],
        },
        {
            path: '/verification',
            element: <AuthGuard><AuthLayout/></AuthGuard>,
            children: [
                {element: <Navigate to="/verification/setup-password"/>, index: true},
                {path: 'setup-password', element: <CompleteVerificationPage/>},
            ]
        },
        {path: "/", element: <Navigate to="/auth" replace/>},
        {path: "*", element: <Page404/>}
    ]);
}