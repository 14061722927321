import {createContext, useCallback, useContext, useEffect, useMemo, useState} from "react"
import PropTypes from "prop-types"
import {from} from "rxjs";
import {Auth, DataStore, Hub} from "aws-amplify";
import GetUserByCognitoIdUseCase, {RequestGetUserByCognitoId} from "../usecases/user/GetUserByCognitoIdUseCase";
import UseCase from "../usecases/UseCase";
import GetCompanyUseCase, {RequestGetCompany} from "../usecases/company/GetCompanyUseCase";
import {isEmpty} from "../utils/StringUtils";

AuthProvider.propTypes = {
    children: PropTypes.node
}

export function AuthProvider({children}) {

    const getUser = new GetUserByCognitoIdUseCase()
    const getCompany = new GetCompanyUseCase()

    const [userSession, setUserSession] = useState(null)
    const [userCompany, setUserCompany] = useState(null)
    const [initialized, setInitialized] = useState(false)
    const [authenticated, setAuthenticated] = useState(false)
    const [requireResetPassword, setRequireResetPassword] = useState(false)
    const [errorFromAuth, setErrorFromAuth] = useState(null)

    const initAuthListener = useCallback(async () => {
        // (1) register auth listener events
        const authEventsListener = (data) => {
            console.log('Auth event', data.payload.event, data)
            switch (data.payload.event) {
                case 'signIn':
                    checkUserLogged()
                    break;
                case 'signOut':
                    setAuthenticated(false)
                    break;
                case 'forgotPasswordSubmit':
                    checkUserLogged()
                    break;
                case 'confirmSignUp':
                    checkUserLogged()
                    break;
                default:
            }
        }

        Hub.listen('auth', authEventsListener);

        // (2) register db listener ready event
        Hub.listen("datastore", hubData => {
            const {event} = hubData.payload
            if (event === "ready") {
                checkUserLogged()
            }
        })

        // (3) sync db
        await DataStore.start()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        from(initAuthListener()).subscribe()
    }, [initAuthListener])

    function checkUserLogged() {
        Auth.currentAuthenticatedUser()
            .then(user => {
                const request = new RequestGetUserByCognitoId(user.attributes.sub)
                UseCase.execute(getUser, request, onSuccessLogin, onFailure)
            })
            .catch(e => {
                console.log('auth user error', e)
                setInitialized(true)
            })
    }

    const onSuccessLogin = (userProfile) => {
        if (userProfile.status === 'disabled') {

            logout()
            setErrorFromAuth("User is disabled. Contact your administrator")
            setAuthenticated(false)
            setInitialized(true)
            return
        }

        const request = new RequestGetCompany(userProfile.companyId)
        getCompany.execute(request).subscribe({
            next: companyFetched => setUserCompany(companyFetched),
            error: err => console.log(err)
        })

        setUserSession(userProfile)
        setAuthenticated(true)
        setInitialized(true)

        if (userProfile.status === 'reset_password') {
            const needAcceptTC = isEmpty(userProfile.termsAgreedAt)
            setRequireResetPassword(needAcceptTC)
        }
    }

    const onFailure = (err) => console.error('LOGIN ERROR', err)

    const login = useCallback((email, password) =>
            from(Auth.signIn(email, password))
        , [])

        const logout = useCallback(() =>
            from(Auth.signOut())
        , [])

    const contextOptions = useMemo(() => ({
            user: userSession,
            company: userCompany,
            isInitialized: initialized,
            isAuthenticated: authenticated,
            resetPasswordRequired: requireResetPassword,
            setRequireResetPassword,
            errorFromAuth,
            setErrorFromAuth,
            setUserSession,
            login,
            logout
        }),
        [
            userSession,
            userCompany,
            initialized,
            authenticated,
            requireResetPassword,
            setRequireResetPassword,
            errorFromAuth,
            setErrorFromAuth,
            setUserSession,
            login,
            logout
        ])

    return <AuthContext.Provider value={contextOptions}>{children}</AuthContext.Provider>
}

export const AuthContext = createContext(null)

export const useAuthContext = () => {
    const context = useContext(AuthContext)

    if (!context) throw new Error('useAuthContext context must be use inside AuthProvider')

    return context
}