import {Box, IconButton, LinearProgress, Stack, Typography} from "@mui/material";
import {m} from "framer-motion";
import PropTypes from "prop-types";
import React from "react";
import Iconify from "../../iconify";

FilePreviewUpload.propTypes = {
    key: PropTypes.string,
    filePreviewInfo: PropTypes.object,
    descriptionVisible: PropTypes.bool,
    iconVisible: PropTypes.bool,
    progress: PropTypes.bool,
    actionDownload: PropTypes.func,
    actionDelete: PropTypes.func,
};

export function FilePreviewUpload(
    {
        key,
        filePreviewInfo,
        descriptionVisible = true,
        iconVisible = true,
        progress = true,
        actionDownload = null,
        actionDelete = null,
    }
) {
    const {name, description, progressValue} = filePreviewInfo

    const isCompleted = () => progressValue === 100
    const isUploading = () => progress && !isCompleted()

    const showIconCompleted = () => isCompleted() && !actionDownload && !actionDelete
    const showActionDownload = () => actionDownload && isCompleted()
    const showActionDelete = () => actionDelete

    return <>
        <Box key={key} sx={{
            px: 1.75, py: 0.75, borderRadius: 1.25,
            border: (theme) => `solid 1px ${theme.palette.divider}`,
        }}>
            <Stack
                component={m.div}
                direction="row"
                alignItems="center"
            >

                {
                    iconVisible && <Iconify icon="ic:baseline-upload-file"
                                            sx={{marginRight: 1}}
                    />
                }

                <Stack flexGrow={1} sx={{minWidth: 0}}>
                    <Typography variant="subtitle2" noWrap>
                        {name}
                    </Typography>

                    {
                        descriptionVisible && <Typography variant="caption" sx={{color: 'text.secondary'}}>
                            {description}
                        </Typography>
                    }
                </Stack>

                {
                    isUploading() &&
                    <Typography variant="caption" display="block">
                        {Math.round(progressValue)} %
                    </Typography>
                }

                {
                    showIconCompleted() &&
                    <Iconify icon="material-symbols:check-small-rounded"/>
                }


                {
                    showActionDownload() && <IconButton onClick={() => {
                        console.log("download file", name)
                        actionDownload(name)
                    }}>
                        <Iconify icon="ep:download" width={20}/>
                    </IconButton>
                }

                {
                    showActionDelete() && <IconButton onClick={() => {
                        console.log("remove file", name)
                        actionDelete(name)
                    }}>
                        <Iconify icon="material-symbols:delete-outline" width={20}/>
                    </IconButton>
                }


            </Stack>

            {
                isUploading() &&
                <LinearProgress variant="determinate" value={progressValue}
                                sx={{marginY: 0.25, borderRadius: 2}}/>
            }
        </Box>
    </>
}