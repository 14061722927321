import {Checkbox, IconButton, InputAdornment, Link, Stack, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import PropTypes from "prop-types";
import {useState} from "react";
import {useForm} from "react-hook-form";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {useSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import {Auth} from "aws-amplify";
import {AlertError} from "../../../components/utils";
import Iconify from "../../../components/iconify";
import VerifyNewPasswordUseCase, {RequestVerifyNewPassword} from "../../../usecases/login/VerifyNewPasswordUseCase";
import UseCase from "../../../usecases/UseCase";
import FormProvider, {RHFTextField} from "../../../components/hook-form";
import {AcceptTermsUseCase, RequestAcceptTerms} from "../../../usecases/user/AcceptTermsUseCase";
import GetUserByEmailUseCase, {RequestGetUserByEmail} from "../../../usecases/user/GetUserByEmailUseCase";
import {getTodayFormattedMMDDYYY} from "../../../utils/DateUtils";

NewPasswordForm.propTypes = {
    email: PropTypes.string,
    needVerifyTerms: PropTypes.bool
}

export default function NewPasswordForm({email, needVerifyTerms}) {

    const verifyNewPassword = new VerifyNewPasswordUseCase()
    const acceptTerms = new AcceptTermsUseCase()
    const getUserByEmail = new GetUserByEmailUseCase()

    const [showPassword, setShowPassword] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [verifyInProgress, setVerifyInProgress] = useState(false)
    const [termsAccepted, setTermsAccepted] = useState(false)

    const {enqueueSnackbar} = useSnackbar()
    const navigate = useNavigate()

    const handleChange = (newValue) => {
        setTermsAccepted(newValue)
    }

    const onResetPassword = (formInfo) => {
        if (!validateTermsAndConditions()) return

        setVerifyInProgress(true)

        if (needVerifyTerms) {
            const request = new RequestGetUserByEmail(email)
            getUserByEmail.execute(request).subscribe({
                next: userProfiles => {
                    const user = userProfiles[0]
                    const today = getTodayFormattedMMDDYYY()
                    const requestAccept = new RequestAcceptTerms(user.id, today)
                    acceptTerms.execute(requestAccept).subscribe({
                        next: () => console.log('user accept terms and conditions'),
                        error: (err) => console.log('something went wrong', err)
                    })
                },
                error: err => {
                    console.log('something went wrong, user not found', err)
                }
            })
        }

        const request = new RequestVerifyNewPassword(email, formInfo.code, formInfo.password)
        UseCase.execute(verifyNewPassword, request, onPasswordUpdated, onFailure)

        Auth.currentAuthenticatedUser()
            .then((user) => console.log("auth user", user))
            .then((data) => console.log(data))
            .catch((err) => onFailure(err));
    }

    const onPasswordUpdated = () => {
        enqueueSnackbar('Password updated!', {variant: 'success'});
        navigate('/auth/', {
            replace: true,
        })
        // Hub listener at app level will redirect to sign in
    }

    const onFailure = (error) => {
        console.error(error)
        showAlertError(error.message)
        setVerifyInProgress(false)
    }

    const formSchema = Yup.object().shape({
        code: Yup.string().required('Code is required'),
        password: Yup.string().required('Password required'),
    })

    const methods = useForm({resolver: yupResolver(formSchema)})
    const {handleSubmit} = methods

    function showAlertError(message) {
        setErrorMessage(message)
    }

    function validateTermsAndConditions() {
        if (!termsAccepted && needVerifyTerms) {
            showAlertError('Please indicate that you have read and agree to the Terms and Conditions')
            return false
        }

        return true
    }

    return (
        <>
            <FormProvider methods={methods} onSubmit={handleSubmit(onResetPassword)}>

                <Stack spacing={3} paddingBottom={4}>

                    <AlertError message={errorMessage} setMessage={setErrorMessage}/>

                    <RHFTextField name="code" label="Code" autocomplete="one-time-code" required/>

                    <RHFTextField name="password" label="Password" autocomplete="new-password" required
                                  type={showPassword ? 'text' : 'password'}
                                  InputProps={{
                                      endAdornment: (
                                          <InputAdornment position="end">
                                              <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                  <Iconify
                                                      icon={showPassword ? 'mdi:eye-outline' : 'mdi:eye-off-outline'}/>
                                              </IconButton>
                                          </InputAdornment>
                                      ),
                                  }}
                    />
                </Stack>

                {
                    needVerifyTerms &&
                    <Stack direction={"row"} mb={2} alignItems={"center"}>
                        <Checkbox
                            checked={termsAccepted}
                            onChange={(e) => handleChange(e.target.checked)}

                        />
                        <Typography>
                            By continue, you agree to our
                            <Link href="https://datasightusa.com/terms-of-service/" sx={{ml: 0.5}}>
                                Terms & Conditions
                            </Link>
                        </Typography>

                    </Stack>
                }

                <LoadingButton
                    fullWidth size="large" type="submit" variant="contained"
                    loading={verifyInProgress}
                >
                    Update Password
                </LoadingButton>

            </FormProvider>
        </>
    );
}