import {Stack, Typography} from "@mui/material"
import {useContext, useEffect, useState} from "react"
import PropTypes from "prop-types"
import {StepStatus} from "./StepStatus"
import {JobFileType} from "../../../../utils/JobUtils"
import {JobStep} from "./StepManager"
import {UploadStatusContext} from "../../../../context-providers/UploadStatusProvider";
import {FilesPreviewUpload} from "../../../../components/upload/preview/FilesPreviewUpload";
import {FileUploadPreviewModel} from "../../../../components/upload/preview/FilePreviewUploadModel";

FilePreviewSection.propTypes = {
    title: PropTypes.string,
    filesInfo: PropTypes.array
}

function FilePreviewSection({title, filesInfo}) {
    return <Stack spacing={0.5}>
        <Typography variant="button">
            {title}
        </Typography>
        <FilesPreviewUpload filesInfo={filesInfo}/>
    </Stack>
}

StepUpload.propTypes = {
    onStepInfo: PropTypes.func,
    jobInfo: PropTypes.object
}

export default function StepUpload({onStepInfo, jobInfo}) {
    const stepId = JobStep.STEP_COMPLETE_UPLOAD.id
    const {uploadStatus} = useContext(UploadStatusContext)

    const {boundaryFiles, datasourceFiles, breaklinesFiles} = jobInfo

    const [boundaryFilesPreviewUpload, setBoundaryFilesPreviewUpload] = useState(getFilesPreview(boundaryFiles))
    const [datasourceFilesPreviewUpload, setDatasourceFilesPreviewUpload] = useState(getFilesPreview(datasourceFiles))
    const [breaklinesFilesPreviewUpload, setBreaklinesFilesPreviewUpload] = useState(getFilesPreview(breaklinesFiles))

    useEffect(() => {
        onStepInfo(stepId, getStepStatus(), {})

        boundaryFilesPreviewUpload.forEach(
            file => onProgressUpdated(JobFileType.BOUNDARY, file.name, 100)
        )

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        const {fileType, fileName, loaded, total} = uploadStatus
        const newProgressValue = Math.round(loaded * 100 / total)

        if (newProgressValue % 10 === 0) {
            console.log(fileName, "->", newProgressValue)
        }
        onProgressUpdated(fileType, fileName, newProgressValue)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadStatus])

    function getFilesPreview(files) {
        return files.map(file => FileUploadPreviewModel.fromFile(file))
    }

    function getStepStatus() {
        const stepCompleted = jobInfo !== {}
        return stepCompleted ? StepStatus.COMPLETED : StepStatus.UNCOMPLETED
    }

    function onProgressUpdated(fileType, fileName, newProgressValue) {
        if (fileType === JobFileType.BOUNDARY) {
            const updated = updateFileProgress(boundaryFilesPreviewUpload, fileName, newProgressValue)
            setBoundaryFilesPreviewUpload([...updated])
            return
        }

        if (fileType === JobFileType.DATASOURCE) {
            const updated = updateFileProgress(datasourceFilesPreviewUpload, fileName, newProgressValue)
            setDatasourceFilesPreviewUpload([...updated])
            return
        }

        if (fileType === JobFileType.BREAKLINES) {
            const updated = updateFileProgress(breaklinesFilesPreviewUpload, fileName, newProgressValue)
            setBreaklinesFilesPreviewUpload([...updated])
        }
    }

    function updateFileProgress(files, fileName, progressUpload) {
        return files.map(file => {
            if (file.name === fileName) {
                file.progressValue = progressUpload
            }
            return file
        })
    }

    function existCustomBreaklines() {
        return jobInfo.breaklinesFiles !== undefined &&
            jobInfo.breaklinesFiles.length > 0
    }

    return <>
        <Stack spacing={2}>
            <FilePreviewSection
                title={"Boundary files"}
                filesInfo={boundaryFilesPreviewUpload}
            />

            <FilePreviewSection
                title={"Data Source files"}
                filesInfo={datasourceFilesPreviewUpload}
            />

            {
                existCustomBreaklines() &&
                <FilePreviewSection
                    title={"Breaklines Files"}
                    filesInfo={breaklinesFilesPreviewUpload}
                />
            }
        </Stack>
    </>
}