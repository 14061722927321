import AmplifyDB from "../services/amplify/AmplifyDB";

export default class SettingRepository {

    /** @param {AmplifyDB} service */
    constructor(service = new AmplifyDB()) {
        this.service = service
    }

    getSettingByKey(settingKey) {
        return this.service.getSettingByKey(settingKey)
    }

    updateSetting(settingId, settingUpdated) {
        return this.service.updateSetting(settingId, settingUpdated)
    }

}