import {styled} from '@mui/material/styles'
import {LinearProgress, Stack} from '@mui/material'
import Logo from "../logo";

const StyledRoot = styled('div')(({theme}) => ({
    right: 0,
    bottom: 0,
    zIndex: 9998,
    width: '100%',
    height: '100%',
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
}))

export default function LoadingScreen() {
    return (
        <>
            <StyledRoot>
                <Stack alignItems={"center"} spacing={2}>
                    <Logo sx={{width: 306, height: 92}}/>
                    <LinearProgress variant="indeterminate" sx={{width: 350}}/>
                </Stack>
            </StyledRoot>
        </>
    )
}