import {Helmet} from "react-helmet-async";
import {Button, Checkbox, Container, Paper, Stack, Typography} from "@mui/material";
import MDEditor from '@uiw/react-md-editor';
import {useEffect, useState} from "react";
import {useAuthContext} from "../../auth/AuthProvider";
import getAppName from "../../utils/EnvUtils";
import UpdateSettingUseCase, {RequestUpdateSetting} from "../../usecases/settings/UpdateSettingUseCase";
import GetSettingByKeyUseCase, {RequestGetSettingById} from "../../usecases/settings/GetSettingByKeyUseCase";
import UseCase from "../../usecases/UseCase";
import SettingsUC from "../../usecases/models/Settings";
import UpdateUserUseCase, {RequestUpdateUser} from "../../usecases/user/UpdateUserUseCase";
import UserProfile from "../../usecases/models/UserProfile";
import GetUsersUseCase, {RequestGetUsers} from "../../usecases/user/GetUsersUseCase";

export default function InfoPage() {
    const appName = getAppName()

    const {user, setUserSession} = useAuthContext()

    const updateSetting = new UpdateSettingUseCase()
    const getSetting = new GetSettingByKeyUseCase()
    const updateUser = new UpdateUserUseCase()
    const getUsers = new GetUsersUseCase()

    const KEY_INFO_CONTENT = "info_content"

    useEffect(() => {
        const request = new RequestGetSettingById(KEY_INFO_CONTENT)
        UseCase.execute(getSetting, request, onSettingInfo, onFailure)
    }, [])

    const onSettingInfo = (info) => {
        setInfoContent(info.value)
    }

    const onFailure = (error) => {
        console.log('error', error)
    }

    const [infoContent, setInfoContent] = useState("");

    const onSave = () => {
        const infoSetting = new SettingsUC()
        infoSetting.key = KEY_INFO_CONTENT
        infoSetting.value = infoContent
        const request = new RequestUpdateSetting(KEY_INFO_CONTENT, infoSetting)
        UseCase.execute(updateSetting, request, onSettingUpdated, onFailure)
    }

    const onResetForAllUsers = () => {
        const request = new RequestGetUsers(user)
        UseCase.execute(getUsers, request, (users) => {
            const toReset = users.filter(user => getRedirect(user.status) === 'jobs')

            toReset.forEach((u) => {
                const redirectValue = 'info'
                u.status = `${getStatus(u.status)}_${redirectValue}`
                const request = new RequestUpdateUser(u.id, u)
                UseCase.execute(updateUser, request, onSuccessUserUpdated, onFailure)
            })
        }, onFailure)
    }

    const onSettingUpdated = (info) => {
        console.log("info", info)
    }

    function isSuperAdmin() {
        return user.role === '200'
    }

    function getStatus(value) {
        if (value === null) return 'enabled'
        const settings = value.split('_')
        return settings[0]
    }

    function getRedirect(value) {
        const settings = value.split('_')
        if (settings.length === 1) return "info"
        return settings[1]
    }

    const [redirect, setRedirect] = useState(getRedirect(user.status) === 'jobs')

    const handleChange = (newValue) => {
        const userModel = UserProfile.fromUserProfileAmplifyModel(user)
        setRedirect(newValue)

        const redirectValue = newValue ? 'jobs' : 'info'
        userModel.status = `${getStatus(userModel.status)}_${redirectValue}`
        setUserSession(userModel)
        const request = new RequestUpdateUser(userModel.id, userModel)
        UseCase.execute(updateUser, request, onSuccessUserUpdated, onFailure)
    }

    const onSuccessUserUpdated = (user) => {
        console.log('updated', user)
    }

    return (
        <>
            <Helmet>
                <title> Info | {appName}</title>
            </Helmet>

            <Container maxWidth={'lg'}>

                <Stack spacing={1}>
                    {isSuperAdmin() &&
                        <MDEditor
                            height={600}
                            value={infoContent}
                            onChange={setInfoContent}
                            preview={'live'}
                            color={"white"}
                            data-color-mode={"light"}
                            visibleDragbar={"false"}
                            draggable={"false"}
                            sx={{boxShadow: 'none', display: 'inline', border: 1}}
                            disp
                            // fullscreen={"true"}
                        />
                    }

                    {
                        !isSuperAdmin() && <MDEditor
                            height={600}
                            value={infoContent}
                            onChange={setInfoContent}
                            preview={'preview'}
                            commands={[]}
                            extraCommands={[]}
                            color={"white"}
                            data-color-mode={"light"}
                            hideToolbar={"true"}
                            visibleDragbar={"false"}
                            draggable={"false"}
                            sx={{boxShadow: 'none', display: 'inline', border: 1}}
                            disp
                            // fullscreen={"true"}
                        />
                    }

                    {
                        isSuperAdmin() && <Stack direction={"row"} spacing={1} justifySelf={"end"}>
                            <Button
                                variant="contained"
                                onClick={onSave}
                            >
                                Save
                            </Button>

                            <Button
                                variant="contained"
                                onClick={onResetForAllUsers}
                            >
                                Reset for all users
                            </Button>


                        </Stack>

                    }

                    <Paper>
                    <Typography variant="body2">

                        <Checkbox
                            checked={redirect}
                            onChange={(e) => handleChange(e.target.checked)}
                        />
                        I have read this message (set default to Jobs)
                    </Typography>
                    </Paper>


                </Stack>


            </Container>
        </>
    )
}