import {Container, Typography} from "@mui/material";
import getAppName from "../../utils/EnvUtils";
import {Head} from "../../components/utils";
import {StyledContent} from "../Login/LoginStyleUtils";
import {CompleteVerificationForm} from "../../sections/verification/CompleteVerificationForm";

export function CompleteVerificationPage() {

    const appName = getAppName()

    return <>
        <Head title={`Setup Password | ${appName}`}/>

        <Container maxWidth="sm">
            <StyledContent>

                <Typography variant="h4">
                    Set your password
                </Typography>

                <CompleteVerificationForm/>

            </StyledContent>
        </Container>

    </>
}