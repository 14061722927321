import {defer, Subject} from "rxjs";
import {Predicates, SortDirection} from "aws-amplify";
import {Company} from "../../models";

export function createCompany(ds, company) {
    const obs = new Subject()

    ds.save(company)
        .then(companyCreated => {
            console.log('[SUCCESS] createCompany', companyCreated)
            obs.next(companyCreated)
            obs.complete()
        })
        .catch(e => {
            console.log('[FAIL] createCompany', e)
            obs.error(e)
            obs.complete()
        })

    return obs
}

/** @return {Observable<CompanyUC>} */
export function getCompany(ds, companyId) {
    const obs = new Subject()
    ds.query(Company, companyId)
        .then(company => {
            console.log('[SUCCESS] getCompany', company)
            obs.next(company)
            obs.complete()
        })
        .catch(e => {
            console.log('[FAIL] getCompany', e)
            obs.error(e)
            obs.complete()
        })

    return obs
}

export function fetchCompanies(ds) {
    const obs = new Subject()

    ds.query(Company, Predicates.ALL,
        { sort: s => s.createdAt(SortDirection.DESCENDING)}
    )
        .then(companies => {
            console.log('[SUCCESS] fetchCompanies', companies)
            obs.next(companies)
            obs.complete()
        })
        .catch(e => {
            console.log('[FAIL] fetchCompanies', e)
            obs.error(e)
            obs.complete()
        })

    return obs
}

// TODO should it be restricted only for companies with no users?
export function removeCompany(ds, companyId) {
    const obs = new Subject()

    const getOriginalCompany = () => ds.query(Company, companyId)
    defer(getOriginalCompany).subscribe(companyToRemove => {
        ds.delete(companyToRemove)
            .then(companyRemoved => {
                console.log('[SUCCESS] removeCompany', companyRemoved)
                obs.next(companyRemoved)
                obs.complete()
            })
            .catch(e => {
                console.log('[FAIL] removeCompany', e)
                obs.error(e)
                obs.complete()
            })
    })

    return obs
}

export function updateCompany(ds, companyId, companyUpdated) {
    const obs = new Subject()

    const getOriginalCompany = () => ds.query(Company, companyId)
    defer(getOriginalCompany).subscribe(originalCompany => {
        const cUpdated = Company.copyOf(originalCompany, updated => {
            updated.name = companyUpdated.name
            updated.phone = companyUpdated.phone
            updated.address = companyUpdated.address
            updated.country = companyUpdated.country
            updated.state = companyUpdated.state
            updated.city = companyUpdated.city
            updated.zipCode = companyUpdated.zipCode
            updated.billingEmail = companyUpdated.billingEmail
            updated.billingRate = companyUpdated.billingRate
            updated.referrerEmail = companyUpdated.referrerEmail
            updated.status = companyUpdated.status
            updated.trial = companyUpdated.trial
            updated.quotaAcres = companyUpdated.quotaAcres
            updated.quotaJobs = companyUpdated.quotaJobs
            updated.accountUpgradedAt = companyUpdated.accountUpgradedAt
        })

        ds.save(cUpdated)
            .then(companyCreated => {
                console.log('[SUCCESS] updateCompany', companyCreated)
                obs.next(companyCreated)
                obs.complete()
            })
            .catch(e => {
                console.log('[FAIL] updateCompany', e)
                obs.error(e)
                obs.complete()
            })
    })

    return obs
}