import PropTypes from 'prop-types';
import {useState} from 'react';
import {Button, Checkbox, IconButton, MenuItem, TableCell, TableRow, Typography,} from '@mui/material';
import Label from '../../../../components/label';
import Iconify from '../../../../components/iconify';
import ConfirmDialog from '../../../../components/confirm-dialog';
import MenuPopover from "../../../../components/menu-popover/MenuPopover";
import {getArrayRoles} from "../../../../assets/data/roles";

UserTableRow.propTypes = {
    row: PropTypes.object,
    companies: PropTypes.array,
    selected: PropTypes.bool,
    onEditRow: PropTypes.func,
    onUpdateStatusRow: PropTypes.func,
    onSelectRow: PropTypes.func,
}

export default function UserTableRow({row, companies, selected, onEditRow, onSelectRow, onUpdateStatusRow}) {
    const {firstName, lastName, email, companyId, role, status, createdAt} = row

    const [openConfirm, setOpenConfirm] = useState(false)
    const [openPopover, setOpenPopover] = useState(null)

    const handleOpenConfirm = () => setOpenConfirm(true)
    const handleCloseConfirm = () => setOpenConfirm(false)
    const handleOpenPopover = (event) => setOpenPopover(event.currentTarget)
    const handleClosePopover = () => setOpenPopover(null)

    function getCompanyName(companyId) {
        const unknownCompany = 'Unknown'
        if (companies.length === 0) return unknownCompany

        const company = companies.find((company) => company.id === companyId)
        return company ? company.name : unknownCompany
    }

    function getRoleName(roleCode) {
        const roleName = getArrayRoles().find(role => role.code === roleCode)
        if (!roleName) return 'Unknown'
        return roleName.label || 'Unknown'
    }

    function getDateFormatted(date) {
        return new Date(date).toLocaleDateString("en-US")
    }

    return (
        <>
            <TableRow hover selected={selected}>
                <TableCell padding="checkbox">
                    <Checkbox checked={selected} onClick={onSelectRow}/>
                </TableCell>

                <TableCell>
                    <Typography variant="subtitle2" noWrap>
                        {firstName} {lastName}
                    </Typography>
                </TableCell>

                <TableCell align="left">{email}</TableCell>
                <TableCell align="left">{getCompanyName(companyId)}</TableCell>
                <TableCell align="left">{getRoleName(role)}</TableCell>
                <TableCell align="left">{getDateFormatted(createdAt)}</TableCell>

                <TableCell align="center">
                    <Label
                        variant="soft"
                        color={(status.startsWith('enabled') && 'info') || 'error'}
                        sx={{textTransform: 'capitalize'}}
                    >
                        {(status.startsWith('enabled') && 'Enabled') || 'Disabled'}
                    </Label>
                </TableCell>

                <TableCell align="right">
                    <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}>
                        <Iconify icon="eva:more-vertical-fill"/>
                    </IconButton>
                </TableCell>
            </TableRow>

            <MenuPopover
                open={openPopover}
                onClose={handleClosePopover}
                arrow="right-top"
                sx={{width: 140}}
            >
                <MenuItem
                    onClick={() => {
                        onEditRow()
                        handleClosePopover()
                    }}
                >
                    <Iconify icon="material-symbols:edit"/>
                    Edit
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleOpenConfirm()
                        handleClosePopover()
                    }}
                    sx={{color: (status === 'enabled' && 'error.main') || 'info.main'}}

                >
                    <Iconify icon={(status === 'enabled' && 'ic:baseline-toggle-off') || 'ic:baseline-toggle-on'}/>
                    {(status === 'enabled' && 'Disable') || 'Enable'}
                </MenuItem>
            </MenuPopover>

            <ConfirmDialog
                open={openConfirm}
                onClose={handleCloseConfirm}
                title={(status === 'enabled' && 'Disable User') || 'Enable User'}
                content={(status === 'enabled' && 'Are you sure to disable the user') || 'Are you sure to enable the user'}
                action={
                    <Button variant="contained" color="warning" onClick={() => {
                        handleCloseConfirm()
                        onUpdateStatusRow()
                    }}>
                        Confirm
                    </Button>
                }
            />
        </>
    )
}