/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "appApi",
            "endpoint": "https://gkuxdpddmk.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://phuk6lewfbewnkhqpc4afdr6sy.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-ixnl5shc65azpfcmwmbunnc5ty",
    "aws_cognito_identity_pool_id": "us-east-2:a0a68736-7943-4a2d-bb9c-8fe1bab21c4e",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_nY89UvjGc",
    "aws_user_pools_web_client_id": "np7ktnr58ag7tmi15t91i8bft",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "webapp-storage-f1346c3b35530-prod",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
