import React, {useCallback, useEffect, useState} from "react"
import PropTypes from "prop-types"
import {ToggleButton, ToggleButtonGroup} from "@mui/lab";
import {FormControl, Stack, Typography} from "@mui/material"
import Upload from "../../../../components/upload/Upload"
import {JobStep} from "./StepManager"
import {EMPTY_STRING} from "../../../../utils/constants"
import {StepStatus} from "./StepStatus"
import {isFilesCompleted, JobResolutions} from "../../../../utils/JobUtils";

export const DataSourceFileTypes = {
    LASZ: {
        label: 'Point Cloud (as LAZ or LAS)',
        type: 'datasource_las_laz',
        multipleFiles: false,
        required: ['.laz', '.las'],
        minRequired: 1,
        optional: []
    },
    TIF: {
        label: 'DEM (as TIF)',
        type: 'datasource_tif',
        multipleFiles: false,
        required: ['.tif', '.tiff'],
        minRequired: 1,
        optional: []
    }
}

export function getDataSourceFileType(type) {
    switch (type) {
        case DataSourceFileTypes.LASZ.type:
            return DataSourceFileTypes.LASZ
        case DataSourceFileTypes.TIF.type:
            return DataSourceFileTypes.TIF
        default:
            return {}
    }
}

StepDataSource.propTypes = {
    onStepInfo: PropTypes.func,
    jobInfo: PropTypes.object
}

export default function StepDataSource({onStepInfo, jobInfo}) {
    const defaultDataSourceType = DataSourceFileTypes.LASZ
    const stepId = JobStep.STEP_DATA_SOURCE.id

    const [jobResolution, setJobResolution] = useState(JobResolutions.NORMAL.id)
    const [files, setFiles] = useState([])
    const [formInfo, setFormInfo] = useState({})
    const [dataSourceType, setDataSourceType] = useState(defaultDataSourceType.type)
    const [multiUpload, setMultiUpload] = useState(defaultDataSourceType.multipleFiles)

    useEffect(() => {
        function restoreFormState() {
            if (isNewStep()) {
                setFormInfo({...formInfo, datasourceType: defaultDataSourceType.type})
                return
            }

            setDataSourceType(jobInfo.datasourceType)
            setFiles(jobInfo.datasourceFiles)
            setFormInfo(jobInfo)
        }

        restoreFormState()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        updateStepStatus()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formInfo])


    function getHelperMessage(type) {
        const {required, optional} = getDataSourceFileType(type)
        const optionalMessage = (optional.length !== 0) ? `and optionally ${optional.join(' ')}` : EMPTY_STRING
        return `Select ${required.join(' ')} ${optionalMessage}`
    }

    function getAcceptedFileTypes(type) {
        const {required, optional} = getDataSourceFileType(type)
        return [...required, ...optional]
    }

    function updateStepStatus() {
        const stepStatus = getStepStatus()
        onStepInfo(stepId, stepStatus, formInfo)
    }

    const handleChangeDatasourceType = (type) => {
        const {multipleFiles} = getDataSourceFileType(type)

        setFiles([])
        setMultiUpload(multipleFiles)
        setDataSourceType(type)

        setFormInfo({...formInfo, datasourceType: type, datasourceFiles: []})
    }

    function isNewStep() {
        return jobInfo.datasourceFiles === undefined
    }

    const handleDropMultiFile = useCallback(
        (acceptedFiles) => {

            const filesUpdated = [...files, ...acceptedFiles]
            setFiles(filesUpdated)

            setFormInfo({...formInfo, datasourceFiles: filesUpdated})

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [files]
    )

    const handleRemoveFile = (inputFile) => {
        const filesUpdated = files.filter((fileFiltered) => fileFiltered !== inputFile)
        setFiles(filesUpdated)

        setFormInfo({...formInfo, datasourceFiles: filesUpdated})
    }

    const handleRemoveAllFiles = () => {
        setFiles([])

        setFormInfo({...formInfo, datasourceFiles: []})
    }

    function getStepStatus() {
        const fileTypeInfo = getDataSourceFileType(dataSourceType)

        const stepCompleted = isFilesCompleted(files, fileTypeInfo)
        return stepCompleted ? StepStatus.COMPLETED : StepStatus.UNCOMPLETED
    }

    const handleChangeResolution = (newResolution) => {
        setJobResolution(newResolution)
        setFormInfo({...formInfo, resolution: newResolution})
    }

    return <>
        <Stack pt={2}>

            <FormControl fullWidth>
                <ToggleButtonGroup
                    value={dataSourceType}
                    exclusive
                    fullWidth
                    onChange={e => handleChangeDatasourceType(e.target.value)}
                    aria-label="DataSourceType"
                >
                    <ToggleButton value={DataSourceFileTypes.LASZ.type} aria-label="DXF" color={'primary'}>
                        {DataSourceFileTypes.LASZ.label}
                    </ToggleButton>
                    <ToggleButton value={DataSourceFileTypes.TIF.type} aria-label="Shapefile" color={'primary'}>
                        {DataSourceFileTypes.TIF.label}
                    </ToggleButton>
                </ToggleButtonGroup>
            </FormControl>

            <Upload sx={{mt: 2}}
                    helperText={getHelperMessage(dataSourceType)}
                    files={files}
                    multiple={multiUpload}
                    accept={{
                        'text/plain': getAcceptedFileTypes(dataSourceType)
                    }}
                    onDrop={handleDropMultiFile}
                    onRemove={handleRemoveFile}
                    onRemoveAll={handleRemoveAllFiles}
            />

            { jobInfo.datasourceType === DataSourceFileTypes.LASZ.type &&
                <FormControl fullWidth sx={{ mt: "40px !important" }}>
                    <Typography variant="subtitle1" sx={{mb: 1}}>
                        Sampling Resolution
                    </Typography>
                    <ToggleButtonGroup
                        id='resolution-toggle-button-group'
                        value={jobResolution}
                        exclusive
                        fullWidth
                        onChange={e => handleChangeResolution(e.target.value)}
                        aria-label="Sampling Resolution"
                    >
                        <ToggleButton value={JobResolutions.NORMAL.id} aria-label="DXF" color={'primary'}>
                            {JobResolutions.NORMAL.label}
                        </ToggleButton>
                        <ToggleButton value={JobResolutions.HIGH.id} aria-label="Shapefile" color={'primary'}>
                            {JobResolutions.HIGH.label}
                        </ToggleButton>
                    </ToggleButtonGroup>

                    <Typography variant="body2" sx={{color: 'text.secondary'}}>
                        { JobResolutions[jobResolution].description }
                    </Typography>
                </FormControl>
            }

        </Stack>
    </>
}