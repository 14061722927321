import SettingRepository from "../../repositories/SettingRepository";

export class RequestUpdateSetting {
    constructor(settingId, settingUpdated) {
        this.settingId = settingId
        this.settingUpdated = settingUpdated
    }
}

export default class UpdateSettingUseCase {
    constructor(repository = new SettingRepository()) {
        this.repository = repository
    }

    /** @return {Observable<SettingsUC>} */
    execute(request) {
        const {settingId, settingUpdated} = request
        return this.repository.updateSetting(settingId, settingUpdated)
    }
}