import {Helmet} from 'react-helmet-async';
import {Container} from '@mui/material';
import {useLocation} from "react-router-dom";
import getAppName from "../../utils/EnvUtils";
import CompanyNewEditForm from "../../sections/@dashboard/company/CompanyNewEditForm";

export default function EditCompanyPage() {

    const appName = getAppName()
    const {state} = useLocation()
    const {company} = state

    return (
        <>
            <Helmet>
                <title> User: Edit User | {appName}</title>
            </Helmet>

            <Container maxWidth={'lg'}>
                <CompanyNewEditForm isEdit company={company}/>
            </Container>
        </>
    );
}
