import {Link, Stack, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import PropTypes from "prop-types";
import {useEffect, useMemo, useState} from "react";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import VerifyCodeUseCase, {RequestVerifyCode} from "../../../usecases/login/VerifyCodeUseCase";
import SendCodeUseCase, {RequestSendCode} from "../../../usecases/login/SendCodeUseCase";
import {AlertError} from "../../../components/utils";
import UseCase from "../../../usecases/UseCase";
import FormProvider, {RHFTextField} from "../../../components/hook-form";
import GetUserByEmailUseCase, {RequestGetUserByEmail} from "../../../usecases/user/GetUserByEmailUseCase";
import {RequestResetPassword, ResetPasswordUseCase} from "../../../usecases/login/ResetPasswordUseCase";
import {isEmpty, isNonEmpty} from "../../../utils/StringUtils";

VerifyCodeForm.propTypes = {
    email: PropTypes.string,
    code: PropTypes.string,
    sendCode: PropTypes.bool
}

export default function VerifyCodeForm({email, code, sendCode}) {

    const navigate = useNavigate()
    const {enqueueSnackbar} = useSnackbar()

    const verifyCode = new VerifyCodeUseCase()
    const sendCodeUseCase = new SendCodeUseCase()
    const getUserByEmail = new GetUserByEmailUseCase()
    const resetPassword = new ResetPasswordUseCase()

    const [errorMessage, setErrorMessage] = useState('')
    const [verifyInProgress, setVerifyInProgress] = useState(false)

    console.log(email, code, sendCode)

    const isEmailKnown = () => isNonEmpty(email)

    const redirectsToAuth = () => {
        navigate('/auth', {replace: true})
    }

    useEffect(() => {
        if (code != null) return

        if (!email || !sendCode) return

        const request = new RequestSendCode(email)
        sendCodeUseCase.execute(request).subscribe({
            error: (err) => onFailure(err)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sendCode])

    const onVerifyCode = (formInfo) => {
        setVerifyInProgress(true)
        console.log(formInfo)

        const request = new RequestGetUserByEmail(formInfo.emailForm)
        UseCase.execute(
            getUserByEmail, request,
            (users) => onValidateUser(users, formInfo),
            redirectsToAuth
        )
    }

    const onValidateUser = (users, formInfo) => {
        console.log('email', formInfo)

        const code = formInfo.codeForm
        const existUser = users.length !== 0
        if (!existUser) {
            redirectsToAuth()
            return
        }

        const {termsAgreedAt} = users[0]
        const needResetPassword = isEmpty(termsAgreedAt)

        const request = new RequestVerifyCode(formInfo.emailForm, code)
        UseCase.execute(verifyCode, request, () => onCodeVerified(formInfo, needResetPassword), onFailure)
    }

    const onCodeVerified = (formInfo, needResetPassword) => {

        if (needResetPassword) {
            const request = new RequestResetPassword(formInfo.emailForm)
            UseCase.execute(resetPassword, request,
                () => {
                    const userInfo = {
                        "email": formInfo.emailForm,
                        "needTerms": true
                    }

                    navigate('/auth/new-password', {
                        replace: true,
                        state: {userInfo}
                    })
                },
                onFailure)
        }

        console.log('redirects to dashboard')
        // Hub listener at app level will handle user sign in
    }

    const onFailure = (error) => {
        setErrorMessage(error.message)
        setVerifyInProgress(false)
    }

    const defaultValues = useMemo(() => ({
        codeForm: code,
        emailForm: email

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [code])

    const formSchema = Yup.object().shape({
        codeForm: Yup.string().required('Code is required'),
        emailForm: Yup.string().required('Email is required'),
    })

    const methods = useForm({
        resolver: yupResolver(formSchema),
        defaultValues,
    })

    const {handleSubmit} = methods

    const resendCode = () => {
        const userEmail =  methods.getValues().emailForm
        const request = new RequestSendCode(userEmail)
        sendCodeUseCase.execute(request).subscribe({
            next: () => enqueueSnackbar(`Code was sent to ${userEmail}`, {variant: 'info'}),
            error: (err) => enqueueSnackbar(err, {variant: 'error'})
        })
    }

    function getVerifyMessage(userEmail) {
        if (isNonEmpty(userEmail)) {
            return `A code was sent to ${userEmail}.`
        }

        return `A code was sent to your email address.`
    }

    return (
        <>
            <Typography variant="h4" gutterBottom>
                Verify Code
            </Typography>

            <Typography variant="body2" sx={{mb: 2}}>
                { getVerifyMessage(methods.getValues().emailForm) }
                <Link
                    sx={{ml: 0.5}}
                    component="button"
                    variant="body2"
                    onClick={resendCode}
                >
                    Resend code
                </Link>
            </Typography>

            <FormProvider methods={methods} onSubmit={handleSubmit(onVerifyCode)}>

                <Stack spacing={3} paddingBottom={2}>

                    <AlertError message={errorMessage} setMessage={setErrorMessage}/>

                    <RHFTextField name="emailForm" label="Email" required disabled={isEmailKnown()}/>

                    <RHFTextField name="codeForm" label="Code" required/>
                </Stack>

                <LoadingButton
                    fullWidth size="large" type="submit" variant="contained"
                    loading={verifyInProgress}
                >
                    Verify Code
                </LoadingButton>

            </FormProvider>
        </>
    );
}