import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {IconButton, InputAdornment, Link, Stack, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useEffect, useState} from "react";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import Iconify from '../../../components/iconify';
import {AlertError} from "../../../components/utils";
import {useAuthContext} from "../../../auth/AuthProvider";
import FormProvider, {RHFTextField} from "../../../components/hook-form";

export default function LoginForm() {

    const {login, errorFromAuth, setErrorFromAuth} = useAuthContext()

    const [showPassword, setShowPassword] = useState(false)
    const [loginInProgress, setLoginInProgress] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const navigate = useNavigate()

    useEffect(()=> {
        if (!errorFromAuth) return

        setLoginInProgress(false)
        showAlertError(errorFromAuth)
        setErrorFromAuth(null)
    }, [errorFromAuth, setErrorFromAuth])

    const onSignIn = (formInfo) => {
        setLoginInProgress(true)

        login(formInfo.email, formInfo.password).subscribe({
            next: userProfile => onLoginSuccess(userProfile),
            error: err => onLoginFailure(err)
        })
    }

    const onLoginSuccess = (user) => {
        console.log('signing in ', user)
        // Hub listener at app level will handle user sign in
    }

    const onLoginFailure = (error) => {
        if (isUserNotVerified(error)) return

        showAlertError(error.message)
        setLoginInProgress(false)
    }

    const formSchema = Yup.object().shape({
        email: Yup.string().required('Email is required').email('Email must be a valid email address'),
        password: Yup.string().required('Password required')
    })

    const methods = useForm({resolver: yupResolver(formSchema)})
    const {watch, handleSubmit} = methods
    const values = watch()

    function isUserNotVerified(error) {
        if (error.code !== 'UserNotConfirmedException') return false

        const userInfo = {
            email: values.email,
            sendCode: true
        }

        navigate('/auth/verify', {
            replace: true,
            state: {userInfo}
        })

        return true
    }

    function showAlertError(message) {
        setErrorMessage(message)
    }

    return (
        <>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSignIn)}>
                <Stack spacing={3}>

                    <AlertError message={errorMessage} setMessage={setErrorMessage}/>

                    <RHFTextField name="email" label="Email address" required/>
                    <RHFTextField name="password" label="Password" required
                                  type={showPassword ? 'text' : 'password'}
                                  InputProps={{
                                      endAdornment: (
                                          <InputAdornment position="end">
                                              <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                  <Iconify
                                                      icon={showPassword ? 'mdi:eye-outline' : 'mdi:eye-off-outline'}/>
                                              </IconButton>
                                          </InputAdornment>
                                      ),
                                  }}
                    />
                </Stack>

                <Typography variant="body2" sx={{mt: 1, mb: 2}}>
                    <Link component={RouterLink} variant="subtitle2"
                          to={'/auth/reset-password'}
                    >
                        Forgot password?
                    </Link>
                </Typography>

                <LoadingButton
                    fullWidth size="large" type="submit" variant="contained"
                    loading={loginInProgress}
                >
                    Login
                </LoadingButton>
            </FormProvider>
        </>
    );
}