import {Helmet} from 'react-helmet-async';
import {useEffect, useState} from 'react';
import {paramCase} from 'change-case';
import {useNavigate} from 'react-router-dom';
import {
    Button,
    Card,
    Container,
    Divider,
    IconButton,
    Tab,
    Table,
    TableBody,
    TableContainer,
    Tabs,
    Tooltip,
} from '@mui/material';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import ConfirmDialog from '../../components/confirm-dialog';
import {
    emptyRows,
    getComparator,
    TableEmptyRows,
    TableNoData,
    TablePaginationCustom,
    TableSelectedAction,
    useTable,
} from '../../components/table';
import {PATH_DASHBOARD} from "../../routes/paths";
import getAppName from "../../utils/EnvUtils";
import CompanyTableRow from "../../sections/@dashboard/company/list/CompanyTableRow";
import CompanyTableToolbar from "../../sections/@dashboard/company/list/CompanyTableToolBar";
import GetCompaniesUseCase from "../../usecases/company/GetCompaniesUseCase";
import RemoveCompanyUseCase, {RequestRemoveCompany} from "../../usecases/company/RemoveCompanyUseCase";
import UseCase from "../../usecases/UseCase";
import CompanyTableHead from "../../sections/@dashboard/company/list/CompanyTableHead";
import {containsInFields} from "../../utils/SearchUtils";

const STATUS_OPTIONS = ['all', 'enabled', 'trial', 'disabled']
const ROLE_OPTIONS = ['Name', 'Email', 'Country']
const TABLE_HEAD = [
    {id: 'name', label: 'Name', align: 'left'},
    {id: 'billingEmail', label: 'Email', align: 'left'},
    {id: 'billingRate', label: 'Bill Rate', align: 'left'},
    {id: 'country', label: 'Country', align: 'left'},
    {id: 'createdAt', label: 'Member since', align: 'left'},
    {id: 'status', label: 'Status', align: 'center'},
    {id: ''},
]

export default function CompanyListPage() {

    const fetchCompanies = new GetCompaniesUseCase()
    const removeCompany = new RemoveCompanyUseCase()

    const appName = getAppName()
    const navigate = useNavigate()

    const [tableData, setTableData] = useState([])
    const [openConfirm, setOpenConfirm] = useState(false)
    const [filterInput, setFilterInput] = useState('')
    const [filterRole, setFilterRole] = useState('Name')
    const [filterStatus, setFilterStatus] = useState('all')

    const {
        dense, page, order, orderBy, rowsPerPage, setPage,
        selected, setSelected, onSelectAllRows,
        onSort, onChangeDense, onChangePage, onChangeRowsPerPage,
    } = useTable({defaultOrderBy: 'createdAt', defaultOrder: 'desc'})

    const dataFiltered = applyFilter({
        inputData: tableData,
        comparator: getComparator(order, orderBy),
        filterInput,
        filterRole,
        filterStatus
    })

    const dataInPage = dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    const denseHeight = dense ? 52 : 72
    const isFiltered = filterInput !== '' || filterRole !== 'Name' || filterStatus !== 'all'

    const isNotFound = (!dataFiltered.length && !!filterInput) ||
        (!dataFiltered.length && !!filterRole) ||
        (!dataFiltered.length && !!filterStatus)

    useEffect(() => {
        UseCase.execute(fetchCompanies, {}, onCompaniesFetched, onFailure)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleOpenConfirm = () => setOpenConfirm(true)
    const handleCloseConfirm = () => setOpenConfirm(false)

    const handleFilterStatus = (event, newValue) => {
        setPage(0)
        setFilterStatus(newValue)
    }

    const handleFilterInput = (event) => {
        setPage(0)
        setFilterInput(event.target.value)
    }

    const handleFilterRole = (event) => {
        setPage(0)
        setFilterRole(event.target.value)
    }

    const handleResetFilter = () => {
        setFilterInput('')
        setFilterRole('Name')
        setFilterStatus('all')
    }

    const handleDeleteRow = (id) => {
        const request = new RequestRemoveCompany(id)
        UseCase.execute(removeCompany, request, onCompanyRemoved, onFailure)
    }

    const onCompaniesFetched = (companies) => {
        setTableData(companies)
    }

    const onCompanyRemoved = (company) => {
        const deleteRow = tableData.filter((row) => row.id !== company.id)
        setSelected([])
        setTableData(deleteRow)

        if (page > 0) {
            if (dataInPage.length < 2) {
                setPage(page - 1)
            }
        }
    }

    const onFailure = (error) => {
        console.log(error)
    }

    const handleDeleteRows = (selectedRows) => {
        const deleteRows = tableData.filter((row) => !selectedRows.includes(row.id))
        setSelected([])
        setTableData(deleteRows)

        if (page > 0) {
            if (selectedRows.length === dataInPage.length) {
                setPage(page - 1)
            } else if (selectedRows.length === dataFiltered.length) {
                setPage(0)
            } else if (selectedRows.length > dataInPage.length) {
                const newPage = Math.ceil((tableData.length - selectedRows.length) / rowsPerPage) - 1
                setPage(newPage)
            }
        }
    }

    function navigateToEditForm(company) {
        navigate(
            PATH_DASHBOARD.companies.edit(paramCase(company.name)), {
                state: {company}
            }
        )
    }

    return (
        <>
            <Helmet>
                <title> Companies | {appName}</title>
            </Helmet>

            <Container maxWidth={'lg'}>

                <Card>
                    <Tabs
                        value={filterStatus}
                        onChange={handleFilterStatus}
                        sx={{
                            px: 2,
                            bgcolor: 'background.neutral',
                        }}
                    >
                        {
                            STATUS_OPTIONS.map(tab =>
                                <Tab key={tab} label={tab} value={tab}/>
                            )
                        }
                    </Tabs>

                    <Divider/>

                    <CompanyTableToolbar
                        isFiltered={isFiltered}
                        filterInput={filterInput}
                        filterRole={filterRole}
                        optionsRole={ROLE_OPTIONS}
                        onFilterInput={handleFilterInput}
                        onFilterRole={handleFilterRole}
                        onResetFilter={handleResetFilter}
                    />


                    <TableContainer sx={{position: 'relative', overflow: 'unset'}}>
                        <TableSelectedAction
                            dense={dense}
                            numSelected={selected.length}
                            rowCount={tableData.length}
                            onSelectAllRows={(checked) =>
                                onSelectAllRows(
                                    checked,
                                    tableData.map((row) => row.id)
                                )
                            }
                            action={
                                <Tooltip title="Delete">
                                    <IconButton color="primary" onClick={handleOpenConfirm}>
                                        <Iconify icon="eva:trash-2-outline"/>
                                    </IconButton>
                                </Tooltip>
                            }
                        />

                        <Scrollbar>
                            <Table size={dense ? 'small' : 'medium'} sx={{minWidth: 800}}>
                                <CompanyTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={tableData.length}
                                    numSelected={selected.length}
                                    onSort={onSort}
                                    onSelectAllRows={(checked) =>
                                        onSelectAllRows(
                                            checked,
                                            tableData.map((row) => row.id)
                                        )
                                    }
                                />

                                <TableBody>
                                    {dataFiltered
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((company) => (
                                            <CompanyTableRow
                                                key={company.id}
                                                data={company}
                                                selected={selected.includes(company.id)}
                                                onDeleteRow={() => handleDeleteRow(company.id)}
                                                onEditRow={() => navigateToEditForm(company)}
                                            />
                                        ))}


                                    <TableEmptyRows
                                        height={denseHeight}
                                        emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                                    />

                                    <TableNoData isNotFound={isNotFound}/>
                                </TableBody>
                            </Table>
                        </Scrollbar>
                    </TableContainer>

                    <TablePaginationCustom
                        count={dataFiltered.length}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        onPageChange={onChangePage}
                        onRowsPerPageChange={onChangeRowsPerPage}
                        //
                        dense={dense}
                        onChangeDense={onChangeDense}
                    />
                </Card>
            </Container>

            <ConfirmDialog
                open={openConfirm}
                onClose={handleCloseConfirm}
                title="Delete"
                content={
                    <>
                        Are you sure want to delete <strong> {selected.length} </strong> items?
                    </>
                }
                action={
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            handleDeleteRows(selected)
                            handleCloseConfirm()
                        }}
                    >
                        Delete
                    </Button>
                }
            />
        </>
    )
}

// ----------------------------------------------------------------------

function applyFilter({inputData, comparator, filterInput, filterStatus}) {
    const stabilizedThis = inputData.map((el, index) => [el, index])

    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })

    inputData = stabilizedThis.map((el) => el[0])

    if (filterStatus !== 'all') {
        inputData = containsInFields(inputData, ['status'], filterStatus)
    }

    const fields = ['name', 'billingEmail', 'country']
    inputData = containsInFields(inputData, fields, filterInput)

    return inputData
}