import {Container} from '@mui/material';
import {useLocation} from "react-router-dom";
import UserNewEditForm from '../../sections/@dashboard/user/UserNewEditForm';

export default function CreateUserPage() {

    const {state} = useLocation()
    const {companies} = state

    return (
        <>
            <Container maxWidth={'lg'}>
                <UserNewEditForm companies={companies}/>
            </Container>
        </>
    );
}
