import React, {useState} from 'react';
import {Outlet} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import {Alert, Box} from "@mui/material"
import Header from './header';
import Nav from './nav';
import Background from "../../assets/illustrations/webappbackground2.jpg"
import {getNavConfig} from "./nav/config";
import {JobUploadMonitor, UploadStatusContext} from "../../context-providers/UploadStatusProvider";
import {useAuthContext} from "../../auth/AuthProvider";

const APP_BAR_MOBILE = 72
const APP_BAR_DESKTOP = 96

const StyledRoot = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden'
})

const Main = styled('div')(({theme}) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
        paddingTop: APP_BAR_DESKTOP + 24,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    backgroundImage: `url(${Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: '100%',
    backgroundPosition: "left bottom",
    backgroundSize: "100% 100%",
    backgroundAttachment: "fixed",
    height: "calc(100vh)"
}))

export default function DashboardLayout() {
    const [open, setOpen] = useState(false);
    const {user} = useAuthContext()
    const navConfig = user && getNavConfig(user.role)

    const [onUpload, setOnUpload] = useState(false)
    const [uploadStatus, setUploadStatus] = useState({})

    const [filesUploading, setFilesUploading] = useState(new Map())

    return (
        <JobUploadMonitor.Provider value={{filesUploading, setFilesUploading}}>
            <UploadStatusContext.Provider value={{onUpload, setOnUpload, uploadStatus, setUploadStatus}}>
                <StyledRoot>
                    <Header navConfig={navConfig} onOpenNav={() => setOpen(true)}/>
                    <Nav navConfig={navConfig} openNav={open} onCloseNav={() => setOpen(false)}/>
                    <Main>
                        <Box pb={2} mx={2}>
                            {/* full width banner to global app alerts */}
                            {onUpload && <Alert severity={'info'} variant="filled">
                                Upload in progress. Job will start automatically after complete upload
                            </Alert>}
                        </Box>

                        <Outlet/>
                    </Main>
                </StyledRoot>
            </ UploadStatusContext.Provider>
        </JobUploadMonitor.Provider>
    )
}