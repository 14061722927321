/*
    Some notes from Ash on old implementation and potential places to look for insights:

    Main API config is here:
    https://github.com/DataSightUSA/lidar_breakline_api/blob/main/openapi/breaklines.yml

    Need to enable CORS. Per the docs, should add OPTIONS response:
    https://docs.aws.amazon.com/apigateway/latest/developerguide/enable-cors-for-resource-using-swagger-importer-tool.html

    The old app actually forwards the request from its own Amplify back end, the function's defined here:
    amplify/backend/function/jobApiFunction/src/app.js

    This is the Amplify API on the old app which calls a lambda to start the job:
    https://us-east-2.console.aws.amazon.com/lambda/home?region=us-east-2#/functions/jobApiFunction-develop?tab=code

    Seems like this is the public URL for the API on PROD:
    https://qm626dn1p4.execute-api.us-east-2.amazonaws.com/test/

    and starting a job is a POST to:
    https://qm626dn1p4.execute-api.us-east-2.amazonaws.com/breaklines/

    Maybe this is an internal URL? This is set as an environment variable in the Lambda:
    JOBS_API_URL = http://internal-breaklines-api-us-east-2-899891262.us-east-2.elb.amazonaws.com:443/breaklines

    Getting CORS errors. Tried enabling CORS on both APIs here:
    https://us-east-2.console.aws.amazon.com/apigateway/main/apis?region=us-east-2
    ... but didn't help. It errored when enabling CORS for the OPTIONS method so maybe that's causing the problem

    This returns a 403 forbidden:
    curl -v -X OPTIONS https://qm626dn1p4.execute-api.us-east-2.amazonaws.com/breaklines
 */

import {API} from "aws-amplify";

// TODO: Ash: Get this from config, e.g. process.env
// const JOBS_API_URL = 'https://qm626dn1p4.execute-api.us-east-2.amazonaws.com/new_user_pool'
// const JOBS_API_URL = 'https://6rg13q2inf.execute-api.us-east-2.amazonaws.com'
// const JOBS_API_URL = 'https://06jp2mpjbi.execute-api.us-east-2.amazonaws.com' // Probably Dev
// const JOBS_API_URL = 'https://yljr6yvf2g.execute-api.us-east-2.amazonaws.com/develop' // New Breaklines "appApi" Amplify/Lambda API for web app
//
// async function getHeaders() {
//
//     const authorization = (await Auth.currentSession()).getAccessToken().getJwtToken()
//     return {
//         "Content-Type": "application/json",
//         'Access-Control-Allow-Origin': '*',
//         'Access-Control-Allow-Headers': '*',
//         Authorization: authorization,
//     }
// }

export async function validateBoundary(job) {

    console.log('BreaklinesApi: Sending boundary validation request to breaklines API.')

    const apiName = 'appApi';
    const path = `/job/${job.id}/validate`;
    const config = { response: true };

    let response = null
    try {
        response = await API.get(apiName, path, config)
    } catch (error) {
        console.error('BreaklinesApi: Failed to GET boundary validation from breaklines API', error)
        throw (error)
    }

    if (!response?.status === 200) {
        throw (new Error(`Breaklines API: Got an error response from GET to breaklines API, status ${response?.statusText}`))
    }

    console.log('BreaklinesApi: Success', response)
    return response.data
}

export async function runJob(job) {

    const userCognitoId = job.userProfileId
    const body = { ...job, userCognitoId }

    const apiName = 'appApi';
    const path = `/job/${job.id}/run`;
    const config = { response: true, body };

    let response = null
    try {
        response = await API.post(apiName, path, config)
    }
    catch (error) {
        console.error('BreaklinesApi: Failed to POST job to breaklines API', error)
        throw (error)
    }

    if (!response.status === 200) {
        throw (new Error(`Breaklines API: Got an error response from POST to breaklines API, status ${response?.status}`))
    }

    console.log('BreaklinesApi: Success', response)
    return response
}
