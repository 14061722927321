import PropTypes from "prop-types";
import {Box, Card, Divider, FormControl, Grid, IconButton, Stack, Typography} from "@mui/material";
import {LoadingButton, ToggleButton, ToggleButtonGroup} from "@mui/lab";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import {forkJoin, from} from "rxjs";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import {Storage} from "aws-amplify";
import FormProvider, {RHFSelect, RHFTextField} from "../../../components/hook-form";
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";
import {PATH_DASHBOARD} from "../../../routes/paths";
import {BoundaryFileTypes, getBoundaryFileType} from "./jobstep/StepBoundary";
import {DataSourceFileTypes, getDataSourceFileType} from "./jobstep/StepDataSource";
import {BreaklinesFileTypes, getBreaklinesFileType} from "./jobstep/StepCriticalBreaklines";
import UploadBox from "../../../components/upload/UploadBox";
import UpdateJobUseCase, {RequestUpdateJob} from "../../../usecases/job/UpdateJobUseCase";
import UseCase from "../../../usecases/UseCase";
import {getBasePathForJobFile, JobFileType, JobResolutions} from "../../../utils/JobUtils";
import GetCompanyUseCase, {RequestGetCompany} from "../../../usecases/company/GetCompanyUseCase";
import GetUserByIdUseCase, {RequestGetUserById} from "../../../usecases/user/GetUserByIdUseCase";
import {DEFAULT_BILLING_RATE, EMPTY_STRING, UNKNOWN} from "../../../utils/constants";
import {UploadStatusContext} from "../../../context-providers/UploadStatusProvider";
import FileStorage from "../../../services/FileStorage";
import {FileUploadPreviewModel} from "../../../components/upload/preview/FilePreviewUploadModel";
import {FilesPreviewUpload} from "../../../components/upload/preview/FilesPreviewUpload";
import Iconify from "../../../components/iconify";
import GetUsersUseCase, {RequestGetUsers} from "../../../usecases/user/GetUsersUseCase";
import {useAuthContext} from "../../../auth/AuthProvider";
import GetCompaniesUseCase from "../../../usecases/company/GetCompaniesUseCase";

const JOB_STATUS_OPTIONS = ['pending', 'running', 'completed', 'failed']

JobEditForm.propTypes = {
    jobToEdit: PropTypes.object
}

export default function JobEditForm({jobToEdit}) {

    const getCompany = new GetCompanyUseCase()
    const getUserById = new GetUserByIdUseCase()
    const updateJob = new UpdateJobUseCase()
    const fileStorage = new FileStorage()
    const getUsers = new GetUsersUseCase()
    const getCompanies = new GetCompaniesUseCase()

    const {user: userProfile} = useAuthContext()
    const navigate = useNavigate()
    const {enqueueSnackbar} = useSnackbar()

    const [updatingJob, setUpdatingJob] = useState(false)

    const defaultBoundaryType = BoundaryFileTypes.DXF
    const defaultDataSourceType = DataSourceFileTypes.LASZ
    const defaultBreaklinesType = BreaklinesFileTypes.DXF

    const [boundaryTypeUpdated, setBoundaryTypeUpdated] = useState(defaultBoundaryType.type)
    const [dataSourceTypeUpdated, setDataSourceTypeUpdated] = useState(defaultDataSourceType.type)
    const [breaklinesTypeUpdated, setBreaklinesTypeUpdated] = useState(defaultBreaklinesType.type)
    const [jobResolutionUpdated, setJobResolutionUpdated] = useState(jobToEdit.resolution)

    const [restoreSection, setRestoreSection] = useState({})

    const [boundaryFilesPreviewUpload, setBoundaryFilesPreviewUpload] = useState(getFilesPreviewByName(jobToEdit.aoi))
    const [datasourceFilesPreviewUpload, setDatasourceFilesPreviewUpload] = useState(getFilesPreviewByName(jobToEdit.las))
    const [breaklinesFilesPreviewUpload, setBreaklinesFilesPreviewUpload] = useState(getFilesPreviewByName(jobToEdit.customBreaklines))

    const {uploadStatus, setUploadStatus, setOnUpload} = useContext(UploadStatusContext)

    const [company, setCompany] = useState()
    const [user, setUser] = useState()
    const [users, setUsers] = useState([])
    const [companies, setCompanies] = useState([])

    function getFilesPreviewByName(files) {
        return files.map(file => FileUploadPreviewModel.fromFileName(file))
    }

    function getFilesNames(filesPreview) {
        return filesPreview.map(filesPreview => filesPreview.name)
    }

    useEffect(() => {
        getCompanyInfo(jobToEdit.companyId)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const request = new RequestGetUserById(jobToEdit.userProfileId)
        getUserById.execute(request).subscribe({
            next: userFetched => setUser(userFetched),
            error: err => console.log(err)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getCompanies.execute().subscribe({
            next: companies => setCompanies(companies),
            error: err => console.log(err)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const request = new RequestGetUsers(userProfile)
        getUsers.execute(request).subscribe({
            next: usersFetched => setUsers(usersFetched),
            error: err => console.log(err)
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function getJobToUpdate(jobInfoUpdated) {
        return {
            ...jobToEdit,
            ...jobInfoUpdated,
            type: boundaryTypeUpdated,
            aoi: getFilesNames(boundaryFilesPreviewUpload),
            las: getFilesNames(datasourceFilesPreviewUpload),
            customBreaklines: getFilesNames(breaklinesFilesPreviewUpload),
            resolution: jobResolutionUpdated,
            userProfileId: user.id,
            companyId: user.companyId,
        }
    }

    const formSchema = Yup.object().shape({
        author: Yup.string().notRequired(),
        name: Yup.string().notRequired(),
        billingNumber: Yup.string().notRequired(),
        status: Yup.string().notRequired(),
        downloadUrl: Yup.string().notRequired(),
        billingRate: Yup.number().notRequired(),
        acreage: Yup.number().notRequired(),
        errorMessage: Yup.string().notRequired()
    })

    function getBillingRate() {
        if (!jobToEdit?.billingRate) {
            return DEFAULT_BILLING_RATE
        }

        return jobToEdit?.billingRate || DEFAULT_BILLING_RATE
    }

    const defaultValues = useMemo(
        () => ({
            author: jobToEdit?.userProfileId || EMPTY_STRING,
            name: jobToEdit?.name || EMPTY_STRING,
            billingNumber: jobToEdit?.billingNumber || EMPTY_STRING,
            status: jobToEdit?.status || EMPTY_STRING,
            downloadUrl: jobToEdit?.downloadUrl || EMPTY_STRING,
            billingRate: getBillingRate(),
            acreage: jobToEdit?.acreage || 0,
            errorMessage: jobToEdit?.errorMessage || EMPTY_STRING
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [jobToEdit]
    )

    const methods = useForm({
        resolver: yupResolver(formSchema),
        defaultValues
    })

    const {watch, handleSubmit} = methods
    const values = watch()

    useEffect(() => {
        onAuthorChanged(values.author)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.author])

    const onAuthorChanged = (userId) => {
        if (!userId) return

        const newAuthor = users.find(user => user.id === userId)
        if (!newAuthor) return

        setUser(newAuthor)
        getCompanyInfo(newAuthor.companyId)
    }

    const onUpdateJob = (formInfo) => {
        setUpdatingJob(true)
        const jobToUpdate = getJobToUpdate(formInfo)

        const request = new RequestUpdateJob(jobToUpdate.id, jobToUpdate)
        UseCase.execute(updateJob, request, onSuccessJobUpdated,
            (error) => {
                console.log('unable to update job', error)
                setUpdatingJob(false)
            })
    }

    const onSuccessJobUpdated = (jobUpdated) => {
        console.log("job updated!", jobUpdated)
        removePendingFiles()
        uploadFilesChanged()
    }

    const handleChangeBoundaryType = (type) => {
        setBoundaryTypeUpdated(type)
    }

    const handleChangeDatasourceType = (type) => {
        setDataSourceTypeUpdated(type)
    }

    const handleChangeBreaklinesType = (type) => {
        setBreaklinesTypeUpdated(type)
    }

    function getCompanyInfo(companyId) {
        const request = new RequestGetCompany(companyId)
        getCompany.execute(request).subscribe({
            next: companyFetched => setCompany(companyFetched),
            error: err => console.log(err)
        })
    }

    function getAuthorInfo(user) {
        const authorCompany = companies.find(company => company.id === user.companyId)
        const companyName = authorCompany === undefined ? UNKNOWN : authorCompany.name

        return `${user.fullName()} | ${companyName}`
    }

    function uploadFilesChanged() {
        const basePath = getBasePathForJobFile(jobToEdit.userProfileId, jobToEdit.id)
        const poolObs = []

        // 1. upload boundary files
        const boundaryFilesToUpload = boundaryFilesPreviewUpload.filter(file => file.blob !== null)
        boundaryFilesToUpload.forEach(file => {
            poolObs.push(fileStorage.saveFile(
                    basePath + file.name,
                    file.blob,
                    JobFileType.BOUNDARY,
                    file.name,
                    onUploadProgressUpdate
                )
            )
        })

        // 2. upload datasources files
        const datasourcesFilesToUpload = datasourceFilesPreviewUpload.filter(file => file.blob !== null)
        datasourcesFilesToUpload.forEach(file => {
            poolObs.push(fileStorage.saveFile(
                    basePath + file.name,
                    file.blob,
                    JobFileType.DATASOURCE,
                    file.name,
                    onUploadProgressUpdate
                )
            )
        })

        // 3. upload breaklines files
        const breaklinesFilesToUpload = breaklinesFilesPreviewUpload.filter(file => file.blob !== null)
        breaklinesFilesToUpload.forEach(file => {
            poolObs.push(fileStorage.saveFile(
                    basePath + file.name,
                    file.blob,
                    JobFileType.BREAKLINES,
                    file.name,
                    onUploadProgressUpdate
                )
            )
        })

        setOnUpload(poolObs.length !== 0)
        forkJoin(poolObs).subscribe({
            next: filesUploaded => {
                console.log('files uploaded', filesUploaded)
                setOnUpload(false)
                setUpdatingJob(false)

                navigate(PATH_DASHBOARD.jobs.list, {replace: true})
                enqueueSnackbar('Job was updated!', {variant: 'success'});
            }
        })

        if (poolObs.length === 0) {
            navigate(PATH_DASHBOARD.jobs.list, {replace: true})
            enqueueSnackbar('Job was updated!', {variant: 'success'});
        }
    }

    function removePendingFiles() {
        const basePath = getBasePathForJobFile(jobToEdit.userProfileId, jobToEdit.id)

        const originalFiles = [...jobToEdit.aoi, ...jobToEdit.las, ...jobToEdit.customBreaklines]
        const newFiles = [...boundaryFilesPreviewUpload, ...datasourceFilesPreviewUpload, ...breaklinesFilesPreviewUpload]

        const filesToRemove = originalFiles.filter(originalFile => !newFiles.find(newFile => newFile.name === originalFile))

        filesToRemove.forEach(fileName => {
            from(fileStorage.removeFile(basePath + fileName)).subscribe({
                next: fileRemoved => console.log('file removed', fileRemoved),
                error: err => console.log('could not remove file', err)
            })
        })

        setUpdatingJob(false)
    }

    const handleChangeResolution = (resolutionUpdated) => {
        setJobResolutionUpdated(resolutionUpdated)
    }

    useEffect(() => {
        const {fileType, fileName, loaded, total} = uploadStatus
        const newProgressValue = Math.round(loaded * 100 / total)

        if (newProgressValue % 10 === 0) {
            console.log(fileName, "->", newProgressValue)
        }
        onProgressUpdated(fileType, fileName, newProgressValue)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadStatus])

    function getAcceptedBoundaryFileTypes(type) {
        const {required, optional} = getBoundaryFileType(type)
        return [...required, ...optional]
    }

    function getAcceptedDataSourceFileTypes(type) {
        const {required, optional} = getDataSourceFileType(type)
        return [...required, ...optional]
    }

    function getAcceptedBreaklinesFileTypes(type) {
        const {required, optional} = getBreaklinesFileType(type)
        return [...required, ...optional]
    }

    function onProgressUpdated(fileType, fileName, progressUpload) {
        if (fileType === JobFileType.BOUNDARY) {
            const updated = updateFileProgress(boundaryFilesPreviewUpload, fileName, progressUpload)
            setBoundaryFilesPreviewUpload([...updated])
            return
        }

        if (fileType === JobFileType.DATASOURCE) {
            const updated = updateFileProgress(datasourceFilesPreviewUpload, fileName, progressUpload)
            setDatasourceFilesPreviewUpload([...updated])
            return
        }

        if (fileType === JobFileType.BREAKLINES) {
            const updated = updateFileProgress(breaklinesFilesPreviewUpload, fileName, progressUpload)
            setBreaklinesFilesPreviewUpload([...updated])
        }
    }

    function updateFileProgress(files, fileName, progressUpload) {
        return files.map(file => {
            if (file.name === fileName) {
                file.progressValue = progressUpload
            }

            return file
        })
    }

    const onDownloadFile = (fileName) => {
        console.log(`downloading ${fileName}`)

        const {userProfileId, id} = jobToEdit
        const filePath = getBasePathForJobFile(userProfileId, id) + fileName

        from(Storage.get(filePath)).subscribe({
            next: result => {
                const link = document.createElement("a")
                link.download = fileName
                link.href = result
                link.click()
            },
            error: err => console.log(err)
        })
    }

    function onNewFilesToUpload(files, fileType) {
        console.log("new files to upload", fileType, files)

        if (fileType === JobFileType.BOUNDARY) {
            const newFilesPreview = files.map(file => FileUploadPreviewModel.fromFile(file))
            const filesPreviewUpdated = boundaryFilesPreviewUpload.concat(newFilesPreview)
            setBoundaryFilesPreviewUpload(filesPreviewUpdated)
            return
        }

        if (fileType === JobFileType.DATASOURCE) {
            const newFilesPreview = files.map(file => FileUploadPreviewModel.fromFile(file))
            const filesPreviewUpdated = datasourceFilesPreviewUpload.concat(newFilesPreview)
            setDatasourceFilesPreviewUpload(filesPreviewUpdated)
            return
        }

        if (fileType === JobFileType.BREAKLINES) {
            const newFilesPreview = files.map(file => FileUploadPreviewModel.fromFile(file))
            const filesPreviewUpdated = breaklinesFilesPreviewUpload.concat(newFilesPreview)
            setBreaklinesFilesPreviewUpload(filesPreviewUpdated)
        }

    }

    function onRemoveFile(fileName, fileType) {
        if (fileType === JobFileType.BOUNDARY) {
            const filesPreviewUpdated = boundaryFilesPreviewUpload.filter(filePreview => filePreview.name !== fileName)
            setBoundaryFilesPreviewUpload(filesPreviewUpdated)
            restoreSection[JobFileType.BOUNDARY] = true
            setRestoreSection(restoreSection)
        }

        if (fileType === JobFileType.DATASOURCE) {
            const filesPreviewUpdated = datasourceFilesPreviewUpload.filter(filePreview => filePreview.name !== fileName)
            setDatasourceFilesPreviewUpload(filesPreviewUpdated)
            restoreSection[JobFileType.DATASOURCE] = true
            setRestoreSection(restoreSection)
        }

        if (fileType === JobFileType.BREAKLINES) {
            const filesPreviewUpdated = breaklinesFilesPreviewUpload.filter(filePreview => filePreview.name !== fileName)
            setBreaklinesFilesPreviewUpload(filesPreviewUpdated)
            restoreSection[JobFileType.BREAKLINES] = true
            setRestoreSection(restoreSection)
        }
    }

    function restoreFiles(fileType) {
        if (fileType === JobFileType.BOUNDARY) {
            const filesPreviewUpdated = getFilesPreviewByName(jobToEdit.aoi)
            setBoundaryFilesPreviewUpload(filesPreviewUpdated)
            restoreSection[JobFileType.BOUNDARY] = false
            setRestoreSection(restoreSection)
        }

        if (fileType === JobFileType.DATASOURCE) {
            const filesPreviewUpdated = getFilesPreviewByName(jobToEdit.las)
            setDatasourceFilesPreviewUpload(filesPreviewUpdated)
            restoreSection[JobFileType.DATASOURCE] = false
            setRestoreSection(restoreSection)
        }

        if (fileType === JobFileType.BREAKLINES) {
            const filesPreviewUpdated = getFilesPreviewByName(jobToEdit.customBreaklines)
            setBreaklinesFilesPreviewUpload(filesPreviewUpdated)
            restoreSection[JobFileType.BREAKLINES] = false
            setRestoreSection(restoreSection)
        }
    }

    const onUploadProgressUpdate = (fileType, fileName, loaded, total) => {
        setUploadStatus({fileType, fileName, loaded, total})
    }

    function getJobResolution() {
        if (jobToEdit.resolution === "0" || jobToEdit.resolution === JobResolutions.NORMAL.id) {
            return 'Normal'
        }

        return 'High'
    }

    function getCompanyName() {
        return !company ? EMPTY_STRING : company.name
    }

    function getUserName() {
        return !user ? EMPTY_STRING : `${user.firstName} ${user.lastName}`
    }

    function getUserEmail() {
        return !user ? EMPTY_STRING : user.email
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <Card sx={{p: 4}}>

                        <CustomBreadcrumbs
                            links={[
                                {
                                    name: 'Jobs',
                                    href: PATH_DASHBOARD.jobs.list,
                                },
                                {name: 'Edit Job'},
                            ]}
                        />

                        <Stack spacing={1}>
                            <Stack>
                                <Typography variant="overline">Resolution</Typography>
                                <Typography variant="body2"> {getJobResolution()} </Typography>
                            </Stack>
                            <Stack>
                                <Typography variant="overline">Type</Typography>
                                <Typography variant="body2"> {jobToEdit.type} </Typography>
                            </Stack>
                            <Stack>
                                <Typography variant="overline">Author</Typography>
                                <Typography variant="body2"> {getUserName()} </Typography>
                                <Typography variant="body2"> {getUserEmail()} </Typography>
                            </Stack>
                            <Stack>
                                <Typography variant="overline">Company</Typography>
                                <Typography variant="body2"> {getCompanyName()} </Typography>
                                <Typography variant="body2"> Bill number: {jobToEdit.billingNumber} </Typography>
                            </Stack>
                        </Stack>

                    </Card>


                </Grid>

                <Grid item xs={9}>
                    <Card sx={{p: 3}}>

                        <Typography variant="h6" flexGrow={1} sx={{minWidth: 0, pb: 2}}>
                            Job Information
                        </Typography>

                        <FormProvider methods={methods} onSubmit={handleSubmit(onUpdateJob)}>

                            <Stack spacing={2}>

                                {user !== undefined &&
                                    <RHFSelect name="author" label="Author | Company" placeholder="Author"
                                               required native open
                                    >
                                        <option value=""/>
                                        {
                                            users.map((user) => (
                                                <option key={user.id} value={user.id}>
                                                    {getAuthorInfo(user)}
                                                </option>
                                            ))
                                        }
                                    </RHFSelect>
                                }

                                <Box
                                    rowGap={3}
                                    columnGap={2}
                                    display="grid"
                                    gridTemplateColumns={{
                                        xs: 'repeat(1, 1fr)',
                                        sm: 'repeat(2, 1fr)',
                                    }}
                                >

                                    <RHFTextField name="name" label="Job Name"/>
                                    <RHFTextField name="billingNumber" label="Billing Reference"/>
                                    <RHFSelect name="status" label="Status" placeholder="Status"
                                               required native
                                    >
                                        <option value="" selected/>
                                        {
                                            JOB_STATUS_OPTIONS.map(status => (
                                                <option key={status} value={status}>
                                                    {status}
                                                </option>
                                            ))
                                        }
                                    </RHFSelect>

                                    <RHFTextField name="downloadUrl" label="Download URL"/>
                                    <RHFTextField name="billingRate" label="Billing Rate"/>
                                    <RHFTextField name="acreage" label="Acreages"/>

                                </Box>

                                <RHFTextField name="errorMessage" label="Error message"/>

                                <Divider/>

                                <Grid container spacing={2}>

                                    <Grid item xs={4}>

                                        <Stack direction="row"
                                               alignItems="center">
                                            <Typography variant="h6" flexGrow={1} sx={{minWidth: 0}}>
                                                Boundary
                                            </Typography>

                                            {
                                                restoreSection[JobFileType.BOUNDARY] &&
                                                <IconButton onClick={() => {
                                                    restoreFiles(JobFileType.BOUNDARY)
                                                }}>
                                                    <Iconify icon="material-symbols:restore-page-outline"/>
                                                </IconButton>
                                            }

                                            <UploadBox
                                                accept={{'text/plain': getAcceptedBoundaryFileTypes(boundaryTypeUpdated)}}
                                                onDrop={(files) => onNewFilesToUpload(files, JobFileType.BOUNDARY)}
                                            />
                                        </Stack>

                                        <ToggleButtonGroup
                                            value={boundaryTypeUpdated}
                                            exclusive
                                            fullWidth
                                            orientation="vertical"
                                            size="small"
                                            onChange={e => handleChangeBoundaryType(e.target.value)}
                                            aria-label="Boundary type"
                                            sx={{mt: 1, mb: 2}}
                                        >
                                            <ToggleButton value={BoundaryFileTypes.DXF.type} aria-label="DXF"
                                                          color={'primary'}>
                                                {BoundaryFileTypes.DXF.label}
                                            </ToggleButton>
                                            <ToggleButton value={BoundaryFileTypes.SHAPEFILES.type}
                                                          aria-label="Shapefile" color={'primary'}>
                                                {BoundaryFileTypes.SHAPEFILES.label}
                                            </ToggleButton>
                                        </ToggleButtonGroup>

                                        <FilesPreviewUpload
                                            filesInfo={boundaryFilesPreviewUpload}
                                            descriptionVisible={false}
                                            iconVisible={false}
                                            actionDownload={onDownloadFile}
                                            actionDelete={(fileName) => onRemoveFile(fileName, JobFileType.BOUNDARY)}
                                        />
                                    </Grid>

                                    <Grid item xs={4}>

                                        <Stack direction="row"
                                               alignItems="center">
                                            <Typography variant="h6" flexGrow={1} sx={{minWidth: 0}}>
                                                Data Source
                                            </Typography>

                                            {
                                                restoreSection[JobFileType.DATASOURCE] &&
                                                <IconButton onClick={() => {
                                                    restoreFiles(JobFileType.DATASOURCE)
                                                }}>
                                                    <Iconify icon="material-symbols:restore-page-outline"/>
                                                </IconButton>
                                            }

                                            <UploadBox
                                                accept={{'text/plain': getAcceptedDataSourceFileTypes(dataSourceTypeUpdated)}}
                                                onDrop={(files) => onNewFilesToUpload(files, JobFileType.DATASOURCE)}
                                            />
                                        </Stack>

                                        <ToggleButtonGroup
                                            value={dataSourceTypeUpdated}
                                            exclusive
                                            fullWidth
                                            orientation="vertical"
                                            size="small"
                                            onChange={e => handleChangeDatasourceType(e.target.value)}
                                            aria-label="DataSourceType"
                                            sx={{mt: 1, mb: 2}}
                                        >
                                            <ToggleButton value={DataSourceFileTypes.LASZ.type} aria-label="DXF"
                                                          color={'primary'}>
                                                {DataSourceFileTypes.LASZ.label}
                                            </ToggleButton>
                                            <ToggleButton value={DataSourceFileTypes.TIF.type} aria-label="Shapefile"
                                                          color={'primary'}>
                                                {DataSourceFileTypes.TIF.label}
                                            </ToggleButton>
                                        </ToggleButtonGroup>

                                        <FilesPreviewUpload
                                            filesInfo={datasourceFilesPreviewUpload}
                                            descriptionVisible={false}
                                            iconVisible={false}
                                            actionDownload={onDownloadFile}
                                            actionDelete={(fileName) => onRemoveFile(fileName, JobFileType.DATASOURCE)}
                                        />

                                        {dataSourceTypeUpdated === DataSourceFileTypes.LASZ.type &&
                                            <FormControl fullWidth sx={{mt: 2}}>
                                                <Typography variant="subtitle1" sx={{mb: 1}}>
                                                    Sampling Resolution
                                                </Typography>
                                                <ToggleButtonGroup
                                                    id='resolution-toggle-button-group'
                                                    value={jobResolutionUpdated}
                                                    exclusive
                                                    fullWidth
                                                    orientation="vertical"
                                                    size="small"
                                                    onChange={e => handleChangeResolution(e.target.value)}
                                                    aria-label="Sampling Resolution"
                                                >
                                                    <ToggleButton value={JobResolutions.NORMAL.id} aria-label="DXF"
                                                                  color={'primary'}>
                                                        {JobResolutions.NORMAL.label}
                                                    </ToggleButton>
                                                    <ToggleButton value={JobResolutions.HIGH.id} aria-label="Shapefile"
                                                                  color={'primary'}>
                                                        {JobResolutions.HIGH.label}
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                            </FormControl>
                                        }

                                    </Grid>

                                    <Grid item xs={4}>

                                        <Stack direction="row"
                                               alignItems="center">
                                            <Typography variant="h6" flexGrow={1} sx={{minWidth: 0}}>
                                                Breaklines
                                            </Typography>

                                            {
                                                restoreSection[JobFileType.BREAKLINES] &&
                                                <IconButton onClick={() => {
                                                    restoreFiles(JobFileType.BREAKLINES)
                                                }}>
                                                    <Iconify icon="material-symbols:restore-page-outline"/>
                                                </IconButton>
                                            }

                                            <UploadBox
                                                accept={{'text/plain': getAcceptedBreaklinesFileTypes(breaklinesTypeUpdated)}}
                                                onDrop={(files) => onNewFilesToUpload(files, JobFileType.BREAKLINES)}
                                            />
                                        </Stack>

                                        <ToggleButtonGroup
                                            value={breaklinesTypeUpdated}
                                            exclusive
                                            fullWidth
                                            orientation="vertical"
                                            size="small"
                                            onChange={e => handleChangeBreaklinesType(e.target.value)}
                                            aria-label="breaklines type"
                                            sx={{mt: 1, mb: 2}}
                                        >
                                            <ToggleButton value={BreaklinesFileTypes.DXF.type} aria-label="DXF"
                                                          color={'primary'}>
                                                {BreaklinesFileTypes.DXF.label}
                                            </ToggleButton>
                                            <ToggleButton value={BreaklinesFileTypes.SHAPEFILES.type}
                                                          aria-label="Shapefile" color={'primary'}>
                                                {BreaklinesFileTypes.SHAPEFILES.label}
                                            </ToggleButton>
                                        </ToggleButtonGroup>

                                        <FilesPreviewUpload
                                            filesInfo={breaklinesFilesPreviewUpload}
                                            descriptionVisible={false}
                                            iconVisible={false}
                                            actionDownload={onDownloadFile}
                                            actionDelete={(fileName) => onRemoveFile(fileName, JobFileType.BREAKLINES)}
                                        />
                                    </Grid>
                                </Grid>

                                <Stack alignItems="flex-end" sx={{mt: 3}}>
                                    <LoadingButton type="submit" variant="contained" loading={updatingJob}>
                                        Save Changes
                                    </LoadingButton>
                                </Stack>

                            </Stack>

                        </FormProvider>

                    </Card>
                </Grid>
            </Grid>

        </>
    )
}